import React from 'react';
import Aux from '../../hoc/Auxiliary';
import axios from "axios/index";

export default class Callback extends React.Component {
    constructor (props) {
        super (props);
        this.state = {
            status: false,
            transNumber: this.getQueryString('transactionNumber')
        }
    }

    /*get transactionNumber*/
    getQueryString(name){
        let queryString = this.props.location.pathname;
        let params = queryString.split(name+'=');

        if(params.length === 1){
            queryString = this.props.location.search;
            params = queryString.split(name+'=');
        }

        if(params.length === 1) return params[0];

        let param = params[1].split('&');
        return param[0]
    }

    /*callback check status*/
    checkStatus = ()=> {
        let self= this;
        axios ({
            method:'get',
            url:'https://canteen.az/api/public/payment/callback',
            params: {
                transactionNumber:this.state.transNumber
            }
        })
            .then(function (response) {
                if(response.data.status.code !=200) {
                    axios ({
                        method:'PUT',
                        url:' https://canteen.az/api/private/order/rollback-confirm-order/'+ response.data.result.data.orderId
                    })
                        .then(function (response) {
                            window.location.href="/confirmOrders";
                        })
                }
                self.setState({
                    status: response.data.result.data.status ,
                })
            })
            .catch(function (response) {

            });
    };

    componentDidMount () {
        this.checkStatus()
    }

    render() {
        return (
            <Aux>
                <div className="container">
                    <div className="success">
                        {
                            this.state.status ?
                                <div className="success-content">
                                    <div className="circle-block">
                                        <i className="fal fa-check"></i>
                                    </div>
                                    <h3>Uğurlu əməliyyat</h3>
                                </div>
                                :
                                <div className="fail-content">
                                    <div className="circle-block">
                                        <i className="fal fa-times"></i>
                                    </div>
                                    <h3>Uğursuz əməliyyat</h3>
                                </div>
                        }
                    </div>
                </div>
            </Aux>
        )
    }
}
