import React from 'react';
import Aux from '../../hoc/Auxiliary';
import Select  from 'react-select';
import axios from "axios/index";
import {getTranslation} from "../../Helper/Helper";

export default class Contact extends React.Component {
    constructor(props) {
        super (props);
        this.state = {
            selectedOption: null,
            fileName: '',
            text:'',
            file:null,
            complaints:[],
            offers: [],
            showModal:false,
            base64:'',
            type:'',
            tab:'writeLetter',
            modalData:'',
            data: [],
            translation : {
                GENERAL_REPORT : {},
                GENERAL : {},
                FEEDBACK : {}
            }
        };

        this.handleChangeText = this.handleChangeText.bind(this);
    }

    showModal (item,index) {
        this.setState({
             modalData : item ,
             index
        });
        this.setState({
            showModal : true
        })
    }

    closeModal () {
        this.setState({
            showModal : false
        })

    }

   /* get input value*/
    handleChangeText = (stateVar, event) => {
        this.setState({
            [stateVar]: event.target.value
        });
    };

    /*select option*/
    handleChange = selectedOption => {
        this.setState({
            selectedOption ,
            type: selectedOption.type
        });

    };

    getFeedback = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/feedback',
            params: {
                limit: 1000,
                offset: 0
            },
            headers: {
                authorization : 'bearer '+  localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    let complaints = [], offers = [];
                    for (let i of response.data.result.data){
                        if(i.type === 2){
                            complaints.push(i)
                        }else{
                            offers.push(i)
                        }
                    }

                    self.setState({complaints, offers, data: complaints})
                }
            })
            .catch(function (response) {

            });
    };

    /*change img base64*/
    handleImageChange(e) {
        e.preventDefault();
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            this.setState({
                file: file,
                base64: reader.result
            });
        };
        switch (e.target.name) {
            case 'selectedFile':
                if(e.target.files.length > 0) {
                    this.setState({ fileName: e.target.files[0].name });
                }
                break;
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    }

    sendFeedback  = (event) => {
        event.preventDefault();
        let self= this;
        let image= this.state.base64;
        let data = {
            text: this.state.text,
            type:this.state.type,
        };
        if(this.state.file !==null)  {
            data.image = image
        }
        axios({
            method: 'post',
            url: 'https://canteen.az/api/private/feedback',
            data: data,
            headers: {
                authorization : 'bearer '+ localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    window.location.reload(true);
                }
            })
            .catch(function (response) {

            });
    };

    componentDidMount() {
        this.getFeedback();

        let header = document.getElementById('header');
        header.classList.remove("fixed");

        if(typeof global.translation === 'undefined'){
            let self = this;
            getTranslation(function(obj){
                self.setState({translation: obj})
            })
        }else{
            this.setState({translation: global.translation})
        }
    }

    modal () {
        if(this.state.showModal) {
            return (
                <div className="modal modal-text" id="modal-text" tabIndex="-1" role="dialog" aria-labelledby="modal-text" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="section-head">
                                    Movzu basligi {this.state.index + 1}
                                </div>
                                <div className="section-body">
                                    <div className="feedBack-img">
                                        {
                                            this.state.modalData.imagePath !==null && (
                                                <img src={`https://canteen.az/api${this.state.modalData.imagePath} `} alt={this.state.modalData.name} style={{maxWidth:'100%'}}/>
                                            )
                                        }
                                    </div>
                                    {this.state.modalData.text}
                                </div>
                                <div className="button" onClick={()=> this.closeModal()}>
                                    {this.state.translation.GENERAL.close_btn}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop show" onClick={()=> this.closeModal()}></div>
                </div>
            )
        }
    }

    render() {
        const { selectedOption } = this.state;

        /*select style*/
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                color: '#000',
                backgroundColor: state.isSelected ? '#E4E4E4' : 'transparent',
                height:'56px',
                padding:'16px',
                fontSize : '14px',
                borderBottom: '1px solid #f5f5f5',
                "&:hover": {
                    backgroundColor:  '#E4E4E4',
                },
                "&:last-child": {
                    borderBottom: 'none',
                }
            }),

            control: (provided) => ({
                ...provided,
                minHeight: '56px',
                borderRadius:'8px',
                backgroundImage: `url(${require("../../assets/img/file.svg")})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '16px',
                backgroundPosition:' 3% 50%',
                padding: ' 0 20px 0 64px',
                fontSize:'14px',
                color:'#000',
                boxShadow: 'none',
                "::focus" : {
                    borderColor: '#f5f5f5',
                }
            }),

            indicatorSeparator: () => {},

            dropdownIndicator: defaultStyles => ({
                ...defaultStyles,
                'svg ' : {
                    display : 'none'
                },
                background: `url(${require("../../assets/img/arrow_down.svg")}) no-repeat 50%`,
            }),

            menu : (provided) => ({
                ...provided,
                borderRadius: '3px',
                padding:'0',
                border: '1px solid #ddd',
                boxShadow: '2px 3px 3px rgba(0,0,0,.11)',
            }),
            menuList: base => ({
                ...base,
                padding: 0
            })
        };

        const options = [
            { value: 'offer', label: this.state.translation.FEEDBACK.title1 ,type:1 },
            { value: 'complaint', label: this.state.translation.FEEDBACK.title2 ,type:2 },
        ];

        const { fileName } = this.state;

        let file = fileName
            ? ( <span>{fileName}</span>)
            : null ;

        return (
            <Aux>
                <section id="suggestions">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="suggestions">
                                    <div className="left">
                                        <div className="left-nav">
                                            <a  className={this.state.tab === 'complaints' ? 'active' : ''} onClick={()=> this.setState({tab : 'complaints', data: this.state.complaints})}>{this.state.translation.FEEDBACK.title2}</a>
                                            <a  className={this.state.tab === 'offers' ? 'active' : ''} onClick={()=> this.setState({tab : 'offers', data: this.state.offers})}>{this.state.translation.FEEDBACK.title1}</a>
                                            <a  className={this.state.tab === 'writeLetter' ? 'active' : ''} onClick={()=> this.setState({tab : 'writeLetter'})}>{this.state.translation.FEEDBACK.write_mess}</a>
                                        </div>
                                    </div>

                                    {
                                        this.state.tab == 'writeLetter' ?
                                            <div className="suggestions-letter-part same-div">
                                                <div className="main">
                                                    <form onSubmit={this.sendFeedback}>
                                                        <div className="form-group">
                                                            <Select
                                                                value={selectedOption}
                                                                onChange={this.handleChange}
                                                                options={options}
                                                                placeholder={this.state.translation.FEEDBACK.subject}
                                                                styles = { customStyles }
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <textarea id="subject" className="form-control" name="subject" rows="0" cols="0" placeholder={this.state.translation.FEEDBACK.message}
                                                                      value={this.state.text}
                                                                      onChange={(event)=>this.handleChangeText('text', event)}
                                                            />
                                                            <div className="file-part">
                                                                <input  id="file"
                                                                        type="file"
                                                                        name="selectedFile"
                                                                        onChange={ (event) => this.handleImageChange(event) }/>
                                                                <span id="valFile">{file}</span>
                                                                <span id="buttonFile">
                                                                    <img className="blueFile" src={require('../../assets/img/add-file.svg')} alt="addFile"/>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <button type="submit">{this.state.translation.FEEDBACK.send_btn}</button>
                                                    </form>
                                                </div>
                                                <div className="contact">
                                                    <div className="inside-contact">
                                                        <div className="left-contact">
                                                            <img src={require('../../assets/img/loc.svg')} alt="location"/>
                                                        </div>
                                                        <div className="right-contact">
                                                            Bakı şəhəri, Nizami rayonu,
                                                            <br/>
                                                            Qara Qarayev prospekti, 39 A
                                                        </div>
                                                    </div>
                                                    <div className="inside-contact">
                                                        <div className="left-contact">
                                                            <img src={require('../../assets/img/ph.svg')} alt="phone"/>
                                                        </div>
                                                        <div className="right-contact">
                                                            <a href="tel:+994502339992">+994 (50) 233 99 92</a>
                                                            <a href="tel:+994512356727">+994 (51) 235 67 27</a>
                                                        </div>
                                                    </div>
                                                    <div className="inside-contact">
                                                        <div className="left-contact">
                                                            <img src={require('../../assets/img/ma.svg')} alt="email"/>
                                                        </div>
                                                        <div className="right-contact">
                                                            <a href="mailto:info@canteen.az">info@canteen.az</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="suggestions-complaints-part same-div">
                                                {
                                                    this.state.data.length > 0 ?
                                                        <div className="main">
                                                            {
                                                                this.state.data.map((item, index) => {
                                                                    return (
                                                                        <div key={item.id}>
                                                                            <div className="box" >
                                                                                <div className="compact"  onClick={() =>this.showModal(item,index)}>
                                                                                    <div className="icon">
                                                                                        <img src={require('../../assets/img/file.svg')} alt="file"/>
                                                                                    </div>
                                                                                    <div className="text">
                                                                                        {item.text}
                                                                                    </div>
                                                                                    <div className="time">{item.date}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    )
                                                                })
                                                            }
                                                            <div className="contact">
                                                                <div className="inside-contact">
                                                                    <div className="left-contact">
                                                                        <img src={require('../../assets/img/loc.svg')} alt="location"/>
                                                                    </div>
                                                                    <div className="right-contact">
                                                                        Bakı şəhəri, Nizami rayonu,
                                                                        <br/>
                                                                        Qara Qarayev prospekti, 39 A
                                                                    </div>
                                                                </div>
                                                                <div className="inside-contact">
                                                                    <div className="left-contact">
                                                                        <img src={require('../../assets/img/ph.svg')} alt="phone"/>
                                                                    </div>
                                                                    <div className="right-contact">
                                                                        <a href="tel:+994502339992">+994 (50) 233 99 92</a>
                                                                        <a href="tel:+994512356727">+994 (51) 235 67 27</a>
                                                                    </div>
                                                                </div>
                                                                <div className="inside-contact">
                                                                    <div className="left-contact">
                                                                        <img src={require('../../assets/img/ma.svg')} alt="email"/>
                                                                    </div>
                                                                    <div className="right-contact">
                                                                        <a href="mailto:info@canteen.az">info@canteen.az</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="no-data">Məlumat yoxdur</div>
                                                }
                                            </div>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.modal()}
            </Aux>
        )
    }
}
