import React from 'react';
import Aux from '../../hoc/Auxiliary'
import axios from 'axios';
import {getTranslation} from "../../Helper/Helper";
import swal from 'sweetalert';

export default class Cooperative extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: "",
            companyName: "",
            email: "",
            fullName: "",
            phoneNumber: "+994",
            validationFullName: false,
            validationCompanyName: false,
            validationEmail: false,
            validationPhone: false,
            validationAddress: false,
            translation: {
                LOGIN_SIGNUP: {},
                BASKET: {},
                GENERAL: {}
            }
        };
        this.handleChange = this.handleChange.bind(this);
    }

    /* get input value*/
    handleChange = (stateVar, event) => {
        this.setState({
            [stateVar]: event.target.value
        });
    };

    /*send data api*/
    sendData = (event) => {
        event.preventDefault();
        let self = this;
        let hasValidationFullName = this.state.fullName.length >= 3;
        let hasValidationCompanyName = this.state.companyName.length >= 3;
        let hasValidationAddress = this.state.address.length >= 3;
        let hasValidationEmail = this.state.email.length >= 3;
        let hasValidationPhone = this.state.phoneNumber.length >= 11;
        self.setState({
            validationFullName: !hasValidationFullName,
            validationCompanyName: !hasValidationCompanyName,
            validationEmail: !hasValidationEmail,
            validationPhone: !hasValidationPhone,
            validationAddress: !hasValidationAddress,
        });
        let data = {
            additionalPhoneNumber: this.state.phoneNumber,
            address: this.state.address,
            branchName: "xxx",
            branchType: "xxx",
            companyName: this.state.companyName,
            customerType: 0,
            email: this.state.email,
            fullName: this.state.fullName,
            phoneNumber: this.state.phoneNumber,
            turnover: 0,
        };

        if (hasValidationFullName && hasValidationCompanyName && hasValidationEmail && hasValidationPhone && hasValidationAddress) {
            axios({
                method: 'post',
                url: 'https://canteen.az/api/public/auth/register',
                data: data,
            })
                .then(function (response) {
                    if (response.data.status.code == 200) {
                        swal({
                            title: self.state.translation.LOGIN_SIGNUP.success_operation,
                        });
                        window.location.href = '/creatPassword'
                    }
                })
                .catch(function (response) {

                });

            self.setState({
                validationFullName: false,
                validationCompanyName: false,
                validationEmail: false,
                validationPhone: false,
                validationAddress: false
            });
        }
    };

    componentDidMount() {
        let header = document.getElementById('header');
        header.classList.remove("fixed");
        if (typeof global.translation === 'undefined') {
            let self = this;
            getTranslation(function (obj) {
                self.setState({translation: obj})
            })
        } else {
            this.setState({translation: global.translation})
        }
    }

    render() {
        return (
            <Aux>
                <section id="special">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="special">
                                    <div className="left">
                                        <div className="section-head">{this.state.translation.LOGIN_SIGNUP.create_business_account}</div>
                                        <form onSubmit={this.sendData}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control name"
                                                    name="name"
                                                    placeholder={this.state.translation.LOGIN_SIGNUP.name_surname + ' *'}
                                                    value={this.state.fullName}
                                                    onChange={(event) => this.handleChange('fullName', event)}
                                                />
                                                {
                                                    this.state.validationFullName ?
                                                        <div className="invalid-feedback">
                                                            {this.state.translation.LOGIN_SIGNUP.include_name_surname}
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control enterprise"
                                                    id="enterprise"
                                                    name="companyName"
                                                    placeholder={this.state.translation.LOGIN_SIGNUP.enterprise + ' *'}
                                                    value={this.state.companyName}
                                                    onChange={(event) => this.handleChange('companyName', event)}
                                                />
                                                {
                                                    this.state.validationCompanyName ?
                                                        <div className="invalid-feedback">
                                                            {this.state.translation.LOGIN_SIGNUP.include_company_name}
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control email"
                                                    id="email"
                                                    name="email"
                                                    placeholder={this.state.translation.LOGIN_SIGNUP.e_poct + ' *'}
                                                    value={this.state.email}
                                                    onChange={(event) => this.handleChange('email', event)}
                                                />
                                                {
                                                    this.state.validationEmail ?
                                                        <div className="invalid-feedback">
                                                            {this.state.translation.LOGIN_SIGNUP.include_email}
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control phone"
                                                    id="phone"
                                                    name="phoneNumber"
                                                    value={this.state.phoneNumber}
                                                    onChange={(event) => this.handleChange('phoneNumber', event)}
                                                />
                                                {
                                                    this.state.validationPhone ?
                                                        <div className="invalid-feedback">
                                                            {this.state.translation.LOGIN_SIGNUP.include_phone_number}
                                                        </div>
                                                        : null
                                                }

                                            </div>
                                            <div className="form-group">
                                            <textarea
                                                id="address"
                                                className="form-control address"
                                                name="address"
                                                placeholder={this.state.translation.LOGIN_SIGNUP.adress + ' *'}
                                                rows="0"
                                                cols="0"
                                                value={this.state.address}
                                                onChange={(event) => this.handleChange('address', event)}>
                                            </textarea>
                                                {
                                                    this.state.validationAddress ?
                                                        <div className="invalid-feedback">
                                                            {this.state.translation.LOGIN_SIGNUP.include_address}
                                                        </div>
                                                        : null
                                                }

                                            </div>
                                            <button
                                                type="submit">{this.state.translation.LOGIN_SIGNUP.send_btn}</button>
                                        </form>
                                    </div>
                                    <div className="right">
                                        <img src={require('../../assets/img/special.png')} alt="special"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="special-footer">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="footer">
                                    &copy; 2020 {this.state.translation.GENERAL.copyright_text}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </Aux>
        )
    }
}
