import React from 'react';
import Aux from '../../hoc/Auxiliary'
import axios from 'axios';
import { Link } from "react-router-dom";
import {getTranslation}from '../../Helper/Helper';
import Swal from "sweetalert";


export default class ForgetCustomerCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: "+994",
            validationPhone:false,
            showRegister: false,
            validation: false,
            translation: {
                LOGIN_SIGNUP: {},
                BASKET: {},
                GENERAL:{}
            }
        };
        this.handleChange = this.handleChange.bind(this);
    }

    /*get input value*/
    handleChange = (stateVar, event) => {
        this.setState({
            [stateVar]: event.target.value
        });
    };

    showRegister() {
        this.setState({
            showRegister: !this.state.showRegister
        })
    }

    sendData = (event) => {
        event.preventDefault();
        let self =this;
        let hasValidationPhone = this.state.phoneNumber.length>=11;
        self.setState ( {
            validationPhone: !hasValidationPhone
        });
        let data = {
            phoneNumber: this.state.phoneNumber,

        };
        if(hasValidationPhone) {
            axios({
                method: 'post',
                url: 'https://canteen.az/api/public/auth/forgot-code',
                data: data,
            })
                .then(function (response) {
                    if(response.data.status.code==200) {
                        Swal({
                            title: self.state.translation.LOGIN_SIGNUP.success_operation,
                        });
                        window.location.href = '/createPassword'
                    }
                })
                .catch(function (response) {

                });
            self.setState ( {
                validationPhone:false
            });
        }
    };


    componentDidMount() {
        if(typeof global.translation === 'undefined'){
            let self = this;
            getTranslation(function(obj){
                self.setState({translation: obj})
            })
        }else{
            this.setState({translation: global.translation})
        }
    }

    render() {
        return (
            <Aux>
                <section id="special">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="special">
                                    <div className="left">
                                        <div>
                                            <div className="section-head">{this.state.translation.LOGIN_SIGNUP.change_customer_code}</div>
                                            <form onSubmit={this.sendData}>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control phone"
                                                        id="phone"
                                                        name="phone"
                                                        value={this.state.phoneNumber}
                                                        onChange={(event)=>this.handleChange('phoneNumber', event)}
                                                    />
                                                    {
                                                        this.state.validationPhone ?
                                                            <div className="invalid-feedback">
                                                                {this.state.translation.LOGIN_SIGNUP.include_phone_number}
                                                            </div>
                                                            : null
                                                    }
                                                    <button type="submit">{this.state.translation.BASKET.continue}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <img src={require('../../assets/img/special.png')} alt="special"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="special-footer">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="footer">
                                    &copy; 2020 {this.state.translation.GENERAL.copyright_text}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    this.state.showRegister ?
                        <div    className="modal modal-account"
                                id="modal-account"
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="modal-account"
                                aria-hidden="true"
                        >
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        {/*   <div className="close" data-dismiss="modal" aria-label="Close">
                                            <i className="fas fa-times"></i>
                                        </div>*/}
                                        <div className="head">
                                            {this.state.translation.GENERAL.about_text7_}
                                        </div>
                                        <div className="box" onClick={()=>this.showRegister()}>
                                            <Link to="/individual">
                                                <img className="personal" src={require('../../assets/img/individual.svg')} alt="individual"/>
                                                {this.state.translation.LOGIN_SIGNUP.create_individual_account}
                                                <img className="arrow" src={require('../../assets/img/right.svg')} alt="arrow"/>
                                            </Link>
                                        </div>
                                        <div className="box" onClick={()=>this.showRegister()}>
                                            <Link to="/cooperative">
                                                <img className="personal" src={require('../../assets/img/business.svg')} alt="business"/>
                                                {this.state.translation.LOGIN_SIGNUP.create_business_account}
                                                <img className="arrow" src={require('../../assets/img/right.svg')} alt="arrow"/>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-backdrop show" onClick={()=>this.showRegister()}></div>
                        </div>
                        : null
                }
            </Aux>
        )
    }
}
