import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import Select, {components} from 'react-select';
import axios from "axios/index";
import {NavLink, Link} from 'react-router-dom';
import Slider from "react-slick";
import InfiniteScroll from "react-infinite-scroll-component";
import Lightbox from 'react-image-lightbox';
import {getTranslation} from "../../../Helper/Helper";
import Swal from "sweetalert";

export default class CampaignBusiness extends React.Component {
    constructor(props) {
        super(props);
        let subCategory = false;
        let category = false;
        if (typeof this.props.match != 'undefined') {
            subCategory = this.props.match.params.subCategory || false;
            category = this.props.match.params.categoryID || false;
        }
        this.state = {
            selectedSearchOption: null,
            selectedSortOption: null,
            categoryProducts: [],
            subCategories: [],
            subCategory,
            searchList: [],
            category,
            offset: 0,
            hasMore: false,
            quantity: '',
            bgColor: '',
            alternativeList: [],
            query: '',
            results: {},
            loading: false,
            message: '',
            sort: false,
            showOfferedModal: false,
            showMemoryModal: false,
            offered: '',
            lightBox: '',
            showLightBox: false,
            photoIndex: 0,
            orderList: [],
            showNote: false,
            customerType: localStorage.getItem('customerType'),
            index: 0,
            item: {},
            noteItem: '',
            search: false,
            suggestProduct: false,
            suggestedProductName: '',
            validationName: false,
            translation: {
                LEFT_MENU: {},
                GENERAL: {},
                ORDER: {}
            }
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (typeof nextProps.match !== 'undefined' && nextProps.match.params.categoryID !== prevState.category) {
            const currentCategoryID = nextProps.match.params.categoryID
            return {
                category: currentCategoryID
            };
        }

        if (nextProps.match.params.subCategory !== prevState.subCategory) {
            const subCategory = nextProps.match.params.subCategory

            return {
                subCategory
            };
        }
        return null;
    }

    componentDidUpdate(prevState) {
        if (prevState.match.params.categoryID != this.state.category || prevState.match.params.subCategory != this.state.subCategory) {
            this.setState({offset: 0}, () => {
                this.setState({searchList: []}, () => {
                    this.getSearchList()
                })
            })
        }
    }

    /*select option*/
    handleSortOptions = (selectedSortOption) => {
        this.setState({
            selectedSortOption,
            sort: selectedSortOption.value,
            searchList: [],
            offset: 0,
            hasMore: false
        }, () => {
            this.getSearchList()
        })
    };

    /*add button on click function*/
    showProductCount(item, index) {
        item.showCount = !item.showCount;
        let searchList = this.state.searchList;
        searchList.slice(index, 1, item);
        this.setState({searchList});
        this.setState({searchList}, () => {
            if (!item.hasBasket) this.productsIncrease(item, index)
        });
    };

    showOfferedModal(item) {
        this.setState({
            offered: item
        });
        this.setState({
            showOfferedModal: true
        });
    };

    showNoteModal = (item) => {
        for (let i of this.state.orderList) {
            if (item.id === i.id) {
                item.note = i.note;
            }
        }

        this.setState({
            noteItem: item,
            showNote: true
        });
    };

    closeNoteModal = () => {
        this.setState({
            showNote: false
        });
    };

    closeOfferedModal() {
        this.setState({
            showOfferedModal: false
        });
    };

    showMemoryModal(id) {
        this.setState({
            showMemoryModal: true
        });
        this.getAlternativeList(id)
    }

    closeMemoryModal() {
        this.setState({
            showMemoryModal: false
        });
    }

    showLightBox = (item) => {
        this.setState({
            lightBox: item,
            showLightBox: true
        });
    };

    closeLightBox = () => {
        this.setState({
            showLightBox: false
        });
    };

    toggle(item, index) {
        item.isBulk = !item.isBulk;
        item.changeQuantity = false;
        let searchList = this.state.searchList;
        searchList.slice(index, 1, item);
        this.setState({searchList});
    }

    /* get categoryList*/
    getCategories = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/product/campaigns',
            params: {
                limit: 50,
                offset: 0,
            },
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token'),
                'Accept-Language': localStorage.getItem('lang') || 'az'
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    self.setState({
                        categoryProducts: response.data.result.data,
                    });
                }
            })
            .catch(function (response) {

            });
    };

    /*set subCategories */
    setSubCategories = (item) => {
        this.setState({
            subCategories: item.subCategories
        })
    };

    getSearchList = () => {
        let self = this;
        let params = {};
        params.limit = 15;
        params.offset = this.state.offset;
        if (this.state.category > 0) params.category = this.state.category;
        if (this.state.subCategory > 0) params.subCategory = this.state.subCategory;
        if (this.state.sort) params.sortOrder = this.state.sort;
        if (this.state.query.length > 0) params.searchQuery = this.state.query
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/product/v3/search',
            params: params,
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token'),
                'Accept-Language': localStorage.getItem('lang') || 'az'
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    let searchList = self.state.searchList;
                    self.setState({
                        searchList: searchList.concat(response.data.result.data.products),
                    });
                    let offset = self.state.offset + response.data.result.data.products.length;
                    let hasMore = parseInt(response.data.result.data.products.length) > 0;
                    self.setState({offset, hasMore})
                }
            })
            .catch(function (response) {
            });
    };

    getSaleList = (campaign) => {
        let self = this;
        let params = {};
        params.limit = 15;
        params.offset = this.state.offset;
        params.campaign = campaign;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/product/v3/search',
            params: params,
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token'),
                'Accept-Language': localStorage.getItem('lang') || 'az'
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    let searchList = self.state.searchList;
                    self.setState({
                        searchList: searchList.concat(response.data.result.data.products),
                    });
                    let offset = self.state.offset + response.data.result.data.products.length;
                    let hasMore = parseInt(response.data.result.data.products.length) > 0;
                    self.setState({offset, hasMore: false})
                }
            })
            .catch(function (response) {
            });
    };

    getAlternativeList = (id) => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/product/sub/' + id,
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token'),
                'Accept-Language': localStorage.getItem('lang') || 'az'
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    self.setState({
                        alternativeList: response.data.result.data
                    })
                }
            })
            .catch(function (response) {

            });
    };

    productsIncrease = (item, index) => {
        let self = this;
        const items = this.state.searchList;
        const alternativeItems = this.state.alternativeList;
        item.changeQuantity = true;
        item.quantity = parseFloat(item.quantity) || 0;
        item.qatLimit = item.qatLimit == '0.00' ? 1 : parseFloat(item.qatLimit);
        item.quantity = parseFloat(item.quantity) + item.qatLimit;
        if (parseFloat(item.maxLimit) > 0 && parseFloat(item.quantity) > parseFloat(item.maxLimit)) {
            Swal({
                title: self.state.translation.ORDER.maximum_order + ' : ' + item.maxLimit,
                icon: "warning",
                button: 'Ok',
            })
            item.quantity -= item.qatLimit
        }

        if ( parseFloat(item.limit) > 0  && parseFloat(item.quantity) > parseFloat(item.limit)){
            Swal({
                title: self.state.translation.ORDER.not_enough_product,
                icon: "warning",
                button: 'Ok',
            })
            item.quantity -= item.qatLimit
        }
        this.state.showMemoryModal ? alternativeItems.splice(index, 1, item) : items.splice(index, 1, item);

        this.setState({
            searchList: items,
            alternativeItems: alternativeItems
        });

        self.setState({
            bgColor: item.bgColor = true
        });
        setTimeout(function () {
            self.setState({
                bgColor: item.bgColor = false
            })
        }, 1000);

        clearTimeout(item.timer);

        item.timer = setTimeout(() => {

            if (item.hasBasket) this.updateCart(item)
            else this.addToCart(item)

        }, 1000);

    };

    productsDecrease = (item, index) => {
        const items = this.state.searchList;
        const alternativeItems = this.state.alternativeList;
        item.changeQuantity = true;
        item.qatLimit = item.qatLimit == '0.00' ? 1 : parseFloat(item.qatLimit);
        item.quantity = parseFloat(item.quantity) || 0;
        if (item.quantity > 0) {
            item.quantity = item.quantity - item.qatLimit;
            this.state.showMemoryModal ? alternativeItems.splice(index, 1, item) : items.splice(index, 1, item);
            this.setState({
                searchList: items,
                alternativeItems: alternativeItems
            });
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.updateCart(item)
            }, 1000);

        }

    };

    //show price in header
    calculateTotal() {
        let total = 0;
        for (let i of this.state.orderList) {
            total += i.quantity * i.price
        }
        this.setState({total});
        document.getElementById('basketTotal').innerText = parseFloat(total).toFixed(2)
    }

    //get product quantity
    getQuantity = (item) => {
        for (let i of this.state.orderList) {
            if (item.id === i.id) {
                item.hasBasket = true;
                item.quantity = item.changeQuantity ? item.quantity : i.quantity
            }
        }
        if (this.quantityTimer) {
            /*this.quantityTimer = false;*/
            return item.quantity;
        }

        if (item.quantity <= 0) return 0

        return item.quantity ? parseFloat(item.quantity).toFixed(2) : ''
    };

    getQuantityPlenty = (item) => {
        for (let i of this.state.orderList) {
            if (item.id === i.id) {
                item.hasBasket = true;
                item.quantity = item.changeQuantity ? item.quantity : Math.floor(parseFloat(i.quantity) / parseFloat(i.countInBulk))
            }
        }

        if (item.quantity <= 0) return 0

        return item.quantity || 0
    };

    addStar = (item, index) => {
        let self = this;
        axios({
            method: 'post',
            url: 'https://canteen.az/api/private/product/star/true/' + item.id,
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    let searchList = self.state.searchList;
                    item.star = true;
                    searchList[index] = item;
                    self.setState({searchList})
                }
            })
            .catch(function (response) {

            });
    };

    removeStar = (item, index) => {
        let self = this;
        axios({
            method: 'post',
            url: 'https://canteen.az/api/private/product/star/false/' + item.id,
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    let searchList = self.state.searchList;
                    item.star = false;
                    searchList[index] = item;
                    self.setState({searchList})
                }
            })
            .catch(function (response) {

            });
    };

    addProductToCard = (item, index, event) => {
        clearTimeout(item.timer);
        this.quantityTimer = true;
        let value = event.target.value;
        const items = this.state.searchList;
        item.changeQuantity = true;
        item.qatLimit = item.qatLimit == '0.00' ? 1 : parseFloat(item.qatLimit);
        item.quantity = value;
        items.splice(index, 1, item);
        this.setState({
            searchList: items
        });

        let self = this;
        item.timer = setTimeout(function () {
            self.quantityTimer = false;
            let multiple = Math.round(parseFloat(value) / parseFloat(item.qatLimit));
            item.quantity = item.qatLimit * multiple;
            items.splice(index, 1, item);
            self.setState({
                searchList: items
            });

            if (item.hasBasket) {
                self.updateCart(item)
            } else {
                self.addToCart(item)
            }
        }, 2000)
    }

    addProductToCardPlenty = (item, index, event) => {
        let type = item.hasBasket ? 'update' : 'addCart';
        let value = event.target.value;
        item.changeQuantity = true;
        let items = this.state.searchList;
        item.quantity = parseInt(value);
        items.splice(index, 1, item);
        this.setState({
            searchList: items, index, item
        });
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            if (type === "update") this.updateCart(item)
            else this.addToCart(item)

        }, 500);

    }

    addToCart = (item) => {
        let quantity = item.isBulk ? item.quantity * item.countInBulk : item.quantity;
        let self = this;
        axios({
            method: 'post',
            url: 'https://canteen.az/api/private/order/save/to/cart',
            data: {
                note: item.note,
                overwrite: false,
                product: item.id,
                quantity: quantity
            },
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    self.getOrders();
                }
            })
            .catch(function (response) {

            });
    };

    updateCart = (item) => {
        item.quantity = item.isBulk ? item.quantity * item.countInBulk : item.quantity;
        if (parseFloat(item.maxLimit) > 0 && (parseFloat(item.quantity) > item.maxLimit)) {
            return
        }
        let self = this;
        axios({
            method: 'post',
            url: 'https://canteen.az/api/private/order/update/product/to/cart',
            data: {
                note: item.note,
                overwrite: true,
                product: item.id,
                quantity: item.quantity
            },
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    self.getOrders();
                }
            })
            .catch(function (response) {

            });
    };

    /*get OrderList*/
    getOrders = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/order/cart/orders',
            params: {
                limit: 1000,
                offset: 0
            },
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    self.setState({
                        orderList: response.data.result.data.products
                    }, () => {
                        if (self.state.item.changeQuantity) {
                            let items = self.state.searchList;
                            self.state.item.changeQuantity = false;
                            items.splice(self.state.index, 1, self.state.item);
                            self.setState({searchList: items})
                        }
                    });
                    self.calculateTotal()
                    document.getElementById('basketItem').innerText = response.data.result.data.products.length
                }
            })
            .catch(function (response) {

            });
    };

    /*searchList as yhe result of search query*/
    fetchSearchResults = (query) => {
        let params = {};
        this.setState({
            search: true
        });
        params.limit = 15;
        params.offset = this.state.offset;
        params.searchQuery = query;
        /*if (this.state.category > 0) params.category = this.state.category;
        if (this.state.subCategory > 0) params.subCategory = this.state.subCategory;*/
        if (this.state.sort) params.sortOrder = this.state.sort;
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/product/v3/search',
            params: params,
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then((res) => {
                let offset = self.state.offset + res.data.result.data.products.length;
                let searchList = self.state.searchList;
                searchList = searchList.concat(res.data.result.data.products);
                const resultNotFoundMsg = searchList.length === 0
                    ? self.state.translation.GENERAL.search_result
                    : '';
                self.setState({
                    searchList,
                    starProducts: [],
                    message: resultNotFoundMsg,
                    loading: false,
                    offset,
                    hasMore: res.data.result.data.products.length >= params.limit
                });
            })
            .catch((error) => {
            });
    };

    /*get search input value*/
    handleOnInputChange = (event) => {
        clearTimeout(this.timer);
        const query = event.target.value;
        this.setState({query, offset: 0, searchList: []});
        this.timer = setTimeout(() => {
            this.setState({hasMore: false});

            if (!query) {
                this.setState({searchList: [], message: '', offset: 0, subCategories: []}, () => {
                    this.getSearchList()
                });
            } else {
                this.setState({loading: true, message: ''}, () => {
                    this.fetchSearchResults(query);
                });
            }
        }, 600);

    };

    memoryModal() {
        if (this.state.showMemoryModal) {
            return (
                <div className="modal modal-memory" id="modal-memory" tabIndex="-1" role="dialog"
                     aria-labelledby="modal-memory" aria-hidden="true">
                    <div className="modal-dialog modal-lg"
                         role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="close" aria-label="Close" onClick={() => this.closeMemoryModal()}>
                                    <i className="fas fa-times"></i>
                                </div>
                                <div className="more">
                                    <div className="top">
                                        <div className="left">
                                            {this.state.translation.BASKET.basket}
                                        </div>
                                    </div>
                                    <div className="alternative-content">
                                        {
                                            this.state.alternativeList.map((item, index) => {
                                                return (
                                                    <div className="box" key={item.id}>
                                                        <div className="zoom">
                                                            <button className="transparent"
                                                                    onClick={() => this.showLightBox(item)}>
                                                                <i className="fal fa-search-plus"></i>
                                                            </button>
                                                        </div>
                                                        <div className="all-inside">
                                                            <div className="left">
                                                                <img
                                                                    src={`https://canteen.az/bm/files/products/${item.id}/300/${item.images[0]} `}
                                                                    alt={item.name}/>
                                                            </div>
                                                            <div className="right">
                                                                <div className="name_and_delete">
                                                                    <div className="left_name">
                                                                        <div className="name"> {item.displayName}</div>
                                                                        <div className="price">
                                                                            {parseFloat(item.price).toFixed(2)} AZN
                                                                            <img
                                                                                src={require('../../../assets/img/right_arrow.svg')}
                                                                                alt="arrow"/>
                                                                            <span
                                                                                className="red">{parseFloat(item.price * item.quantity).toFixed(2)} AZN</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="calculate">
                                                                    <div className="left">
                                                                        <span
                                                                            className="piece">{this.getQuantity(item)}</span>
                                                                        <span className="calculate_name">
                                                                            {item.measurementUnit === 1 || item.measurementUnit === 3 ? this.state.translation.GENERAL.piece : this.state.translation.GENERAL.kq}
                                                                        </span>
                                                                    </div>
                                                                    <div className="right-button">
                                                                        <div className="minus"
                                                                             onClick={() => this.productsDecrease(item, index)}>
                                                                            <img
                                                                                src={require('../../../assets/img/minus.svg')}
                                                                                alt="minus"/>
                                                                        </div>
                                                                        <div className="plus"
                                                                             onClick={() => this.productsIncrease(item, index)}>
                                                                            <img
                                                                                src={require('../../../assets/img/plus.svg')}
                                                                                alt="plus"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="return" onClick={() => this.closeMemoryModal()}>
                                        <a href="#">{this.state.translation.BASKET.return_basket}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop show" onClick={() => this.closeMemoryModal()}></div>
                </div>
            )
        }
    }

    offeredModal(settings) {
        if (this.state.showOfferedModal) {
            return (
                <div
                    className="modal modal-carousel"
                    id="modal-carousel"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="modal-carousel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="carousel">
                                    <Slider {...settings}>
                                        <div className="item">
                                            {
                                                this.state.offered.images.length > 0 ?
                                                    <img
                                                        src={`https://canteen.az/bm/files/products/${this.state.offered.id}/original/${this.state.offered.images[0]} `}
                                                        alt={this.state.offered.name}/>
                                                    :
                                                    <img src={require('../../../assets/img/default-img.png')}
                                                         alt={this.state.offered.name}/>
                                            }
                                        </div>
                                    </Slider>
                                </div>
                                <div className="content">
                                    <div className="head">{this.state.offered.displayName}</div>
                                    <div className="price"><span>{this.state.offered.price} </span>AZN</div>
                                    <div className="text">
                                        {this.state.offered.description}
                                    </div>
                                    <div className="own-table">
                                        <div className="box">
                                            <div className="left">Marka</div>
                                            <div className="right">{this.state.offered.marka}</div>
                                        </div>
                                        <div className="box">
                                            <div className="left">Ölçü</div>
                                            <div className="right">{this.state.offered.malOlcu}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop show" onClick={() => this.closeOfferedModal()}></div>
                </div>
            )
        }
    }

    lightBoxModal() {
        if (this.state.showLightBox) {
            return (
                <Lightbox
                    mainSrc={`https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/` + this.state.lightBox.images[this.state.photoIndex]}
                    nextSrc={`https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/` + this.state.lightBox.images[(this.state.photoIndex + 1) % `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/` + this.state.lightBox.images.length]}
                    prevSrc={`https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/` + this.state.lightBox.images[(this.state.photoIndex + `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/` + this.state.lightBox.images.length - 1) % `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/` + this.state.lightBox.images.length]}
                    onCloseRequest={() => this.closeLightBox()}
                    onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/` + this.state.lightBox.images.length - 1) % `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/` + this.state.lightBox.images.length,
                        })
                    }
                    onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + 1) % `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/` + this.state.lightBox.images.length,
                        })
                    }
                />
            )
        }
    }

    noteModal() {
        if (this.state.showNote) {
            return (
                <div
                    className="modal modal-note"
                    id="modal-note"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="modal-note"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="close" data-dismiss="modal" aria-label="Close">
                                    <i className="fas fa-times"></i>
                                </div>
                                <div className="section-head">
                                    {this.state.translation.GENERAL.add_note}
                                </div>
                                <form>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control note"
                                            id="note"
                                            name="note"
                                            placeholder={this.state.translation.GENERAL.comment}
                                            value={this.state.noteItem.note}
                                            onChange={(event) => {
                                                let noteItem = this.state.noteItem;
                                                noteItem.note = event.target.value;
                                                this.setState({noteItem})
                                            }}

                                        />
                                    </div>
                                    <button
                                        onClick={() => this.closeNoteModal()}>{this.state.translation.BASKET.add_btn}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop show"></div>
                </div>
            )
        }
    }

    getSale(item) {
        let sale = 100 - parseFloat(item.price) * 100 / item.oldPrice;
        return parseFloat(sale).toFixed(2) + '%'
    }

    getBoxSale(item) {
        let sale = (parseFloat(item.price) * item.countInBulk - item.bulkPrice) * 100 / (parseFloat(item.price) * item.countInBulk);
        return parseFloat(sale).toFixed(2) + '%'
    }

    next = () => {
        if (this.state.search) {
            this.fetchSearchResults(this.state.query)
        } else {
            this.getSearchList()
        }
    }

    suggestProductModal() {
        if (this.state.suggestProduct) {
            return (
                <div
                    className="modal modal-note"
                    id="modal-note"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="modal-note"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="close" data-dismiss="modal" aria-label="Close">
                                    <i className="fas fa-times"></i>
                                </div>
                                <div className="section-head">
                                    {this.state.translation.GENERAL.notify_us}
                                </div>
                                <form onSubmit={this.sendSuggestedProduct}>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control note"
                                            name="suggestProduct"
                                            placeholder={this.state.translation.GENERAL.add_product_name}
                                            onChange={(event) => {
                                                this.state.suggestedProductName = event.target.value;
                                                this.setState({suggestedProductName: this.state.suggestedProductName})
                                            }}
                                        />
                                        {
                                            this.state.validationName ?
                                                <div className="invalid-feedback">
                                                    {this.state.translation.GENERAL.add_product_name} !
                                                </div>
                                                : ''
                                        }
                                    </div>
                                    <button>{this.state.translation.BASKET.add_btn}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop show" onClick={() => this.setState({suggestProduct: false})}></div>
                </div>
            )
        }
    }

    sendSuggestedProduct = (event) => {
        event.preventDefault();
        let self = this;
        let hasValidationName = this.state.suggestedProductName.length >= 2;
        self.setState({
            validationName: !hasValidationName,
        });
        if (hasValidationName) {
            axios({
                method: 'post',
                url: 'https://canteen.az/api/private/product-suggest',
                data: {
                    name: this.state.suggestedProductName
                },
                headers: {
                    authorization: 'bearer ' + localStorage.getItem('token')
                }
            })
                .then(function (response) {
                    if (response.data.status.code == 200) {
                        self.setState({
                            suggestProduct: false
                        })
                        Swal({
                            title: self.state.translation.LOGIN_SIGNUP.success_operation,
                            icon: "success",
                            button: 'Ok',
                        })
                    }
                })
                .catch(function (response) {
                });
            self.setState({
                validationName: false
            });
        }
    };

    componentDidMount() {
        this.getCategories();
        this.getSaleList(7);
        this.getSaleList(6);
        this.getOrders();
        let header = document.getElementById('header');
        header.classList.add("fixed");
        let x = document.getElementById("bottomMenuID").offsetWidth;
        let topId = document.getElementById('topID');
        topId.style.width = x + 'px';
        let y = document.getElementById("offeredID").offsetWidth;
        let topFilterId = document.getElementById('topFilterID');
        topFilterId.style.width = y + 'px';
        if (typeof global.translation === 'undefined') {
            let self = this;
            getTranslation(function (obj) {
                self.setState({translation: obj})
            })
        } else {
            this.setState({translation: global.translation})
        }
    };

    render() {
        const {selectedSortOption} = this.state;
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                color: '#000',
                backgroundColor: state.isSelected ? '#E4E4E4' : 'transparent',
                padding: '18px 24px',
                fontSize: '14px',
                borderBottom: '1px solid #f5f5f5',
                "&:first-of-type": {
                    borderRadius: '20px 20px 0 0',
                },
                "&:hover": {
                    backgroundColor: '#E4E4E4',
                },
                "&:last-child": {
                    borderBottom: 'none',
                    borderRadius: '0 0 20px 20px',
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                position: 'relative'

            }),

            control: (provided) => ({
                ...provided,
                minHeight: '14px',
                borderRadius: '8px',
                width: '210px',
                fontSize: '16px',
                color: '#000',
                backgroundColor: 'transparent',
                border: 'none',
                boxShadow: 'none',
                "::focus": {
                    borderColor: '#f5f5f5',
                },
                "@media only screen and (max-width: 768px)": {
                    width: '100%',
                },
            }),

            indicatorSeparator: () => {
            },

            dropdownIndicator: defaultStyles => ({
                ...defaultStyles,
                'svg ': {
                    display: 'none'
                },
                background: `url(${require("../../../assets/img/arrow_down.svg")}) no-repeat 50%`,
                paddingRight: '20px'
            }),

            valueContainer: (provided) => ({
                ...provided,
                padding: '2px 12px',
                justifyContent: 'center',
                "@media only screen and (max-width: 768px)": {
                    justifyContent: 'flex-start',
                },
            }),

            placeholder: (provided) => ({
                ...provided,
                color: '#2e3131'
            }),

            menu: (provided) => ({
                ...provided,
                background: '#ffffff',
                boxShadow: '0 2px 13px rgba(0, 0, 0, 0.132184)',
                borderRadius: '20px',
                padding: '0',
                border: '1px solid #ddd',
                width: '210px',
                zIndex: '999999',
                "@media only screen and (max-width: 768px)": {
                    width: '100%',
                },
            }),

            menuList: base => ({
                ...base,
                padding: 0,

            })
        };

        const Option = props => {
            return (
                <components.Option {...props}>
                    <label className="input-radio">
                        <input type="radio" checked={props.isSelected} onChange={() => null}/>
                        <span className="checkmark"></span>
                    </label>
                    {props.label}
                </components.Option>
            );
        };

        const optionsSort = [
            {value: 'priceLow', label: this.state.translation.GENERAL.cheap_exp},
            {value: 'priceHigh', label: this.state.translation.GENERAL.exp_cheap},
            {value: 'alphabetical', label: this.state.translation.GENERAL.a_z}
        ];

        let settings = {
            dots: true,
            infinite: true,
            speed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 7000,
            arrows: false
        };
        const {query} = this.state;
        return (
            <Aux>
                <section id="category-page">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-lg-3 col-md-4">
                                <div id="left-category">
                                    <div className="top-menu" id="topID">
                                        <NavLink to="/categories"
                                                 onClick={() => this.setState({subCategories: []})}>{this.state.translation.GENERAL.product_by_category}</NavLink>
                                        <NavLink to="/campaign"
                                                 onClick={() => this.setState({subCategories: []})}>{this.state.translation.GENERAL.product_by_campaign}</NavLink>
                                        <NavLink to="/firm"
                                                 onClick={() => this.setState({subCategories: []})}>{this.state.translation.GENERAL.product_by_firm}</NavLink>
                                    </div>
                                    <div className="center-block" onClick={() => this.setState({suggestProduct: true})}>
                                        {this.state.translation.GENERAL.not_found}
                                    </div>
                                    <div className="bottom-menu" id="bottomMenuID">
                                        {
                                            this.state.categoryProducts.map((item) => {
                                                return (
                                                    <Link to={`/campaign/${item.id}`} key={item.id}
                                                          onClick={() => this.setSubCategories(item)}>
                                                        {item.name}
                                                    </Link>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-8">
                                <div id="category-product">
                                    <div className="fixed-right">
                                        <div className="top" id="topFilterID">
                                            <div className="search">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="search"
                                                    id="search"
                                                    placeholder={this.state.translation.GENERAL.search_by_products}
                                                    value={query}
                                                    onChange={this.handleOnInputChange}
                                                />
                                            </div>
                                            <div className="sorting">
                                                <Select
                                                    value={selectedSortOption}
                                                    onChange={this.handleSortOptions}
                                                    options={optionsSort}
                                                    placeholder={this.state.translation.GENERAL.order_by}
                                                    styles={customStyles}
                                                    components={{Option}}
                                                />
                                            </div>
                                        </div>
                                        <div className="choose"></div>
                                    </div>
                                    {
                                        this.state.message.length > 0 ?
                                            <div className="no-data search-results">
                                                {this.state.message}
                                            </div>
                                            :
                                            null
                                    }
                                    <InfiniteScroll
                                        dataLength={this.state.searchList.length}
                                        next={this.next}
                                        hasMore={this.state.hasMore}
                                        loader={<h4>{this.state.translation.GENERAL.loading}</h4>}
                                        style={{overflow: 'hidden'}}>
                                        <div className="offered" id="offeredID">
                                            <div className="row">
                                                {
                                                    this.state.searchList.map((item, index) => {
                                                        return (
                                                            <div className=" col-lg-4 col-md-6 col-xs-12" key={index}>
                                                                <div className={`box ${item.bgColor ? "bgColor" : ""}`}>
                                                                    <div className="zoom"
                                                                         onClick={() => this.showOfferedModal(item)}>
                                                                        <i className="fal fa-search-plus"></i>
                                                                    </div>
                                                                    <div className="star">
                                                                        {
                                                                            item.star ?
                                                                                <span className="fa fa-star"
                                                                                      onClick={() => this.removeStar(item, index)}> </span>
                                                                                :
                                                                                <span className="far fa-star"
                                                                                      onClick={() => this.addStar(item, index)}> </span>
                                                                        }
                                                                    </div>
                                                                    <div className="product">
                                                                        {
                                                                            item.images.length > 0 ?
                                                                                <img
                                                                                    src={`https://canteen.az/bm/files/products/${item.id}/300/${item.images[0]} `}
                                                                                    alt={item.name}/>
                                                                                :
                                                                                <img
                                                                                    src={require('../../../assets/img/default-img.png')}
                                                                                    alt={item.name}/>
                                                                        }
                                                                        {
                                                                            parseFloat(item.oldPrice) > parseFloat(item.price) ?
                                                                                <div
                                                                                    className="discount-price">{this.getSale(item)}</div>
                                                                                : null
                                                                        }
                                                                        {
                                                                            item.countInBulk != 1 ?
                                                                                <div className="discount">
                                                                                    {
                                                                                        item.price * parseFloat(item.countInBulk) > item.bulkPrice && item.isBulk ?
                                                                                            <div className="left green">
                                                                                                {this.state.translation.GENERAL.box_sale} {this.getBoxSale(item)}
                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                    <div className="right">
                                                                                        <div className="componentsBox">
                                                                                            <label
                                                                                                className="switch-slider">
                                                                                                <input type="checkbox"
                                                                                                       onChange={() => this.toggle(item, index)}/>
                                                                                                <span
                                                                                                    className="switch-slider-inner round"></span>
                                                                                            </label>
                                                                                        </div>
                                                                                        {
                                                                                            item.isBulk ?
                                                                                                <span
                                                                                                    className="box-name">{item.measurementUnit === 1 || item.measurementUnit === 3 ? this.state.translation.GENERAL.box : this.state.translation.GENERAL.bag}</span>
                                                                                                :
                                                                                                <span
                                                                                                    className="box-name">{item.measurementUnit === 1 || item.measurementUnit === 3 ? this.state.translation.GENERAL.piece : this.state.translation.GENERAL.kq}</span>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                    <div className="name">{item.displayName}</div>
                                                                    {
                                                                        item.isBulk ?
                                                                            <div className="price">
                                                                                <span
                                                                                    className="discount-box">{item.price} AZN * {item.countInBulk}x = </span>
                                                                                <span
                                                                                    className="price-span">{item.bulkPrice}</span> AZN
                                                                            </div>
                                                                            :
                                                                            <div className="price">
                                                                                <span
                                                                                    className="discount-box throw">{(item.oldPrice !== null && item.oldPrice > item.price) ? item.oldPrice + ' AZN = ' : ''}</span>
                                                                                <span
                                                                                    className="price-span">{item.price}</span> AZN
                                                                            </div>
                                                                    }
                                                                    {
                                                                        item.showCount ?
                                                                            <div className="input-group calculate">
                                                                                {
                                                                                    item.isBulk ?
                                                                                        <div className="left">
                                                                                            <input type="number"
                                                                                                   className="form-control"
                                                                                                   onChange={(event) => this.addProductToCardPlenty(item, index, event)}
                                                                                                   value={this.getQuantityPlenty(item)}
                                                                                            />
                                                                                            <span
                                                                                                className="left-text">{item.measurementUnit === 1 || item.measurementUnit === 3 ? this.state.translation.GENERAL.box : this.state.translation.GENERAL.bag}: </span>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="left">
                                                                                            <input type="text"
                                                                                                   className="form-control"
                                                                                                   onChange={(event) => this.addProductToCard(item, index, event)}
                                                                                                   value={this.getQuantity(item)}
                                                                                                   autoFocus
                                                                                                   onFocus={e => e.currentTarget.select()}
                                                                                            />
                                                                                            <span
                                                                                                className="left-text">{item.measurementUnit === 1 || item.measurementUnit === 3 ? this.state.translation.GENERAL.piece : this.state.translation.GENERAL.kq}: </span>
                                                                                        </div>
                                                                                }
                                                                                {
                                                                                    item.isBulk ?
                                                                                        <div className="right">
                                                                                            <div className="minus"
                                                                                                 onClick={() => this.productsDecreasePlenty(item, index)}>
                                                                                                <img
                                                                                                    src={require('../../../assets/img/minus.svg')}
                                                                                                    alt="minus"/>
                                                                                            </div>
                                                                                            <div className="plus"
                                                                                                 onClick={() => this.productsIncreasePlenty(item, index)}>
                                                                                                <img
                                                                                                    src={require('../../../assets/img/plus.svg')}
                                                                                                    alt="plus"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="right">
                                                                                            <div className="minus"
                                                                                                 onClick={() => this.productsDecrease(item, index)}>
                                                                                                <img
                                                                                                    src={require('../../../assets/img/minus.svg')}
                                                                                                    alt="minus"/>
                                                                                            </div>
                                                                                            <div className="plus"
                                                                                                 onClick={() => this.productsIncrease(item, index)}>
                                                                                                <img
                                                                                                    src={require('../../../assets/img/plus.svg')}
                                                                                                    alt="plus"/>
                                                                                            </div>
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className="add"
                                                                                 onClick={() => this.showProductCount(item, index)}>
                                                                                {this.state.translation.BASKET.add_btn}
                                                                            </div>
                                                                    }
                                                                    <div className="alternative">
                                                                        <div className="left">
                                                                            <a href="#"
                                                                               onClick={() => this.showMemoryModal(item.id, index)}>
                                                                                <i className="fal fa-sync"></i>
                                                                                {this.state.translation.BASKET.alternative}
                                                                            </a>
                                                                        </div>
                                                                        <div className="right">
                                                                            <a onClick={() => this.showNoteModal(item)}>
                                                                                <i className="fal fa-envelope"></i>
                                                                                {this.state.translation.GENERAL.comment}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.memoryModal()}
                {this.offeredModal(settings)}
                {this.lightBoxModal()}
                {this.noteModal()}
                {this.suggestProductModal()}
            </Aux>
        )
    }
}
