import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import {NavLink,Link} from 'react-router-dom';
import axios from "axios/index";
import {getTranslation} from "../../../Helper/Helper";


export default class ConfirmOrders extends React.Component{
    constructor (props) {
        super (props);
        this.state = {
            confirmOrderList:[],
            permission : localStorage.getItem('permission'),
            translation: {
                ORDER: {},
                GENERAL: {},
                LOGIN_SIGNUP : {},
                ORDER_CONFIRMATION: {},
                BASKET:{}
            }
        };
    };

    /*get confirm orderList from api*/
    getData = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/order/confirms',
            params: {
                limit: 1000,
                offset:0
            },
            headers: {
                authorization : 'bearer '+  localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    self.setState({
                        confirmOrderList: response.data.result.data.confirmOrders,
                    });
                }
            })
            .catch(function (response) {

            });
    };

    componentDidMount () {
        this.getData();
        let header = document.getElementById('header');
        header.classList.remove("fixed");
        if(typeof global.translation === 'undefined'){
            let self = this;
            getTranslation(function(obj){
                self.setState({translation: obj})
            })
        }else{
            this.setState({translation: global.translation})
        }
    }

    render () {
        return (
            <Aux>
                <section id="order">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="order">
                                    <div className="left">
                                        <div className="left-nav">
                                            <NavLink to="/confirmOrders">
                                                {this.state.translation.ORDER.approved_orders}
                                            </NavLink>
                                            <NavLink to="/sendOrders">
                                                {this.state.translation.ORDER.sent_orders}
                                            </NavLink>
                                            <NavLink to="/acceptedOrders">
                                                {this.state.translation.ORDER.received_orders}
                                            </NavLink>
                                            <NavLink to="/returnedOrders">
                                                {this.state.translation.GENERAL.returned_orders}
                                            </NavLink>
                                            <NavLink to="/patternOrders">
                                                {this.state.translation.BASKET.template_orders}
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="order-conf-part">
                                        <div className="main">
                                            {
                                                (this.state.confirmOrderList === null || this.state.confirmOrderList.length === 0) ?
                                                    <div className="no-data">
                                                        {this.state.translation.GENERAL.no_information}
                                                    </div>
                                                    :
                                                    this.state.confirmOrderList.map((item)=> {
                                                        return (
                                                            <div className="box" key={item.id}>
                                                                <div className="inside">
                                                                    <div className="left">{this.state.translation.ORDER.status}</div>
                                                                    <div className="right yellow">{this.state.translation.ORDER.approved}</div>
                                                                </div>
                                                                <div className="inside">
                                                                    <div className="left">{this.state.translation.ORDER.order_number}</div>
                                                                    <div className="right">{item.docNumber}</div>
                                                                </div>
                                                                <div className="inside">
                                                                    <div className="left">{this.state.translation.ORDER.date}</div>
                                                                    <div className="right">{item.date}</div>
                                                                </div>
                                                                <div className="inside">
                                                                    <div className="left">{this.state.translation.LOGIN_SIGNUP.username}</div>
                                                                    <div className="right">{item.name + ' ' + item.surname} </div>
                                                                </div>
                                                                <div className="inside">
                                                                    <div className="left price">{this.state.translation.ORDER_CONFIRMATION.amount}</div>
                                                                    <div className="right price">{parseFloat(item.sumAmount).toFixed(2)} AZN</div>
                                                                </div>
                                                                {
                                                                    this.state.permission != 'ROLE_WRITE_ORDER' ?
                                                                        <div className="flex">
                                                                            <div className="inside no-margin">
                                                                                <div className="left button-left">
                                                                                    <Link to={{pathname :`/confirmOrdersMore/${item.id}?doc=${item.docNumber}` , state: item}} >{this.state.translation.GENERAL.read_more}</Link>
                                                                                </div>
                                                                                <div className="right button-right">
                                                                                    <Link to={{pathname :`/orderParametersConfirm/${item.id}?sum=${item.sumAmount}` , state: item}}>
                                                                                        {this.state.translation.LOGIN_SIGNUP.confirm_btn}
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="flex">
                                                                            <div className="inside no-margin" >
                                                                                <div className="left button-left">
                                                                                    <Link to={{pathname :`/confirmOrdersMore/${item.id}?doc=${item.docNumber}` , state: item}} >{this.state.translation.GENERAL.read_more}</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                }

                                                            </div>
                                                        )
                                                    })
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Aux>
        )
    }
}
