import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import {NavLink,Link} from 'react-router-dom';
import axios from "axios/index";
import {getTranslation} from "../../../Helper/Helper";


export default class SendOrders extends React.Component{
    constructor (props) {
        super (props);
        this.state = {
            sendOrderLists:[],
            compareLists:[],
            customerType : localStorage.getItem('customerType'),
            translation: {
                ORDER: {},
                GENERAL: {},
                LOGIN_SIGNUP : {},
                ORDER_CONFIRMATION: {},
                BASKET:{}
            }
        };
    };

    /*get send orderList  from api*/
    getData = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/order/completes',
            params: {
                limit: 50,
                offset:0
            },
            headers: {
                authorization : 'bearer '+ localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    self.setState({
                        sendOrderLists: response.data.result.data,
                    });
                }
            })
            .catch(function (response) {

            });
    };

    /*get compare orderList  from api*/
    getCompareOrders = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/order/accepted/compare',
            params: {
                limit: 1000,
                offset:0
            },
            headers: {
                authorization : 'bearer '+  localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    self.setState({
                        compareLists: response.data.result.data,
                    });
                    let sendOrderLists = self.state.sendOrderLists;
                    for (let i in sendOrderLists) {
                        for ( let j of self.state.compareLists) {
                            if(sendOrderLists[i].id==j.id) {
                                sendOrderLists[i].compare = true;
                            }
                        }
                    }
                    self.setState ({
                        sendOrderLists
                    });
                }
            })
            .catch(function (response) {
            });
    };

    componentDidMount () {
        this.getData();
        this.getCompareOrders();
        let header = document.getElementById('header');
        header.classList.remove("fixed");

        if(typeof global.translation === 'undefined'){
            let self = this;
            getTranslation(function(obj){
                self.setState({translation: obj})
            })
        }else{
            this.setState({translation: global.translation})
        }
    }

    render () {
        return (
           <Aux>
               <section id="order">
                   <div className="container-fluid own-container">
                       <div className="row">
                           <div className="col-md-12">
                               <div className="order">
                                   <div className="left">
                                       <div className="left-nav">
                                           {
                                               this.state.customerType !=9 ?
                                                   <NavLink to="/confirmOrders">
                                                       {this.state.translation.ORDER.approved_orders}
                                                   </NavLink>
                                                   : null
                                           }

                                           <NavLink to="/sendOrders">
                                               {this.state.translation.ORDER.sent_orders}
                                           </NavLink>
                                           <NavLink to="/acceptedOrders">
                                               {this.state.translation.ORDER.received_orders}
                                           </NavLink>
                                           {
                                               this.state.customerType !=9 ?
                                                   <NavLink to="/returnedOrders">
                                                       {this.state.translation.GENERAL.returned_orders}
                                                   </NavLink>
                                                   : null
                                           }
                                           <NavLink to="patternOrders">
                                               {this.state.translation.BASKET.template_orders}
                                           </NavLink>
                                       </div>
                                   </div>
                                   <div className="shipped-ord-part">
                                       <div className="main">
                                           {
                                               this.state.sendOrderLists.length === 0  || this.state.sendOrderLists ===null ?
                                                   <div className="no-data">
                                                       {this.state.translation.GENERAL.no_information}
                                                   </div>
                                                   :
                                                   this.state.sendOrderLists.map((item) => {
                                                       return (
                                                           <div className="box" key={item.id}>
                                                               <div className="inside">
                                                                   <div className="left">{this.state.translation.ORDER.status}</div>
                                                                   <div className="right blue">{this.state.translation.ORDER.sent_order}</div>
                                                               </div>
                                                               <div className="inside">
                                                                   <div className="left">{this.state.translation.ORDER.order_number}</div>
                                                                   <div className="right">{item.docNumber}</div>
                                                               </div>
                                                               <div className="inside">
                                                                   <div className="left">{this.state.translation.ORDER.order_delivery_date}</div>
                                                                   <div className="right">{item.deliveredDate}</div>
                                                               </div>
                                                               <div className="inside">
                                                                   <div className="left">{this.state.translation.ORDER.date}</div>
                                                                   <div className="right">{item.date}</div>
                                                               </div>
                                                               <div className="inside">
                                                                   <div className="left">{this.state.translation.LOGIN_SIGNUP.username}</div>
                                                                   <div className="right">{item.name}</div>
                                                               </div>
                                                               <div className="inside">
                                                                   <div className="left price">{this.state.translation.ORDER_CONFIRMATION.amount}</div>
                                                                   <div className="right price">{parseFloat(item.sumAmount).toFixed(2)} AZN</div>
                                                               </div>
                                                               <div className="flex">
                                                                   <div className="inside no-margin">
                                                                       <div className="left button-left">
                                                                           <Link to={{pathname :`/sendOrdersMore/${item.id}?doc=${item.docNumber}`}}>{this.state.translation.GENERAL.read_more}</Link>
                                                                       </div>
                                                                   </div>
                                                                   {
                                                                       item.compare ?
                                                                           <div className="inside no-margin">
                                                                               <div className="right button-right">
                                                                                   <Link to={{pathname :`/sendOrdersCompare/${item.id}?doc=${item.docNumber}`}}>
                                                                                       {this.state.translation.ORDER.compare}
                                                                                   </Link>
                                                                               </div>
                                                                           </div>
                                                                           : null
                                                                   }
                                                               </div>
                                                           </div>
                                                       )
                                                   })
                                           }
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </section>
           </Aux>
        )
    }
}
