import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import {Link} from 'react-router-dom';
import axios from "axios/index";
import Lightbox from 'react-image-lightbox';
import {
    ExcelExport,
    ExcelExportColumn
} from '@progress/kendo-react-excel-export';
import {getTranslation} from "../../../Helper/Helper";


export default class ConfirmOrdersMore extends React.Component{
    _exporter;
    export = () => {
        this._exporter.save();
    };
    constructor (props) {
        super (props);
        let productID = this.props.match.params.productID;
        productID = productID.split('?')[0];
        this.state = {
            productDetail: [],
            lightBox: '',
            showLightBox: false,
            photoIndex: 0,
            totalPrice: 0,
            docNumber: this.getQueryString('doc'),
            permission : localStorage.getItem('permission'),
            productID,
            translation: {
                ORDER: {},
                GENERAL: {},
                LOGIN_SIGNUP : {},
                ORDER_CONFIRMATION: {}
            }

        };
    };

    printPage = () => {
        window.print();
    };

    showLightBox = (item) => {
        this.setState({
            lightBox: item
        });
        this.setState({
            showLightBox: true
        });
    };

    /*get docNumber*/
    getQueryString(name){
        let queryString = this.props.location.pathname;
        let params = queryString.split(name+'=');
        if(params.length === 1){
            queryString = this.props.location.search;
            params = queryString.split(name+'=');
        }
        let param = params[1].split('&');
        return param[0]
    }

    closeLightBox = () => {
        this.setState({
            showLightBox: false
        });
    };

    /*get confirm orderList from api*/
    getData = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/order/confirm/products/' +this.state.productID,
            headers: {
                authorization : 'bearer '+  localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    const items = response.data.result.data;
                    items.map((item)=>{
                        item.totalPrice = item.quantity * item.price;
                        return item;
                    });
                    self.setState({
                        productDetail: items,
                    });
                    let totalPrice = 0;
                    for(let i of items){
                        totalPrice += parseFloat(i.quantity) * parseFloat(i.price);
                    }
                    self.setState({totalPrice : totalPrice.toFixed(2)})

                }
            })
            .catch(function (response) {
                console.log(response.error)
            });
    };

    removeProduct = (item,index) => {
        let self= this;
        axios({
            method: 'post',
            url: 'https://canteen.az/api/private/order/remove/confirm/' + this.state.productID + '/' + item.id,
            headers: {
                authorization : 'bearer '+ localStorage.getItem('token')
            },
        }).then(function (response) {
            if(response.data.status.code==200) {
                self.state.productLists.splice(index, 1);
                self.setState({
                    productLists: self.state.productLists
                });
            }
        })
            .catch(function (response) {

            });
    };

    updateConfirm = (item,type) => {
        item.qatLimit = item.qatLimit == '0.00' || null ? 1 : item.qatLimit;
        item.quantity = type == 'minus' ? parseFloat(item.quantity) - parseFloat(item.qatLimit) : parseFloat(item.quantity) + parseFloat(item.qatLimit);
        if(parseFloat(item.maxLimit) > 0 && (parseFloat(item.quantity) > item.maxLimit )) {
            return
        }
        let self = this;
        axios ({
            method:'post',
            url:'https://canteen.az/api/private/order/update/to/confirm',
            data: {
                note:"",
                overwrite: true,
                confirmId:this.state.productID,
                product:item.id,
                quantity:item.quantity,
                title:item.title
            },
            headers: {
                authorization : 'bearer '+ localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    self.getData();
                }
            })
            .catch(function (response) {

            });
    };

    componentDidMount () {
        this.getData();


        let header = document.getElementById('header');
        header.classList.remove("fixed");


        if(typeof global.translation === 'undefined'){
            let self = this;
            getTranslation(function(obj){
                self.setState({translation: obj})
            })
        }else{
            this.setState({translation: global.translation})
        }
    };

    lightBoxModal () {
        if(this.state.showLightBox) {
            return (
                <Lightbox
                    mainSrc={`https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/`+ this.state.lightBox.images[this.state.photoIndex]}
                    nextSrc={`https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/`+this.state.lightBox.images[(this.state.photoIndex + 1) % `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/`+this.state.lightBox.images.length]}
                    prevSrc={`https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/`+this.state.lightBox.images[(this.state.photoIndex + `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/`+this.state.lightBox.images.length - 1) % `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/`+this.state.lightBox.images.length]}
                    onCloseRequest={() => this.closeLightBox ()}
                    onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/`+this.state.lightBox.images.length - 1) % `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/`+this.state.lightBox.images.length,
                        })
                    }
                    onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + 1) % `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/`+this.state.lightBox.images.length,
                        })
                    }
                />
            )
        }
    }

    render () {
        const data = this.state.productDetail;
        return (
            <Aux>
                <section id="order">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="order">
                                    <div className="back">
                                        <Link to="/confirmOrders">
                                            <img src={require('../../../assets/img/left_back.svg')} alt="back"/>
                                            Back
                                        </Link>
                                    </div>
                                    <div className="order-conf-part all">
                                        <div className="more">
                                            <div className="top">
                                                <div className="left">
                                                    {this.state.docNumber}
                                                    <span>{this.state.productDetail.length}</span>
                                                </div>
                                                <div className="right">
                                                    <a href="#" className="print"  onClick={this.printPage}>
                                                        <img src={require('../../../assets/img/print.svg')} alt="print"/>
                                                        Print
                                                    </a>
                                                    <a  className="print" onClick={this.export}>
                                                        <img src={require('../../../assets/img/file.svg')} alt="file"/>
                                                        Excell download
                                                    </a>
                                                </div>
                                            </div>
                                            {
                                                this.state.productDetail.map((item,index)=> {
                                                    return (
                                                        <div className="box" key={item.id}>
                                                            <div className="zoom">
                                                                <button className="transparent" onClick={() => this.showLightBox(item)} >
                                                                    <i className="fal fa-search-plus"></i>
                                                                </button>
                                                            </div>
                                                            <div className="all-inside">
                                                                <div className="left">
                                                                    <img src={`https://canteen.az/bm/files/products/${item.id}/300/${item.images[0]} `} alt={item.name}/>
                                                                </div>
                                                                <div className="right">
                                                                    <div className="name_and_delete">
                                                                        <div className="left_name">
                                                                            <div className="name">{item.name}</div>
                                                                            <div className="price">{parseFloat(item.price).toFixed(2)} AZN</div>
                                                                        </div>
                                                                        <div className="delete" onClick={() =>this.removeProduct(item,index)}>
                                                                            <div className="delete-box">
                                                                                <img src={require('../../../assets/img/delete.svg')} alt="delete"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="calculate">
                                                                        <div className="left">
                                                                            <span className="piece"> {parseFloat(item.quantity).toFixed(2)}  </span>
                                                                            {item.measurementUnit === 1 || item.measurementUnit === 3 ? this.state.translation.GENERAL.piece : this.state.translation.GENERAL.kq}                                                                        </div>
                                                                        <div className="right-button">
                                                                            <div className="minus" onClick={()=>this.updateConfirm(item, 'minus')}>
                                                                                <img src={require('../../../assets/img/minus.svg')} alt="minus"/>
                                                                            </div>
                                                                            <div className="plus" onClick={()=>this.updateConfirm(item, 'plus')}>
                                                                                <img src={require('../../../assets/img/plus.svg')} alt="plus"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                })
                                            }
                                            <div className="result">
                                                <div className="inside">
                                                    <div className="left">{this.state.translation.ORDER.total_amount}</div>
                                                    <div className="right yellow">{this.state.totalPrice} AZN</div>
                                                </div>
                                                <div className="inside">
                                                    <div className="left">{this.state.translation.ORDER.delivery_amount}</div>
                                                    <div className="right yellow">0.00 AZN</div>
                                                </div>
                                                <div className="inside last">
                                                    <div className="left">{this.state.translation.ORDER.total_amount}</div>
                                                    <div className="right yellow">{this.state.totalPrice} AZN</div>
                                                </div>
                                            </div>
                                            <div className="confirm">
                                                <Link to={{pathname :`/orderParametersConfirm/${this.state.productID}`}} >Təsdiq et</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <ExcelExport
                                        data={data}
                                        fileName="Canteen.xlsx"
                                        ref={(exporter) => { this._exporter = exporter; }}
                                    >
                                        <ExcelExportColumn field="barcode" title="Barcode" width={200}/>
                                        <ExcelExportColumn field="name" title="Adı" width={200} />
                                        <ExcelExportColumn field="quantity" title="Sifarişin miqdarı" width={200} />
                                        <ExcelExportColumn field="price" title="Qiymət" width={200}/>
                                        <ExcelExportColumn field="note" title="Qeyd" width={200}/>
                                    </ExcelExport>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.lightBoxModal()}
            </Aux>
        )
    }
}
