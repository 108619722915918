import React from 'react';
import Layout from './components/Layout/Layout';
import {getTranslation}from './Helper/Helper';
import './assets/css/all.min.css';
import './assets/css/style.css';


function App() {
    getTranslation(function(obj){
        global.translation = obj
    })
    return (
        <div className="App">
            <Layout/>
        </div>
    );
}
export default App;


