import React from 'react';
import Aux from '../../hoc/Auxiliary';
import axios from "axios/index";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Select from 'react-select';
import swal from 'sweetalert';
import {getTranslation} from "../../Helper/Helper";

export default class Basket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderList: [],
            totalPrice: 0,
            isOpen: false,
            imageID: '',
            show: false,
            showMemory: false,
            memoryLists: [],
            valueOfMemory: '',
            title: '',
            total: 0,
            balanceBonusTotal: 0,
            balance: 0,
            measurementUnit: '',
            lightBox: '',
            showLightBox: false,
            photoIndex: 0,
            note: '',
            customerType: localStorage.getItem('customerType'),
            permission: localStorage.getItem('permission'),
            productItem: '',
            index: '',
            confirmModal: false,
            confirmAllModal: false,
            validationTemplateName: false,
            translation: {
                GENERAL_REPORT: {},
                GENERAL: {},
                ORDER: {},
                BASKET: {},
                LOGIN_SIGNUP: {},
                UTIL: {}
            },
            selectedOption: {}
        }
        ;

        this.handleChange = this.handleChange.bind(this);
    }

    //get orderList
    getOrders = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/order/cart/orders',
            params: {
                limit: 1000,
                offset: 0
            },
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    let balance = response.data.result.data.balance;
                    self.setState({
                        orderList: response.data.result.data.products,
                        balance: parseFloat(balance).toFixed(2)

                    });
                    self.calculateTotal()
                }
            })
            .catch(function (response) {

            });
    };

    removeProduct = (item, index) => {
        let self = this;
        axios({
            method: 'post',
            url: 'https://canteen.az/api/private/order/remove/cart/' + item.id,
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            },
        }).then(function (response) {
            if (response.data.status.code == 200) {
                self.state.orderList.splice(index, 1);
                self.setState({
                    orderList: self.state.orderList
                });
                self.showBasketBadge();
                self.calculateTotal()
            }
        })
            .catch(function (response) {

            });
        this.closeConfirmModal()
    };

    showLightBox = (item) => {
        this.setState({
            lightBox: item
        });
        this.setState({
            showLightBox: true
        });
    };

    closeLightBox = () => {
        this.setState({
            showLightBox: false
        });
    };

    showConfirmModal(item, index) {
        this.setState({
            productItem: item,
            confirmModal: true,
            index
        })
    }

    showConfirmAllModal() {
        this.setState({
            confirmAllModal: true
        })
    }

    closeConfirmModal() {
        this.setState({
            confirmModal: false
        })
    }

    closeConfirmAllModal() {
        this.setState({
            confirmAllModal: false
        })
    }

    removeAllProducts = () => {
        let orderList = [];
        let self = this;
        axios({
            method: 'post',
            url: 'https://canteen.az/api/private/order/clear/cart',
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            },
        }).then(function (response) {
            if (response.data.status.code == 200) {
                self.setState({
                    orderList: orderList
                });
                self.showBasketBadge();
                self.calculateTotal()
            }
        })
            .catch(function (response) {

            });

        this.closeConfirmAllModal()

    };

    //show basket notification
    showBasketBadge = () => {
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/order/cart/count',
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    document.getElementById('basketItem').innerText = response.data.result.data
                }
            })
            .catch(function (response) {

            });
    };

    updateNote = (event, index, item) => {
        item.note = event.target.value;
        let orderList = this.state.orderList;
        orderList.slice(index, 1, item);
        this.setState({
            orderList
        });
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.updateCart(item, '', item.quantity)
        }, 600);
    };

    updateCart = (item, type, quantity) => {
        item.qatLimit = item.qatLimit === '0.00' ? 1 : item.qatLimit;
        item.quantity = quantity ? quantity : type === 'minus' ? parseFloat(item.quantity) - parseFloat(item.qatLimit) : parseFloat(item.quantity) + parseFloat(item.qatLimit);
        if (parseFloat(item.maxLimit) > 0 && (parseFloat(item.quantity) > item.maxLimit)) {
            return
        }
        let self = this;
        axios({
            method: 'post',
            url: 'https://canteen.az/api/private/order/update/product/to/cart',
            data: {
                note: item.note,
                overwrite: true,
                product: item.id,
                quantity: item.quantity
            },
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    self.getOrders();
                }
            })
            .catch(function (response) {

            });
    };

    //select option
    handleOptions = (selectedOption) => {
        this.getMemoryDetails(selectedOption.value)
    };

    //get input value
    handleChange = (stateVar, event) => {
        this.setState({
            [stateVar]: event.target.value
        });

    };

    // add pattern list
    addToPattern = (event) => {
        let self = this;
        event.preventDefault();
        axios({
            method: 'post',
            url: 'https://canteen.az/api/private/order/confirm',
            data: {
                title: this.state.title,
                operationType: 2,
                note: this.state.note
            },
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    self.setState({
                        validationTemplateName: false
                    });
                    window.location.href = '/patternOrders';
                } else {
                    self.setState({
                        validationTemplateName: true
                    });
                }
            })
            .catch(function (response) {

            });
    };

    getMemoryDetails = (day) => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/order/completed/products',
            params: {
                sort: 1,
                day
            },
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    for (let i in response.data.result.data) {
                        response.data.result.data[i].countToAdd = 1;
                    }
                    self.setState({
                        memoryLists: response.data.result.data
                    });
                }
            })
            .catch(function (response) {

            });
    };

    //show total price in header
    calculateTotal() {
        let total = 0;
        for (let i of this.state.orderList) {
            total += i.quantity * i.price
        }

        this.setState({total});
        document.getElementById('basketTotal').innerText = parseFloat(total).toFixed(2)
    }

    productsIncrease = (item, index) => {
        const items = this.state.memoryLists;
        if (parseFloat(item.maxLimit) > 0 && item.quantity * item.countToAdd > item.maxLimit) {
            item.countToAdd--;
            return
        }
        ++item.countToAdd;
        items.splice(index, 1, item);
        this.setState({
            memoryLists: items,
            quantity: item.quantity
        })
    };

    productsDecrease = (item, index) => {
        const items = this.state.memoryLists;
        item.countToAdd = (item.countToAdd > 1) ? --item.countToAdd : 1;
        items.splice(index, 1, item);
        this.setState({
            memoryLists: items,
            quantity: item.quantity
        })

    };

    productsDecreaseMemory = (item, index) => {
        const memoryLists = this.state.memoryLists;
        item.changeQuantity = true;
        item.qatLimit = item.qatLimit == '0.00' ? 1 : parseFloat(item.qatLimit);
        item.quantity = parseFloat(item.quantity) || 0;
        if (item.quantity > 0) {
            item.quantity = item.quantity - item.qatLimit;
            memoryLists.splice(index, 1, item);
            this.setState({
                memoryLists: memoryLists
            });
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.updateCartMemory(item)
            }, 1000);

        }

    };

    productsIncreaseMemory = (item, index) => {
        let self = this;
        const memoryLists = this.state.memoryLists;
        item.changeQuantity = true;
        item.quantity = parseFloat(item.quantity) || 0;
        item.qatLimit = item.qatLimit == '0.00' ? 1 : parseFloat(item.qatLimit);
        item.quantity = parseFloat(item.quantity) + item.qatLimit;
        if (parseFloat(item.maxLimit) > 0 && (parseFloat(item.quantity) > item.maxLimit)) {
            item.quantity -= item.qatLimit
        }

        memoryLists.splice(index, 1, item);

        self.setState({
            bgColor: item.bgColor = true
        });
        setTimeout(function () {
            self.setState({
                bgColor: item.bgColor = false
            })
        }, 1000);

        clearTimeout(this.timer);

        this.timer = setTimeout(() => {

            if (item.hasBasket) this.updateCartMemory(item);
            else this.addToCart(item)

        }, 1000);

    };

    addToCart = (item) => {
        let quantity = item.isBulk ? item.quantity * item.countInBulk : item.quantity;
        let self = this;
        axios({
            method: 'post',
            url: 'https://canteen.az/api/private/order/save/to/cart',
            data: {
                note: item.note,
                overwrite: false,
                product: item.id,
                quantity: quantity
            },
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    self.getOrders();
                }
            })
            .catch(function (response) {

            });
    };

    updateCartMemory = (item) => {
        item.quantity = item.isBulk ? item.quantity * item.countInBulk : item.quantity;

        if (parseFloat(item.maxLimit) > 0 && (parseFloat(item.quantity) > item.maxLimit)) {
            return
        }
        let self = this;
        axios({
            method: 'post',
            url: 'https://canteen.az/api/private/order/update/product/to/cart',
            data: {
                note: item.note,
                overwrite: true,
                product: item.id,
                quantity: item.quantity
            },
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    self.getOrders();
                }
            })
            .catch(function (response) {

            });
    };

    componentDidMount() {
        this.getOrders();
        this.getUserStatics();
        this.getMemoryDetails(2);
        let header = document.getElementById('header');
        header.classList.remove("fixed");

        if (typeof global.translation === 'undefined') {
            let self = this;
            getTranslation(function (obj) {
                self.setState({translation: obj})
            })
        } else {
            this.setState({translation: global.translation})
        }

        let rightBlock = document.getElementById('sticky');
        let stickyChild = document.getElementById('sticky-child');

        window.onscroll = function () {
            if(window.pageYOffset > 180) {
                rightBlock.classList.add('sticky');
                stickyChild.style.width = rightBlock.offsetWidth + 'px';
            }

            else {
                rightBlock.classList.remove('sticky')
            }
        }
    }

    lightBoxModal() {
        if (this.state.showLightBox) {
            return (
                <Lightbox
                    mainSrc={`https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/` + this.state.lightBox.images[this.state.photoIndex]}
                    nextSrc={`https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/` + this.state.lightBox.images[(this.state.photoIndex + 1) % `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/` + this.state.lightBox.images.length]}
                    prevSrc={`https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/` + this.state.lightBox.images[(this.state.photoIndex + `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/` + this.state.lightBox.images.length - 1) % `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/` + this.state.lightBox.images.length]}
                    onCloseRequest={() => this.closeLightBox()}
                    onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/` + this.state.lightBox.images.length - 1) % `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/` + this.state.lightBox.images.length,
                        })
                    }
                    onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + 1) % `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/` + this.state.lightBox.images.length,
                        })
                    }
                />
            )
        }
    }

    confirmOrder = () => {

        if (this.state.customerType == 9) {
            if (this.state.total >= 100 ) {
                return (
                    window.location.href = '/orderParameters'
                )
            }
            else {
                return (
                    swal({
                        title: this.state.translation.GENERAL.warning,
                        text: this.state.translation.ORDER_CONFIRMATION.min_order_amount,
                        icon: "warning",
                        button: 'Ok',
                    })
                )
            }
        }

        else {
            return (
                window.location.href = '/orderParameters'
            )
        }


    };

    getQuantity = (item) => {
        for (let i of this.state.orderList) {
            if (item.id === i.id) {
                item.hasBasket = true;
                item.quantity = item.changeQuantity ? item.quantity : i.quantity
            }
        }
        return item.quantity ? parseFloat(item.quantity).toFixed(2) : 0
    };

    addConfirmOrders = () => {
        let date = new Date().getFullYear() + '-' + ("0" + (1 + new Date().getMonth())).slice(-2) + '-' + ("0" + (new Date().getDate())).slice(-2);
        axios({
            method: 'post',
            url: 'https://canteen.az/api/private/order/confirm',
            data: {
                note: '',
                operationType: 1,
                deliveredDate: date
            },
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    window.location.href = '/confirmOrders'
                }
            })
            .catch(function (response) {

            });
    };

    confirmModal() {
        if (this.state.confirmModal) {
            return (
                <div className="modal modal-confirm modal-visa" id="modal-visa" tabIndex="-1" role="dialog"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="close" data-dismiss="modal" aria-label="Close">
                                    <i className="fas fa-times"></i>
                                </div>
                                <div className="add-cart">
                                    <p className="confirm-head">{this.state.translation.GENERAL.delete_header}</p>
                                    <p>{this.state.translation.GENERAL.delete_product_message}</p>
                                    <div className="btn-block">
                                        <button
                                            onClick={() => this.closeConfirmModal()}>{this.state.translation.GENERAL.no}</button>
                                        <button
                                            onClick={() => this.removeProduct(this.state.productItem, this.state.index)}>{this.state.translation.GENERAL.yes}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop show"></div>
                </div>
            )
        }
    }

    confirmAllModal() {
        if (this.state.confirmAllModal) {
            return (
                <div className="modal modal-confirm modal-visa" id="modal-visa" tabIndex="-1" role="dialog"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="close" data-dismiss="modal" aria-label="Close">
                                    <i className="fas fa-times"></i>
                                </div>
                                <div className="add-cart">
                                    <p className="confirm-head">{this.state.translation.GENERAL.delete_header}</p>
                                    <p>{this.state.translation.GENERAL.pattern_message}</p>
                                    <div className="btn-block">
                                        <button
                                            onClick={() => this.closeConfirmAllModal()}>{this.state.translation.GENERAL.no}</button>
                                        <button
                                            onClick={this.removeAllProducts}>{this.state.translation.GENERAL.yes}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop show"></div>
                </div>
            )
        }
    }

    getUserStatics = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/user-statistics',
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    let remainingBonus = response.data.result.data.remainingBonus == null ? 0 : response.data.result.data.remainingBonus
                    let remainingBalance = response.data.result.data.remainingBalance == null ? 0 : response.data.result.data.remainingBalance
                    let balanceBonusTotal = parseFloat(remainingBonus + remainingBalance).toFixed(2);
                    self.setState({
                        balanceBonusTotal
                    });

                }
            })
            .catch(function (response) {

            });
    }


    render() {
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                color: '#000',
                backgroundColor: state.isSelected ? '#E4E4E4' : 'transparent',
                padding: '18px 24px',
                fontSize: '14px',
                borderBottom: '1px solid #f5f5f5',
                "&:hover": {
                    backgroundColor: '#E4E4E4',
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                position: 'relative'

            }),

            control: (provided) => ({
                ...provided,
                minHeight: '14px',
                height: '40px',
                borderRadius: '8px',
                width: '147px',
                fontSize: '14px',
                color: '#000',
                backgroundColor: 'transparent',
                border: '1px solid #A4A4A4',
                boxShadow: 'none',
                "::focus": {
                    borderColor: '#f5f5f5',
                }
            }),

            indicatorSeparator: () => {
            },

            dropdownIndicator: defaultStyles => ({
                ...defaultStyles,
                'svg ': {
                    display: 'none'
                },
                background: `url(${require("../../assets/img/arrow_down.svg")}) no-repeat 50%`,
                paddingRight: '20px'
            }),

            valueContainer: (provided) => ({
                ...provided,
                padding: '2px 12px'
            }),

            menu: (provided) => ({
                ...provided,
                background: '#ffffff',
                boxShadow: '2px 3px 3px rgba(0,0,0,.11)',
                borderRadius: '3px',
                padding: '0',
                border: '1px solid #ddd',
                zIndex: '9999'
            }),
            menuList: base => ({
                ...base,
                padding: 0,

            })
        };
        const memoryOptions = [
            {value: 2, label: this.state.translation.UTIL.last_2_days},
            {value: 5, label: this.state.translation.UTIL.last_5_days},
            {value: 10, label: this.state.translation.UTIL.last_10_days},
            {value: 15, label: this.state.translation.UTIL.last_15_days},
        ];


        return (
            <Aux>
                <section id="basket">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="basket">
                                    <div className="left_part">
                                        <div className="search">
                                            <div className="memory" onClick={() => this.setState({showMemory: true})}>
                                                {this.state.translation.GENERAL.memory}
                                                <img src={require('../../assets/img/profil_calendar.svg')}
                                                     alt="calendar"/>
                                            </div>
                                        </div>
                                        <div className="more">
                                            <div className="top">
                                                <div className="left">
                                                    {this.state.translation.BASKET.basket}
                                                </div>
                                                <div className="right">
                                                    <a href="#" onClick={() => this.setState({show: true})}>
                                                        {this.state.translation.ORDER.pattern_alert}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="box-content">
                                                {
                                                    this.state.orderList.map((item, index) => {
                                                        return (
                                                            <div className="box" key={item.id}>
                                                                <div className="zoom">
                                                                    <button className="transparent"
                                                                            onClick={() => this.showLightBox(item)}>
                                                                        <i className="fal fa-search-plus"></i>
                                                                    </button>
                                                                </div>
                                                                <div className="all-inside">
                                                                    <div className="left">
                                                                        <img
                                                                            src={`https://canteen.az/bm/files/products/${item.id}/300/${item.images[0]} `}
                                                                            alt={item.name}/>
                                                                    </div>
                                                                    <div className="right">
                                                                        <div className="name_and_delete">
                                                                            <div className="left_name">
                                                                                <div className="name">{item.displayName}</div>
                                                                                <div className="price">
                                                                                    {parseFloat(item.price).toFixed(2)} AZN
                                                                                    <img src={require('../../assets/img/right_arrow.svg')} alt="arrow"/>
                                                                                    <span className="red">{parseFloat(item.price * item.quantity).toFixed(2)} AZN</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="same"
                                                                                 onClick={() => this.showConfirmModal(item, index)}>
                                                                                <div className="delete">
                                                                                    <div className="delete-box">
                                                                                        <img
                                                                                            src={require('../../assets/img/delete.svg')}
                                                                                            alt="delete"/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="calculate">
                                                                            <div className="left">
                                                                                <span className="piece"> {item.quantity} </span>
                                                                                {item.measurementUnit === 1 || item.measurementUnit === 3 ? this.state.translation.GENERAL.piece : this.state.translation.GENERAL.kq}
                                                                            </div>
                                                                            <div className="right-button">
                                                                                <div className="minus"
                                                                                     onClick={() => this.updateCart(item, 'minus')}>
                                                                                    <img
                                                                                        src={require('../../assets/img/minus.svg')}
                                                                                        alt="minus"/>
                                                                                </div>
                                                                                <div className="plus"
                                                                                     onClick={() => this.updateCart(item, 'plus')}>
                                                                                    <img
                                                                                        src={require('../../assets/img/plus.svg')}
                                                                                        alt="plus"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="note">
                                                                    <form>
                                                                        <div className="form-group">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="note_main01"
                                                                                name="note_main01"
                                                                                placeholder=""
                                                                                value={item.note === null ? '' : item.note}
                                                                                onChange={(event, index) => this.updateNote(event, index, item)}
                                                                            />
                                                                            <span>{this.state.translation.GENERAL.comment}:</span>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>


                                                        )
                                                    })
                                                }
                                            </div>
                                            {
                                                this.state.orderList.length > 0 ?
                                                    <div className="delete-all"
                                                         onClick={() => this.showConfirmAllModal()}>
                                                        <a href="#" data-toggle="modal"
                                                           data-target="#modal-sure">{this.state.translation.BASKET.delete_all}</a>
                                                    </div>
                                                    : null
                                            }

                                        </div>
                                    </div>
                                    <div className="right-block" id="sticky">
                                        <div className="right_part" id="sticky-child">
                                            <div className="inside">
                                                <div
                                                    className="left-basket bold">{this.state.translation.BASKET.balance}</div>
                                                <div className="right-basket font-bold"> {this.state.balanceBonusTotal} AZN </div>
                                            </div>
                                            <div className="inside">
                                                <div
                                                    className="left-basket bold">{this.state.translation.ORDER.limit}</div>
                                                <div className="right-basket font-bold"> {this.state.balance} AZN </div>
                                            </div>
                                            <div className="inside">
                                                <div
                                                    className="left-basket font-bold">{this.state.translation.ORDER.order_amount}</div>
                                                <div className="right-basket">{this.state.total.toFixed(2)} AZN</div>
                                            </div>
                                            <div className="inside">
                                                <div
                                                    className="left-basket font-bold">{this.state.translation.ORDER.delivery_amount}</div>
                                                <div className="right-basket">0.00 AZN</div>
                                            </div>
                                            <div className="inside brown">
                                                <div
                                                    className="left-basket font-bold">{this.state.translation.ORDER.total_amount}</div>
                                                <div className="right-basket">{this.state.total.toFixed(2)} AZN</div>
                                            </div>
                                            {
                                                this.state.customerType != 9 ?
                                                    <div>
                                                        <div className="button" onClick={() => this.addConfirmOrders()}>
                                                            {this.state.translation.ORDER.approved_alert}
                                                        </div>
                                                        {
                                                            this.state.permission != 'ROLE_WRITE_ORDER' ?
                                                                <div className="button" onClick={() => this.confirmOrder()}>
                                                                    {this.state.translation.BASKET.complete_btn}
                                                                </div>
                                                                : null
                                                        }

                                                    </div>

                                                    :
                                                    <div className="button" onClick={() => this.confirmOrder()}>
                                                        {this.state.translation.LOGIN_SIGNUP.confirm_btn}
                                                    </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {
                    this.state.show ?
                        <div
                            className="modal modal-template"
                            id="modal-template"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="modal-template"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="close" data-dismiss="modal" aria-label="Close">
                                            <i className="fas fa-times"></i>
                                        </div>
                                        <div className="section-head">
                                            {this.state.translation.GENERAL.add_pattern_orders}
                                        </div>
                                        <form onSubmit={this.addToPattern}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control name"
                                                    id="name"
                                                    name="name"
                                                    placeholder={this.state.translation.ORDER.pattern_name}
                                                    value={this.state.title}
                                                    onChange={(event) => this.handleChange('title', event)}
                                                />
                                                {
                                                    this.state.validationTemplateName ?
                                                        <div className="invalid-feedback">
                                                            {this.state.translation.ORDER.enter_pattern_name}
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <button type="submit">{this.state.translation.BASKET.add_btn}</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-backdrop show" onClick={() => this.setState({show: false})}></div>
                        </div> : null
                }

                {
                    this.state.showMemory ?
                        <div
                            className="modal modal-memory"
                            id="modal-memory"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="modal-memory"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        {/*  <div className="close"  aria-label="Close">
                                            <i className="fas fa-times"></i>
                                        </div>*/}
                                        <div className="more">
                                            <div className="top">
                                                <div className="left">
                                                    {this.state.translation.BASKET.basket}
                                                </div>
                                                <div className="right">
                                                    <div className="form-group">
                                                        <Select
                                                            defaultValue={memoryOptions[0]}
                                                            onChange={this.handleOptions}
                                                            options={memoryOptions}
                                                            styles={customStyles}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                this.state.memoryLists.length > 0 ?
                                                    <div>
                                                        {
                                                            this.state.memoryLists.map((item, index) => {
                                                                return (
                                                                    <div className="box" key={item.id}>
                                                                        <div className="zoom">
                                                                            <button className="transparent"
                                                                                    onClick={() => this.showLightBox(item)}>
                                                                                <i className="fal fa-search-plus"></i>
                                                                            </button>
                                                                        </div>
                                                                        <div className="all-inside">
                                                                            <div className="left">
                                                                                <img
                                                                                    src={`https://canteen.az/bm/files/products/${item.id}/300/${item.images[0]} `}
                                                                                    alt={item.name}/>
                                                                            </div>
                                                                            <div className="right">
                                                                                <div className="name_and_delete">
                                                                                    <div className="left_name">
                                                                                        <div
                                                                                            className="name">{item.name}</div>
                                                                                        <div className="price">
                                                                                            {parseFloat(item.price).toFixed(2)} AZN
                                                                                            <img
                                                                                                src={require('../../assets/img/right_arrow.svg')}
                                                                                                alt="arrow"/>
                                                                                            <span
                                                                                                className="red">{parseFloat(item.price * item.quantity).toFixed(2)} AZN</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <div className="same">
                                                                                        <div className="delete">
                                                                                            <div className="delete-box">
                                                                                                <img src="/assets/img/basket.svg" alt="" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>*/}
                                                                                </div>
                                                                                <div className="calculate">
                                                                                    <div className="left">
                                                                                        <span
                                                                                            className="piece">{this.getQuantity(item)}</span>
                                                                                        <span
                                                                                            className="calculate_name">{item.measurementUnit === 1 || item.measurementUnit === 3 ? this.state.translation.GENERAL.piece : this.state.translation.GENERAL.kq}</span>
                                                                                    </div>
                                                                                    <div className="right-button">
                                                                                        <div className="minus"
                                                                                             onClick={() => this.productsDecreaseMemory(item, index)}>
                                                                                            <img
                                                                                                src={require('../../assets/img/minus.svg')}
                                                                                                alt="minus"/>
                                                                                        </div>
                                                                                        <div className="plus"
                                                                                             onClick={() => this.productsIncreaseMemory(item, index)}>
                                                                                            <img
                                                                                                src={require('../../assets/img/plus.svg')}
                                                                                                alt="plus"/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="note">
                                                                            <form>
                                                                                <div className="form-group">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="note_modal01"
                                                                                        placeholder=""
                                                                                        value={item.note === null ? '' : item.note}
                                                                                        onChange={(event, index) => {
                                                                                            item.note = event.target.value;
                                                                                            let memoryLists = this.state.memoryLists;
                                                                                            memoryLists.slice(index, 1, item);
                                                                                            this.setState({
                                                                                                memoryLists
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    :

                                                    <div
                                                        className="no-data">{this.state.translation.GENERAL.no_information}</div>

                                            }

                                            <div className="return" onClick={() => this.setState({showMemory: false})}>
                                                <a href="#">{this.state.translation.BASKET.return_basket}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-backdrop show" onClick={() => this.setState({showMemory: false})}></div>
                        </div>
                        : null
                }

                {this.lightBoxModal()}
                {this.confirmModal()}
                {this.confirmAllModal()}
            </Aux>
        )
    }
}
