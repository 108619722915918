import React from 'react';
import Aux from '../../hoc/Auxiliary';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios/index";
import {
    ExcelExport,
    ExcelExportColumn
} from '@progress/kendo-react-excel-export';
import {getTranslation} from "../../Helper/Helper";

export default class History extends React.Component{
    _exporter;
    export = () => {
        this._exporter.save();
    };
    constructor (props) {
        super (props);
        let date = new Date();
        date.setMonth(date.getMonth() -1);
        this.state = {
            startDate: date,
            endDate: new Date (),
            branchList:[],
            branches:[],
            ids:[],
            branchDataList:[],
            checkID:'',
            checked:false,
            showModal:false,
            modalItem:'',
            branchDetailList:[],
            translation : {
                GENERAL_REPORT : {},
                GENERAL : {}
            }
        };

        this.handleChange = this.handleChange.bind(this);
    }

    /*filter checkList*/
    handleChange(e) {
        const value = parseInt(e.target.value);
        let ids = this.state.ids;
        if (this.state.ids.indexOf(value) < 0) {
            ids.push(value);
        }
        else {
            ids = ids.filter(el => el !== value);
        }
        this.setState({ ids });
    }

    showModal = (item) => {
        this.setState({
            showModal : true,
            modalItem : item
        }, ()=>{
            this.getBranchDetail()
        })
    }

    closeModal () {
        this.setState({
            showModal : false
        })
    }

    handleStartDate = date => {
        this.setState({
            startDate: date
        });
    };

    handleEndDate = date => {
        this.setState({
            endDate: date
        });
    };

    printPage = () => {
        window.print();
    };

    /*get branchList*/

    getData = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/report/branches',
            headers: {
                authorization : 'bearer '+  localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    self.setState({
                        branchList: response.data.result.data,
                    });
                    if(response.data.result.data.length === 1){
                        let ids = [];
                        ids.push(response.data.result.data[0].id);
                        self.setState({ids}, ()=>{
                            self.getBranchData()
                        })

                    }
                }
            })
            .catch(function (response) {

            });
    };

    calculate = (event) => {
        event.preventDefault();
        this.getBranchData()
    };

    /*get brancList Data*/
    getBranchData = () => {
        let ids=this.state.ids.join(',');
        let self =this;
        let startDate=this.state.startDate.getFullYear() + '-' + ("0" + (1 + this.state.startDate.getMonth())).slice(-2) + '-' + ("0" + (this.state.startDate.getDate())).slice(-2);
        let endDate=this.state.endDate.getFullYear() + '-' + ("0" + (1 + this.state.endDate.getMonth())).slice(-2) + '-' + ("0" + (this.state.endDate.getDate())).slice(-2);
        axios({
            method: 'get',
            url: ` https://canteen.az/api/private/report/calculate`,
            params: {
                startDate,
                endDate,
                branches: ids,

            },
            headers: {
                authorization : 'bearer '+  localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    self.setState({
                        branchDataList : response.data.result.data
                    })
                }
            })
            .catch(function (response) {

            });
    };

    /*branclisht detail modal data*/
     getBranchDetail () {
        let self =this;
        let startDate=this.state.startDate.getFullYear() + '-' + ("0" + (1 + this.state.startDate.getMonth())).slice(-2) + '-' + ("0" + (this.state.startDate.getDate())).slice(-2);
        let endDate=this.state.endDate.getFullYear() + '-' + ("0" + (1 + this.state.endDate.getMonth())).slice(-2) + '-' + ("0" + (this.state.endDate.getDate())).slice(-2);
         axios({
            method: 'get',
            url: ` https://canteen.az/api/private/report/detail`,
            params: {
                startDate,
                endDate,
                branch: this.state.modalItem.branchId,
            },
            headers: {
                authorization : 'bearer '+  localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    self.setState({
                        branchDetailList : response.data.result.data
                    })
                }
            })
            .catch(function (response) {

            });
    }

    modalData () {
        if(this.state.showModal) {
            return (
                <div
                    className="modal  modal-table"
                    id="modal-table"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="modal-table"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="close" data-dismiss="modal" aria-label="Close" onClick={()=> this.closeModal()}>
                                    <i className="fas fa-times"></i>
                                </div>
                                <div className="section-head">
                                    <div className="left">{this.state.modalItem.branchName}</div>
                                    <div className="right">
                                        <a href="#" className="print" onClick={this.printPage}>
                                            <img src={require('../../assets/img/print.svg')} alt="print"/>
                                            Print
                                        </a>
                                        <a href="#" className="print">
                                            <img src={require('../../assets/img/file.svg')} alt="file"/>
                                            Excell download
                                        </a>
                                    </div>
                                </div>
                                <div className="section-body table-responsive">
                                    <table className="table table-bordered ">
                                        <thead>
                                        <tr>
                                            <th scope="col">Filial</th>
                                            <th scope="col">{this.state.translation.GENERAL_REPORT.first_amount}</th>
                                            <th scope="col">{this.state.translation.GENERAL_REPORT.increase}</th>
                                            <th scope="col">{this.state.translation.GENERAL_REPORT.decrease}</th>
                                            <th scope="col">{this.state.translation.GENERAL_REPORT.last_amount}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.branchDetailList.map((item)=> {
                                                return (
                                                    <tr>
                                                        <td>{item.branchName}</td>
                                                        <td>{parseFloat(item.firstTotalAmount).toFixed(2)} ₼</td>
                                                        <td>{item.incomeTotalAmount != null ? parseFloat(item.incomeTotalAmount).toFixed(2) : '0.00'} ₼</td>
                                                        <td>{item.outcomeTotalAmount != null ? parseFloat(item.outcomeTotalAmount).toFixed(2) : '0.00'} ₼</td>
                                                        <td>{parseFloat(item.lastTotalAmount).toFixed(2)} ₼</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="button" onClick={()=> this.closeModal()}>
                                    {this.state.translation.GENERAL.close_btn}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop show" onClick={()=> this.closeModal()}></div>
                </div>

            )
        }
    }

    componentDidMount () {
        this.getData ();

        let header = document.getElementById('header');
        header.classList.remove("fixed");

        if(typeof global.translation === 'undefined'){
            let self = this;
            getTranslation(function(obj){
                self.setState({translation: obj})
            })
        }else{
            this.setState({translation: global.translation})
        }
    }

    render () {
        const data = this.state.branchDetailList;
        return (
            <Aux>
                <section id="history">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="history">
                                    <div className="left">
                                        <form onSubmit={this.calculate}>
                                            <div className="left-nav">
                                                <div className="check-block">
                                                    <div className="checkbox-part">
                                                        {
                                                            this.state.branchList.map((item ,i)=> {
                                                                return (
                                                                    <div className="checkbox" key={i}>
                                                                        <label>{item.name}
                                                                            <input type="checkbox" checked={this.state.ids.indexOf(item.id) >= 0}  value={item.id}  onChange={this.handleChange}/>
                                                                            <span className="mark-checked"></span>
                                                                        </label>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                </div>
                                                <button className="button" type="submit">{this.state.translation.GENERAL_REPORT.calculate}</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="history-right-part">
                                        <div className="main">
                                            <div className="top">
                                                <form onSubmit={this.calculate}>
                                                    <div className="form-group">
                                                        <DatePicker
                                                            selected={this.state.startDate}
                                                            onChange={this.handleStartDate}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <DatePicker
                                                            selected={this.state.endDate}
                                                            onChange={this.handleEndDate}
                                                        />
                                                    </div>
                                                </form>
                                            </div>
                                            {
                                                this.state.branchDataList.map((item,i)=> {
                                                    return (
                                                        <div className="box" key={i}>
                                                            <div className="inside">
                                                                <div className="left head">{item.branchName}</div>
                                                                <div className="right head" onClick={()=> this.showModal(item)}>{this.state.translation.GENERAL.see_details}
                                                                    <i className="far fa-search-plus"></i>
                                                                </div>
                                                            </div>
                                                            <div className="inside">
                                                                <div className="left">{this.state.translation.GENERAL_REPORT.first_amount}</div>
                                                                <div className="right">{(item.firstTotalAmount) !==null ? parseFloat(item.firstTotalAmount).toFixed(2) : '0.00'} AZN</div>
                                                            </div>
                                                            <div className="inside">
                                                                <div className="left">{this.state.translation.GENERAL_REPORT.increase}</div>
                                                                <div className="right">{(item.incomeTotalAmount) !==null ? parseFloat(item.incomeTotalAmount).toFixed(2) : '0.00'} AZN</div>
                                                            </div>
                                                            <div className="inside">
                                                                <div className="left">{this.state.translation.GENERAL_REPORT.decrease}</div>
                                                                <div className="right">{(item.outcomeTotalAmount) !==null ? parseFloat(item.outcomeTotalAmount).toFixed(2) : '0.00'}  AZN</div>
                                                            </div>
                                                            <div className="inside">
                                                                <div className="left price">{this.state.translation.GENERAL_REPORT.last_amount}</div>
                                                                <div className="right price">{parseFloat(item.lastTotalAmount).toFixed(2)} AZN</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.modalData()}
                <ExcelExport
                    data={data}
                    fileName="Canteen.xlsx"
                    ref={(exporter) => { this._exporter = exporter; }}
                >
                    <ExcelExportColumn field="branchName" title="Filial" width={200}/>
                    <ExcelExportColumn field="firstTotalAmount" title="İlk məbləğ" width={200} />
                    <ExcelExportColumn field="incomeTotalAmount" title="Artma" width={200} />
                    <ExcelExportColumn field="outcomeTotalAmount" title="Azalma" width={200}/>
                    <ExcelExportColumn field="lastTotalAmount" title="Qalıq" width={200} />
                </ExcelExport>
            </Aux>
        )
    }
}
