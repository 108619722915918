import React from 'react';
import Aux from '../../hoc/Auxiliary';
import Select , {components} from 'react-select';
import { Link } from "react-router-dom";
import axios from "axios/index";
import {getTranslation} from "../../Helper/Helper";

const optionsLang = [
    { value: 'az', label: 'Az' },
    { value: 'en', label: 'En' },
    { value: 'ru', label: 'Ru' }
];

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        let currentLang = localStorage.getItem('lang') || 'az';
        this.state = {
            showRegister: false,
            Auth: localStorage.getItem('token'),
            userName: '',
            phoneNumber: "",
            currentLang: {value: currentLang, label: currentLang.toUpperCase()},
            translation: {
                LOGIN_SIGNUP: {},
                BASKET: {},
                GENERAL:{}
            }
        }
    }

    /*modal register*/
    showRegister() {
        this.setState({
            showRegister: !this.state.showRegister
        })
    }

    /*Get name from api*/
    getName = () => {
        let self =this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/user/info',
            headers: {
                authorization : 'bearer '+  localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    self.setState({
                        userName : response.data.result.data.branch.name
                    });

                }
            })
            .catch(function (response) {

            });

    };

    /*Get phoneNumber from api*/
    getPhoneNumber = () => {
        let self =this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/user/get-phone-number',
            headers: {
                authorization : 'bearer '+  localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    self.setState({
                        phoneNumber : response.data.result.data
                    });

                }
            })
            .catch(function (response) {

            });
    };

    /*change select option*/
    handleLangOptions = selectedOption => {
       localStorage.setItem('lang', selectedOption.value);
       window.location.reload()
    };

    componentDidMount () {
        this.getName();
        this.getPhoneNumber();
        if(typeof global.translation === 'undefined'){
            let self = this;
            getTranslation(function(obj){
                self.setState({translation: obj})
            })
        }else{
            this.setState({translation: global.translation})
        }
    }

    render() {
        const { selectedOption } = this.state;
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                color: '#000',
                backgroundColor: state.isSelected ? '#E4E4E4' : 'transparent',
                padding:'5px',
                fontSize : '14px',
                borderBottom: '1px solid #f5f5f5',
                "&:hover": {
                    backgroundColor:  '#E4E4E4',
                },
                "&:last-child": {
                    borderBottom: 'none',
                },
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                position: 'relative',
                cursor: 'pointer'

            }),

            control: (provided) => ({
                ...provided,
                minHeight: '14px',
                fontSize:'16px',
                color:'#000',
                backgroundColor:'transparent',
                border:'none',
                boxShadow: 'none',
                "::focus" : {
                    borderColor: '#f5f5f5',
                },
                cursor: 'pointer'

            }),

            indicatorSeparator: () => {},

            dropdownIndicator: defaultStyles => ({
                ...defaultStyles,
                'svg ' : {
                    display : 'none'
                },
            }),

            valueContainer : (provided)=>( {
                ...provided,
                padding:'2px 12px',
                justifyContent: 'center',
            }),

            placeholder : (provided) => ( {
                ...provided,
                color:'#2e3131'
            }),

            menu : (provided) => ({
                ...provided,
                background: '#ffffff',
                boxShadow: '0 2px 13px rgba(0, 0, 0, 0.132184)',
                padding:'0',
                zIndex:'999999',
                margin: '0'
            }),

            menuList: base => ({
                ...base,
                padding: '0',
                margin:'0'

            })
        };
        const DropdownIndicator = props => {
            return (
                <components.DropdownIndicator {...props}>
                    <span className="fal fa-angle-down"></span>
                </components.DropdownIndicator>
            );
        };
        return (
            <Aux>
                <section id="head">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="head">
                                    <div className="logo">
                                        <Link to="/">
                                            <img src={require('../../assets/img/logo.svg')} alt="logo"/>
                                        </Link>
                                    </div>
                                    <div className="right">
                                        <div className="lang">
                                            <Select
                                                defaultValue={this.state.currentLang}
                                                value={selectedOption}
                                                onChange={this.handleLangOptions}
                                                options={optionsLang}
                                                styles = { customStyles }
                                                components = {{DropdownIndicator}}
                                            />
                                        </div>
                                        {
                                            this.state.Auth !== null ?
                                                <Link to="/profile" className="profile">
                                                    <div className="left-part">
                                                        <div className="img">
                                                            <img src={require('../../assets/img/userIcon.png')} alt="user"/>
                                                        </div>
                                                    </div>
                                                    <div className="right-part">
                                                        <div className="name">{this.state.userName}</div>
                                                        <div className="phone">{this.state.phoneNumber}</div>
                                                    </div>
                                                </Link>
                                                :
                                                <div className="enterance">
                                                    <div className="registration">
                                                        <a href="#"  onClick={() => this.showRegister()}>{this.state.translation.LOGIN_SIGNUP.registration}</a>
                                                    </div>
                                                    <div className="enter">
                                                        <Link to="/createPassword">{this.state.translation.LOGIN_SIGNUP.entrance}</Link>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    this.state.showRegister ?
                           <div    className="modal modal-account"
                                   id="modal-account"
                                   tabIndex="-1"
                                   role="dialog"
                                   aria-labelledby="modal-account"
                                   aria-hidden="true"
                           >
                               <div className="modal-dialog modal-lg" role="document">
                                   <div className="modal-content">
                                       <div className="modal-body">
                                           <div className="close" data-dismiss="modal" aria-label="Close">
                                               <i className="fas fa-times"></i>
                                           </div>
                                           <div className="head">
                                               {this.state.translation.GENERAL.about_text7_}
                                           </div>
                                           <div className="box" onClick={()=>this.showRegister()}>
                                               <Link to="/individual">
                                                   <img className="personal" src={require('../../assets/img/individual.svg')} alt="individual"/>
                                                   {this.state.translation.LOGIN_SIGNUP.create_individual_account }
                                               </Link>
                                           </div>
                                           <div className="box" onClick={()=>this.showRegister()}>
                                               <Link to="/cooperative">
                                                   <img className="personal" src={require('../../assets/img/business.svg')} alt="business"/>
                                                   {this.state.translation.LOGIN_SIGNUP.create_business_account }
                                               </Link>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                               <div className="modal-backdrop show" onClick={()=>this.showRegister()}></div>
                           </div>
                        : null
                }
            </Aux>
        )
    }
}
