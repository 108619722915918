import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import {Link} from 'react-router-dom';
import axios from "axios/index";
import Lightbox from 'react-image-lightbox';
import {
    ExcelExport,
    ExcelExportColumn
} from '@progress/kendo-react-excel-export';
import {getTranslation} from "../../../Helper/Helper";


export default class AcceptedOrdersMore extends React.Component{
    _exporter;
    export = () => {
        this._exporter.save();
    };
    constructor (props) {
        super (props);
        this.state = {
            productDetail: [],
            lightBox: '',
            showLightBox: false,
            photoIndex: 0,
            totalPrice: 0,
            docNumber: this.getQueryString('doc'),
            translation: {
                ORDER: {},
                GENERAL: {},
                LOGIN_SIGNUP : {},
                ORDER_CONFIRMATION: {}
            }
        };
    };

    /*get docNumber*/
    getQueryString(name){
        let queryString = this.props.location.pathname;
        let params = queryString.split(name+'=');
        if(params.length === 1){
            queryString = this.props.location.search;
            params = queryString.split(name+'=');
        }

        let param = params[1].split('&');
        return param[0]
    }

    printPage = () => {
        window.print();
    };

    showLightBox = (item) => {
        this.setState({
            lightBox: item
        });
        this.setState({
            showLightBox: true
        });
    };

    closeLightBox = () => {
        this.setState({
            showLightBox: false
        });
    };

    /*get accepted orderList from api*/
    getData = () => {
        let item=this.props.match.params.productID;
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/order/accepted/products/' +item,
            headers: {
                authorization : 'bearer '+  localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    const items = response.data.result.data;
                    items.map((item)=>{
                        item.totalPrice = item.quantity * item.price;
                        return item;
                    });
                    self.setState({
                        productDetail: items,
                    });
                    let totalPrice = 0;
                    for(let i of items){
                        totalPrice += parseFloat(i.quantity) * parseFloat(i.price);
                    }
                    self.setState({totalPrice : totalPrice.toFixed(2)})

                }
            })
            .catch(function (response) {
                console.log(response.error)
            });
    };

    componentDidMount () {
        this.getData();

        let header = document.getElementById('header');
        header.classList.remove("fixed");

        if(typeof global.translation === 'undefined'){
            let self = this;
            getTranslation(function(obj){
                self.setState({translation: obj})
            })
        }else{
            this.setState({translation: global.translation})
        }
    };

    lightBoxModal () {
        if(this.state.showLightBox) {
            return (
                <Lightbox
                    mainSrc={`https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/`+ this.state.lightBox.images[this.state.photoIndex]}
                    nextSrc={`https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/`+this.state.lightBox.images[(this.state.photoIndex + 1) % `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/`+this.state.lightBox.images.length]}
                    prevSrc={`https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/`+this.state.lightBox.images[(this.state.photoIndex + `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/`+this.state.lightBox.images.length - 1) % `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/`+this.state.lightBox.images.length]}
                    onCloseRequest={() => this.closeLightBox ()}
                    onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/`+this.state.lightBox.images.length - 1) % `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/`+this.state.lightBox.images.length,
                        })
                    }
                    onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + 1) % `https://canteen.az/bm/files/products/${this.state.lightBox.id}/300/`+this.state.lightBox.images.length,
                        })
                    }
                />
            )
        }
    }

    render () {
        const data = this.state.productDetail;
        return (
            <Aux>
                <section id="order">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="order">
                                    <div className="back">
                                        <Link to="/acceptedOrders">
                                            <img src={require('../../../assets/img/left_back.svg')} alt="back"/>
                                            Back
                                        </Link>
                                    </div>
                                    <div className="accepted-ord-part all">
                                        <div className="more">
                                            <div className="top">
                                                <div className="left">
                                                    {this.state.docNumber}
                                                    <span>{this.state.productDetail.length}</span>
                                                </div>
                                                <div className="right">
                                                    <a href="#" className="print"  onClick={this.printPage}>
                                                        <img src={require('../../../assets/img/print.svg')} alt="print"/>
                                                        Print
                                                    </a>
                                                    <a  className="print" onClick={this.export}>
                                                        <img src={require('../../../assets/img/file.svg')} alt="file"/>
                                                        Excell download
                                                    </a>
                                                </div>
                                            </div>
                                            {
                                                this.state.productDetail.map((item)=> {
                                                    return (
                                                        <div className="box" key={item.id}>
                                                            <div className="zoom">
                                                                <button className="transparent" onClick={() => this.showLightBox(item)} >
                                                                    <i className="fal fa-search-plus"></i>
                                                                </button>
                                                            </div>
                                                            <div className="all-inside">
                                                                <div className="left">
                                                                    <img src={`https://canteen.az/bm/files/products/${item.id}/300/${item.images[0]} `} alt={item.name}/>
                                                                </div>
                                                                <div className="right">
                                                                    <div className="name_and_price">
                                                                        <div className="left_name">
                                                                            <div className="name">{item.displayName}</div>
                                                                            <div className="price">{parseFloat(item.price).toFixed(2)} AZN</div>
                                                                        </div>
                                                                        <div className="calculate-price">
                                                                            <span>{parseFloat(item.quantity).toFixed(2)}</span>
                                                                            {item.measurementUnit === 1 || item.measurementUnit === 3 ? this.state.translation.GENERAL.piece : this.state.translation.GENERAL.kq}
                                                                        </div>
                                                                    </div>
                                                                    <div className="all-price">{(parseFloat(item.quantity) * parseFloat(item.price)).toFixed(2)} AZN</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                })
                                            }
                                            <div className="result">
                                                <div className="inside">
                                                    <div className="left">{this.state.translation.ORDER.total_amount}</div>
                                                    <div className="right yellow">{this.state.totalPrice} AZN</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ExcelExport
                                        data={data}
                                        fileName="Canteen.xlsx"
                                        ref={(exporter) => { this._exporter = exporter; }}
                                    >
                                        <ExcelExportColumn field="barcode" title="Barcode" width={200}/>
                                        <ExcelExportColumn field="name" title="Adı" width={200} />
                                        <ExcelExportColumn field="quantity" title="Sifarişin miqdarı" width={200} />
                                        <ExcelExportColumn field="price" title="Qiymət" width={200}/>
                                        <ExcelExportColumn field="note" title="Qeyd" width={200}/>
                                    </ExcelExport>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.lightBoxModal()}
            </Aux>
        )
    }
}
