import React from 'react';
import Aux from '../../hoc/Auxiliary'
import Navigation from '../Navigation/Navigation'
import Header from '../Header/Header'
import Home from  '../../containers/Home/Home';
import CategoryProducts from  '../../containers/Category/CategoryProducts';
import CampaignProducts from  '../../containers/Category/CampaignProducts';
import CategoryBusiness from '../../containers/Category/CategoryBusinessList/CategoryBusiness';
import CampaignBusiness from '../../containers/Category/CategoryBusinessList/CampaignBusiness';
import FirmProducts from '../../containers/Category/CategoryBusinessList/FirmProducts';
import CampaignPublic from '../../containers/Category/CategoryPublicList/CampaignPublic';
import CategoryPublic from '../../containers/Category/CategoryPublicList/CategoryPublic';
import History from  '../../containers/History/History';
import About from  '../../containers/About/About';
import Contact from  '../../containers/Contact/Contact';
import Individual from '../../containers/Register/Individual';
import Cooperative from '../../containers/Register/Cooperative';
import Callback from '../../containers/Callback/Callback';
import CreatePassword from '../../containers/CreatePassword/CreatePassword';
import ForgetCustomerCode from '../../containers/ForgetCustomerCode/ForgetCustomerCode';
import OrderParameters from '../../containers/Basket/OrderParameters';
import OrderParametersConfirm from '../../containers/Basket/OrderParametersConfirm';
import SendOrdersMore from '../../containers/Orders/SendOrders/SendOrdersMore';
import SendOrders from '../../containers/Orders/SendOrders/SendOrders';
import AcceptedOrders from '../../containers/Orders/AcceptedOrders/AcceptedOrders';
import AcceptedOrdersMore from '../../containers/Orders/AcceptedOrders/AcceptedOrdersMore';
import SendOrdersCompare from '../../containers/Orders/SendOrders/SendOrdersCompare';
import PatternOrders from '../../containers/Orders/PatternOrders/PatternOrders';
import PatternOrdersMore from '../../containers/Orders/PatternOrders/PatternOrdersMore';
import ConfirmOrders from '../../containers/Orders/ConfirmOrders/ConfirmOrders';
import ConfirmOrdersMore from '../../containers/Orders/ConfirmOrders/ConfirmOrdersMore';
import ReturnedOrders from '../../containers/Orders/ReturnedOrders/ReturnedOrders';
import ReturnedOrdersMore from '../../containers/Orders/ReturnedOrders/ReturnedOrdersMore';
import Basket from '../../containers/Basket/Basket';
import Profile from '../../containers/Profile/Profile';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import ProtectedRoute from "../../containers/ProtectedRoute";

export default class Layout extends React.Component {
    constructor (props) {
        super (props);
        this.state = {
            customerType : localStorage.getItem('customerType')
        }
    }

    render() {
        return (
            <Aux>
                <Router>
                    <div id="header">
                        <Header/>
                        <Navigation />
                    </div>
                    <main>
                        <Switch>
                            <Route path="/individual"  component={Individual}/>
                            <Route path="/cooperative"  component={Cooperative}/>
                            <Route path="/createPassword" component={CreatePassword}/>
                            <Route path="/forgetCustomerCode"  component={ForgetCustomerCode}/>
                            <Route path="/callback"   component={Callback}/>
                            <Route path="/" exact={true} component={Home}/>
                            {
                                localStorage.getItem('token') ?
                                    (this.state.customerType !=9 ?
                                            <ProtectedRoute path="/categories/:categoryID?/:subCategory?" exact component={CategoryBusiness}/>
                                            :
                                            <ProtectedRoute path="/categories/:categoryID?/:subCategory?" exact component={CategoryProducts}/>
                                    )
                                    :
                                    <Route path="/categories/:categoryID?/:subCategory?"  component={CategoryPublic}/>
                            }
                            {
                                localStorage.getItem('token') ?
                                    (this.state.customerType !=9 ?
                                        <ProtectedRoute path="/campaign/:categoryID?/:subCategory?" exact component={CampaignBusiness}/>
                                        :
                                        <ProtectedRoute path="/campaign/:categoryID?/:subCategory?" exact component={CampaignProducts}/>
                                    )
                                    :
                                        <Route path="/campaign/:categoryID?/:subCategory?"  component={CampaignPublic}/>

                            }
                            <ProtectedRoute path="/firm/:categoryID?/:subCategory?" exact component={FirmProducts}/>
                            <ProtectedRoute path="/history" exact component={History}/>
                            <Route path="/about"  component={About}/>
                            <ProtectedRoute path="/contact"  component={Contact}/>
                            <ProtectedRoute path="/orderParameters"  component={OrderParameters}/>
                            <ProtectedRoute path="/orderParametersConfirm/:confirmID"  component={OrderParametersConfirm}/>
                            <ProtectedRoute path="/sendOrders"  component={SendOrders} />
                            <ProtectedRoute path="/sendOrdersMore/:productID" component={SendOrdersMore}/>
                            <ProtectedRoute path="/sendOrdersCompare/:productID" component={SendOrdersCompare}/>
                            <ProtectedRoute path="/acceptedOrders"  component={AcceptedOrders}/>
                            <ProtectedRoute path="/acceptedOrdersMore/:productID"  component={AcceptedOrdersMore}/>
                            <ProtectedRoute path="/patternOrders"  component={PatternOrders}/>
                            <ProtectedRoute path="/patternOrdersMore/:patternID"  component={PatternOrdersMore}/>
                            <ProtectedRoute path="/confirmOrders"  component={ConfirmOrders}/>
                            <ProtectedRoute path="/confirmOrdersMore/:productID"  component={ConfirmOrdersMore}/>
                            <ProtectedRoute path="/returnedOrders"  component={ReturnedOrders}/>
                            <ProtectedRoute path="/returnedOrdersMore/:productID"  component={ReturnedOrdersMore}/>
                            <ProtectedRoute path="/basket"  component={Basket}/>
                            <ProtectedRoute path="/profile"  component={Profile}/>
                            {this.props.children}
                        </Switch>
                    </main>
                </Router>
            </Aux>
        )
    }
}
