import React from 'react';
import Aux from '../../hoc/Auxiliary'
import axios from 'axios';
import { Link } from "react-router-dom";
import {getTranslation}from '../../Helper/Helper';


export default class CreatePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName:'',
            code:'',
            password:'canteen',
            confirmPassword:'',
            showCreatePassword: false,
            showRegister: false,
            validation: false,
            translation: {
                LOGIN_SIGNUP: {},
                BASKET: {},
                GENERAL:{}
            }
        };
        this.handleChange = this.handleChange.bind(this);
    }

    /*get input value*/
    handleChange = (stateVar, event) => {
        this.setState({
            [stateVar]: event.target.value
        });
    };

    showRegister() {
        this.setState({
            showRegister: !this.state.showRegister
        })
    }

    /*check user code*/
    checkCode=(event) => {
        event.preventDefault();
        let data = {
            code:this.state.code,
        };
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/public/auth/check',
            params: data,
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                   /* self.setState ( {
                        showCreatePassword: true
                    });*/
                    self.setState ( {
                        fullName: response.data.result.data.name
                    });

                    self.setState ( {
                        validation: false
                    });
                    localStorage.setItem('code', response.data.result.data.code);
                    localStorage.setItem('user_code', self.state.code);
                    localStorage.setItem('customerType', response.data.result.data.customerType);
                    let data = {
                        code:localStorage.getItem('code'),
                        password: self.state.password
                    };
                    axios({
                        method: 'post',
                        url: 'https://canteen.az/api/public/auth/create/password',
                        data: data
                    })
                        .then(function (response) {
                            if(response.data.status.code==200) {
                                localStorage.setItem('token', response.data.result.data.token);
                                localStorage.setItem('permission', response.data.result.data.permissions[0]);
                                window.location.href = '/';
                            }
                        })
                        .catch(function (response) {

                        });
                }
                else {
                    self.setState ( {
                        validation: true
                    })
                }
            })
            .catch(function (response) {

            });
    };

    /*createPassword=(event) => {
        let self= this;
        event.preventDefault();
        if(this.state.password != this.state.confirmPassword) {
            self.setState ( {
                validation: true
            });
            return
        }
        let data = {
            code: localStorage.getItem('code'),
            password: this.state.password
        };
        axios({
            method: 'post',
            url: 'https://canteen.az/api/public/auth/create/password',
            data: data,
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    self.setState ( {
                        validation: false
                    });
                    localStorage.setItem('token', response.data.result.data.token);
                    localStorage.setItem('permission', response.data.result.data.permissions[0]);
                    window.location.href = '/';
                    }
            })
            .catch(function (response) {

            });
    };*/

    componentDidMount() {
        let header = document.getElementById('header');
        header.classList.remove("fixed");
        if(typeof global.translation === 'undefined'){
            let self = this;
            getTranslation(function(obj){
                self.setState({translation: obj})
            })
        }else{
            this.setState({translation: global.translation})
        }
    }

    render() {
        return (
            <Aux>
                <section id="special">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="special">
                                    <div className="left">
                                        <div>
                                            <div className="section-head">{this.state.translation.LOGIN_SIGNUP.entrance}</div>
                                            <form onSubmit={this.checkCode}>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="client_code"
                                                        name="client_code"
                                                        placeholder={this.state.translation.LOGIN_SIGNUP.customer_code}
                                                        value={this.state.code}
                                                        onChange={(event) => this.handleChange('code', event)}
                                                    />
                                                    {
                                                        this.state.validation ?
                                                            <div className="invalid-feedback">
                                                                {this.state.translation.LOGIN_SIGNUP.customer_code} !
                                                            </div>
                                                            : null
                                                    }
                                                    <button type="submit">{this.state.translation.BASKET.continue}</button>
                                                </div>
                                            </form>
                                        </div>
                                       {/* { this.state.showCreatePassword ?
                                            (
                                                <div>
                                                    <div className="section-head">{this.state.translation.LOGIN_SIGNUP.create_password}</div>
                                                    <form onSubmit={this.createPassword}>
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control name"
                                                                name="name"
                                                                placeholder=''
                                                                value={this.state.fullName}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <input
                                                                type="password"
                                                                className="form-control"
                                                                id="client_code"
                                                                name="client_code"
                                                                placeholder={this.state.translation.LOGIN_SIGNUP.new_password}
                                                                onChange={(event) => this.handleChange('password', event)}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <input
                                                                type="password"
                                                                className="form-control"
                                                                id="client_code"
                                                                name="client_code"
                                                                placeholder={this.state.translation.LOGIN_SIGNUP.retype_new_pass}
                                                                onChange={(event) => this.handleChange('confirmPassword', event)}
                                                            />
                                                            {
                                                                this.state.validation ?
                                                                    <div className="invalid-feedback">
                                                                        {this.state.translation.LOGIN_SIGNUP.password_wrong}
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                        <button type="submit">{this.state.translation.BASKET.continue}</button>
                                                    </form>
                                                </div>
                                            ) :

                                            (
                                                <div>
                                                    <div className="section-head">{this.state.translation.LOGIN_SIGNUP.entrance}</div>
                                                    <form onSubmit={this.checkCode}>
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="client_code"
                                                                name="client_code"
                                                                placeholder={this.state.translation.LOGIN_SIGNUP.customer_code}
                                                                value={this.state.code}
                                                                onChange={(event) => this.handleChange('code', event)}
                                                            />
                                                            {
                                                                this.state.validation ?
                                                                    <div className="invalid-feedback">
                                                                        {this.state.translation.LOGIN_SIGNUP.customer_code} !
                                                                    </div>
                                                                    : null
                                                            }
                                                            <button type="submit">{this.state.translation.BASKET.continue}</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            )
                                        }*/}
                                        <div className="account">
                                            <a href="#"  onClick={() => this.showRegister()}> {this.state.translation.LOGIN_SIGNUP.sign_up}</a>
                                            <Link to="/forgetCustomerCode"> {this.state.translation.LOGIN_SIGNUP.forget_code}</Link>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <img src={require('../../assets/img/special.png')} alt="special"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="special-footer">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="footer">
                                    &copy; 2020 {this.state.translation.GENERAL.copyright_text}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    this.state.showRegister ?
                        <div    className="modal modal-account"
                                id="modal-account"
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="modal-account"
                                aria-hidden="true"
                        >
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                     {/*   <div className="close" data-dismiss="modal" aria-label="Close">
                                            <i className="fas fa-times"></i>
                                        </div>*/}
                                        <div className="head">
                                            {this.state.translation.GENERAL.about_text7_}
                                        </div>
                                        <div className="box" onClick={()=>this.showRegister()}>
                                            <Link to="/individual">
                                                <img className="personal" src={require('../../assets/img/individual.svg')} alt="individual"/>
                                                {this.state.translation.LOGIN_SIGNUP.create_individual_account}
                                                <img className="arrow" src={require('../../assets/img/right.svg')} alt="arrow"/>
                                            </Link>
                                        </div>
                                        <div className="box" onClick={()=>this.showRegister()}>
                                            <Link to="/cooperative">
                                                <img className="personal" src={require('../../assets/img/business.svg')} alt="business"/>
                                                {this.state.translation.LOGIN_SIGNUP.create_business_account}
                                                <img className="arrow" src={require('../../assets/img/right.svg')} alt="arrow"/>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-backdrop show" onClick={()=>this.showRegister()}></div>
                        </div>
                        : null
                }
            </Aux>
        )
    }
}
