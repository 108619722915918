import React from 'react';
import Aux from '../../hoc/Auxiliary';
import { Link } from "react-router-dom";
import {getTranslation} from "../../Helper/Helper";
import { HashLink } from 'react-router-hash-link';

export default class Footer extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            customerType : localStorage.getItem('customerType'),
            Auth: localStorage.getItem('token'),
            translation: {
                LEFT_MENU: {},
                GENERAL: {},
                ORDER:{}
            },
        }
    };

    componentDidMount() {
        if(typeof global.translation === 'undefined'){
            let self = this;
            getTranslation(function(obj){
                self.setState({translation: obj})
            })
        }else{
            this.setState({translation: global.translation})
        }

    }
    render() {
        return (
            <Aux>
                <footer id="footer">
                    <div className="footer-top">
                        <div className="container-fluid own-container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="footer">
                                        <div className="box">
                                            <Link to="/" className="d-block">
                                                <img className="logo" src={require('../../assets/img/logo.svg')} alt="logo"/>
                                            </Link>
                                            <div className="social">
                                                <a href="https://play.google.com/store/apps/details?id=az.wecode.canteen" target="_blank">
                                                    <img className="app" src={require('../../assets/img/googlePlay.svg')} alt="application"/>
                                                </a>
                                            </div>
                                            <div className="social">
                                                <a href="https://apps.apple.com/app/canteen-llc/id6463211547" target="_blank">
                                                    <img className="app" src={require('../../assets/img/appStore.svg')} alt="application"/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="head">{this.state.translation.LEFT_MENU.home}</div>
                                            <Link to="/categories">
                                                <div className="inside">{this.state.translation.GENERAL.fast_order}</div>
                                            </Link>
                                            <Link  to={this.state.Auth !== null ? '/' : '/createPassword'}>
                                                <div className="inside">{this.state.translation.GENERAL.offered_product}</div>
                                            </Link>
                                            <HashLink smooth to="/#categories">
                                                <div className="inside">{this.state.translation.GENERAL.all_products}</div>
                                            </HashLink>
                                        </div>
                                        <div className="box">
                                            <div className="head">{this.state.translation.GENERAL.fast_order}</div>
                                            <Link to="/categories">
                                                <div className="inside">{this.state.translation.GENERAL.product_by_category}</div>
                                            </Link>
                                            {
                                                this.state.customerType != 9 ?
                                                    <Link to={this.state.Auth !== null ? '/firm' : '/createPassword'}>
                                                        <div className="inside">{this.state.translation.GENERAL.product_by_firm}</div>
                                                    </Link>
                                                    : ''
                                            }
                                            <Link to="/campaign">
                                                <div className="inside">{this.state.translation.GENERAL.product_by_campaign}</div>
                                            </Link>
                                        </div>
                                        <div className="box">
                                            <div className="head">{this.state.translation.ORDER.orders}</div>
                                            {
                                                this.state.customerType != 9 ?
                                                    <Link to={this.state.Auth !== null ? '/confirmOrders' : '/createPassword'}>
                                                        <div className="inside">{this.state.translation.ORDER.approved_orders}</div>
                                                    </Link> : ''
                                            }
                                            <Link to={this.state.Auth !== null ? '/sendOrders' : '/createPassword'}>
                                                <div className="inside">{this.state.translation.ORDER.sent_orders}</div>
                                            </Link>
                                            <Link to={this.state.Auth !== null ? '/acceptedOrders' : '/createPassword'}>
                                                <div className="inside">{this.state.translation.ORDER.received_orders}</div>
                                            </Link>
                                            {
                                                this.state.customerType != 9 ?
                                                    <Link to={this.state.Auth !== null ? '/returnedOrders' : '/createPassword'}>
                                                        <div className="inside">{this.state.translation.GENERAL.returned_orders}</div>
                                                    </Link> : ''
                                            }
                                            <Link to={this.state.Auth !== null ? '/patternOrders' : '/createPassword'}>
                                                <div className="inside">{this.state.translation.GENERAL.templates}</div>
                                            </Link>
                                        </div>
                                        <div className="box">
                                            <div className="head"> {this.state.translation.GENERAL.about}</div>
                                            <HashLink smooth to="/about#software" >
                                                <div className="inside">{this.state.translation.GENERAL.about_text4}</div>
                                            </HashLink>
                                            <HashLink smooth to="/about#partners" >
                                                <div className="inside" >{this.state.translation.GENERAL.partners}</div>
                                            </HashLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="container-fluid own-container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="last">
                                        <div className="left">
                                            &copy; 2023 {this.state.translation.GENERAL.copyright_text}
                                        </div>
                                        <div className="right">
                                            <a href="https://www.facebook.com" target="_blank">
                                                <i className="fab fa-facebook-square"></i>
                                            </a>
                                            <a href="https://www.youtube.com/channel/UCF-AFeUiQ6Uwhx_cTPB46oA" target="_blank">
                                                <i className="fab fa-youtube"></i>
                                            </a>
                                            <a href="https://instagram.com" target="_blank">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                           {/* <a href="#" target="_blank">
                                                <i className="fab fa-twitter"></i>
                                            </a>*/}
                                            <a href="https://www.linkedin.com" target="_blank">
                                                <i className="fab fa-linkedin"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </Aux>
        )
    }
}
