import React from 'react';
import Aux from '../../hoc/Auxiliary';
import Footer from '../../components/Footer/Footer'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {Link} from 'react-router-dom';
import axios from "axios";
import {getTranslation} from "../../Helper/Helper";
import CookieConsent  from "react-cookie-consent";

export default class Home extends React.Component {
    constructor (props) {
        super (props);
        this.state = {
            showOffered:false,
            toggleBtn:false,
            productID:'',
            categories:[],
            suggested:[],
            quantity:'',
            isStar:false,
            offered:'',
            images:[],
            showNotificationModal:false,
            notificationText:'',
            translation: {
                GENERAL: {},
                BASKET: {}
            },
            token : localStorage.getItem('token')
        };
        this.showProductCount = this.showProductCount.bind(this);
    }

    showProductCount(item,index){
        item.showCount = !item.showCount;
        let suggested = this.state.suggested;
        suggested.slice(index, 1, item);
        this.setState({suggested});
    };

    showOfferedModal (item) {
        this.setState({
            offered: item
        });
        this.setState({
            showOfferedModal : true
        });
    };

    closeOfferedModal () {
        this.setState({
            showOfferedModal : false
        })
    }

    productsIncrease = (item,index) => {
        const items = this.state.suggested;
        item.changeQuantity = true;
        item.quantity = parseFloat(item.quantity) || 0;
        item.qatLimit = item.qatLimit == '0.00' ? 1 : parseFloat(item.qatLimit);


        item.quantity = parseFloat(item.quantity) + parseFloat(item.qatLimit);
        if(parseFloat(item.maxLimit) > 0 && (parseFloat(item.quantity) > item.maxLimit )){
            item.quantity -= item.qatLimit
        }

        items.splice(index,1,item);
        this.setState({
            suggested: items
        });

        clearTimeout(this.timer);

        this.timer = setTimeout( () => {

            if(item.hasBasket) this.updateCart(item)
            else this.addToCart(item)

        }, 1000);

    };

    getOrders = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/order/cart/orders',
            params: {
                limit: 1000,
                offset: 0
            },
            headers: {
                authorization : 'bearer '+ localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    self.setState({
                        orderList: response.data.result.data.products
                    });

                    document.getElementById('basketItem').innerText = response.data.result.data.products.length
                }
            })
            .catch(function (response) {

            });
    };

    productsDecrease = (item,index) => {
        const items = this.state.suggested;
        item.changeQuantity = true;
        item.qatLimit = item.qatLimit == '0.00' ? 1 : parseFloat(item.qatLimit);
        item.quantity = parseFloat(item.quantity) || 0;
        if(item.quantity > 0){
            item.quantity = item.quantity - item.qatLimit;
            items.splice(index,1,item);
            this.setState({
                suggested: items
            });

            clearTimeout(this.timer);

            this.timer = setTimeout( () => {
                this.updateCart(item)
            }, 1000);


        }

    };

    getQuantity = (item) => {
        for(let i of this.state.orderList){
            if(item.id === i.id){
                item.hasBasket = true
                item.quantity = item.changeQuantity ? item.quantity : i.quantity
            }
        }
        return item.quantity ? parseFloat(item.quantity).toFixed(2) : 0

    };

    getCategories = () => {
        let self = this;
        let url = this.state.token ?'https://canteen.az/api/private/product/categories': 'https://canteen.az/api/public/product/categories';
        let headers = {
            'Accept-Language': localStorage.getItem('lang') || 'az'
        };

        if(this.state.token) headers.authorization = 'bearer '+ localStorage.getItem('token')

        axios({
            method: 'get',
            url: url,
            params: {
                limit: 50,
                offset: 0
            },
            headers
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    self.setState({
                        categories: response.data.result.data
                    })
                }
            })
            .catch(function (response) {

            });
    };

    getSuggested = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/product/suggested',
            params: {
                limit: 10,
                offset: 0
            },
            headers: {
                authorization : 'bearer '+  localStorage.getItem('token'),
                'Accept-Language': localStorage.getItem('lang') || 'az'
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    self.setState({
                        suggested: response.data.result.data
                    })
                }
            })
            .catch(function (response) {

            });
    };

    addToCart = (item) => {
        let self = this;
        axios ({
            method:'post',
            url:'https://canteen.az/api/private/order/save/to/cart',
            data: {
                note:item.note,
                overwrite:false,
                product:item.id,
                quantity:item.quantity
            },
            headers: {
                authorization : 'bearer '+ localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code ==200) {
                    self.getOrders()
                }
            })
            .catch(function (response) {

            });
        this.closeOfferedModal()
    };

    updateCart = (item) => {
        if(parseFloat(item.maxLimit) > 0 && (parseFloat(item.quantity) > item.maxLimit )) {
            return
        }
        let self = this;
        axios ({
            method:'post',
            url:'https://canteen.az/api/private/order/update/product/to/cart',
            data: {
                note:item.note,
                overwrite: true,
                product:item.id,
                quantity:item.quantity
            },
            headers: {
                authorization : 'bearer '+ localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    self.getOrders();
                }
            })
            .catch(function (response) {

            });
    };

    getNotificationModal () {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/public/product/notification/1',
            params: {
                limit: 1000,
                offset: 0
            },
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    let notificationText = response.data.result.data.text;
                    self.setState({
                        notificationText
                    })
                    if (notificationText.length > 0 )
                        self.setState({
                            showNotificationModal :true
                        })
                }
            })
            .catch(function (response) {

            });
    }

    componentDidMount() {
        this.getCategories();
        this.getSuggested();
        this.getOrders();
        this.getNotificationModal();
        let header = document.getElementById('header');
        header.classList.remove("fixed");
        let self=this;
        axios ({
            url:'https://canteen.az/bm/files/banner/banner.json',
        })
            .then(function (response) {
                self.setState({
                    images: response.data.banners
                })
            })
            .catch(function (response) {

            });
        if(typeof global.translation === 'undefined'){
            let self = this;
            getTranslation(function(obj){
                self.setState({translation: obj})
            })
        }else{
            this.setState({translation: global.translation})
        }
    }

    offeredModal(settings){
        if(this.state.showOfferedModal){
            return (
                <div
                    className="modal modal-carousel"
                    id="modal-carousel"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="modal-carousel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="carousel">
                                    <Slider {...settings}>
                                        <div className="item">
                                            <img src={`https://canteen.az/bm/files/products/${this.state.offered.id}/300/${this.state.offered.images[0]} `} alt=""/>
                                        </div>
                                    </Slider>
                                </div>
                                <div className="content">
                                    <div className="head">{this.state.offered.name}</div>
                                    <div className="price"><span>{this.state.offered.price} </span>AZN</div>
                                    <div className="text">
                                        {this.state.offered.description}
                                    </div>
                                    <div className="own-table">
                                        <div className="box">
                                            <div className="left">Marka</div>
                                            <div className="right">Sandora</div>
                                        </div>
                                        <div className="box">
                                            <div className="left">Həcmi</div>
                                            <div className="right">1 LT</div>
                                        </div>
                                        <div className="box">
                                            <div className="left">Tərkibi</div>
                                            <div className="right">Portağal</div>
                                        </div>
                                        <div className="box">
                                            <div className="left">Qablaşdırma</div>
                                            <div className="right">Kağız Qutu</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop show" onClick={()=> this.closeOfferedModal()}></div>
                </div>
            )
        }
    }

    render() {
        /*slider style*/
        let settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
            arrows:false,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 2,
                        infinite:true
                    }
                },
            ]

        };
        return (
            <Aux>
                <section id="main">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-md-12 own-col">
                                <div className="main">
                                    <div className="carousel inside-container">
                                        <Slider {...settings}>
                                            {
                                                this.state.images.map((item,i)=>{
                                                    return (
                                                        <div className="item" key={i}>
                                                            <img src={item.image} alt="image"/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="categories">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-head">
                                    {this.state.translation.GENERAL.categories}
                                </div>
                                <div className="categories">
                                    {
                                        this.state.categories.map(function (item) {
                                            return (
                                                <Link to={`/categories/${item.id}`} key={item.id}>
                                                    <div className="box">
                                                        <img src={item.iconPath} alt="icon"/>
                                                        <div className="content">{item.name}</div>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    this.state.offered.length>0 ?
                        <section id="offered">
                            <div className="container-fluid own-container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="section-head">
                                            Təklif olunan mallar
                                        </div>
                                        <div className="all">
                                            <Link to="/categories">
                                                Hamısı
                                            </Link>
                                        </div>
                                        <div className="offered">
                                            {
                                                this.state.suggested.map((item,index) => {
                                                    return (
                                                        <div className="box" key={item.id}>
                                                            <div className="zoom" onClick={() =>this.showOfferedModal(item)}>
                                                                <i className="fal fa-search-plus"></i>
                                                            </div>
                                                            <a >
                                                                <div className="product">
                                                                    <img src={`https://canteen.az/bm/files/products/${item.id}/300/${item.images[0]} `} alt={item.name}/>
                                                                </div>
                                                                <div className="name">{item.name}</div>
                                                                <div className="price">
                                                                    <span className="price-span">{item.price} </span>AZN
                                                                </div>
                                                            </a>
                                                            {
                                                                item.showCount ?
                                                                    <div className="calculate">
                                                                        <div className="left">
                                                                            <span className="piece"> {this.getQuantity(item)} </span>
                                                                            {item.measurementUnit === 1 || item.measurementUnit === 3 ? this.state.translation.GENERAL.piece : this.state.translation.GENERAL.kq}
                                                                        </div>
                                                                        <div className="right" >
                                                                            <div className="minus" onClick={() => this.productsDecrease(item,index)}>
                                                                                <img src={require('../../assets/img/minus.svg')} alt="minus"/>
                                                                            </div>
                                                                            <div className="plus" onClick={()=> this.productsIncrease(item,index)}>
                                                                                <img src={require('../../assets/img/plus.svg')} alt="plus"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="add" onClick={()=>this.showProductCount(item)}>
                                                                        Əlavə et
                                                                    </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.offeredModal(settings)}
                        </section>
                        : null
                }

                <Footer/>
                {
                    this.state.showNotificationModal ?
                        <CookieConsent
                            buttonText={this.state.translation.GENERAL.close_btn}
                            cookieName="myAwesomeCookieName2"
                            overlay
                            overlayStyle = {{display : 'flex', alignItems: 'center',justifyContent:'center'}}
                            style={{
                                maxWidth: '30%',
                                position: "relative",
                                borderRadius: '3px',
                                backgroundColor: 'white',
                                color: '#000',
                                padding: '30px 25px',
                                margin: '0',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column'
                            }}
                            buttonStyle={{
                                height: '40px',
                                width: '120px',
                                marginTop: '10px',
                                background: "#410056",
                                color: "white",
                                borderRadius: '8px'
                            }}
                        >
                            <p>
                                {this.state.notificationText}
                            </p>
                        </CookieConsent>

                        : ''
                }
            </Aux>
        )
    }
}

