import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import {NavLink,Link} from 'react-router-dom';
import axios from "axios/index";
import {getTranslation} from "../../../Helper/Helper";

export default class PatternOrders extends React.Component{
    constructor(props) {
        super (props) ;
        this.state = {
            patternList:[],
            customerType : localStorage.getItem('customerType'),
            translation: {
                ORDER: {},
                GENERAL: {},
                LOGIN_SIGNUP : {},
                ORDER_CONFIRMATION: {},
                BASKET:{}
            }
        }
    }

    /*get pattern orderList from api*/
    getData = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/order/patterns',
            params: {
                limit: 30,
                offset:0
            },
            headers: {
                authorization : 'bearer '+  localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    self.setState({
                        patternList: response.data.result.data,
                    });
                }
            })
            .catch(function (response) {

            });
    };

    /*add product to cart*/
    addToCart = (item) => {
        axios ({
            method:'post',
            url:'https://canteen.az/api/private/order/add/cart/' + item.id,
            headers: {
                authorization : 'bearer '+ localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code ==200) {
                    window.location.href = '/Basket'
                }
            })
            .catch(function (response) {

            });
    };

    componentDidMount () {
        this.getData();


        let header = document.getElementById('header');
        header.classList.remove("fixed");

        if(typeof global.translation === 'undefined'){
            let self = this;
            getTranslation(function(obj){
                self.setState({translation: obj})
            })
        }else{
            this.setState({translation: global.translation})
        }
    }

    render () {
        return (
            <Aux>
                <section id="order">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="order">
                                    <div className="left">
                                        <div className="left-nav">
                                            {
                                                this.state.customerType !=9 ?
                                                    <NavLink to="/confirmOrders">
                                                        {this.state.translation.ORDER.approved_orders}
                                                    </NavLink>
                                                    : null
                                            }

                                            <NavLink to="/sendOrders">
                                                {this.state.translation.ORDER.sent_orders}
                                            </NavLink>
                                            <NavLink to="/acceptedOrders">
                                                {this.state.translation.ORDER.received_orders}
                                            </NavLink>
                                            {
                                                this.state.customerType !=9 ?
                                                    <NavLink to="/returnedOrders">
                                                        {this.state.translation.GENERAL.returned_orders}
                                                    </NavLink>
                                                    : null
                                            }
                                            <NavLink to="patternOrders">
                                                {this.state.translation.BASKET.template_orders}
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="temp-ord-account">
                                        <div className="main">
                                            {
                                                (this.state.patternList.length === 0  || this.state.patternList === null )  ?
                                                    <div className="no-data">
                                                        {this.state.translation.GENERAL.no_information}
                                                    </div>
                                                    :
                                                    this.state.patternList.map((item)=> {
                                                        return (
                                                            <div className="box" key={item.id}>
                                                                <div className="inside">
                                                                    <div className="left">{item.title}</div>
                                                                    {/*<div className="right">12.04.2020</div>*/}
                                                                </div>
                                                                <div className="inside no-margin">
                                                                    <div className="left button-left">
                                                                        <Link to={{pathname :`/patternOrdersMore/${item.id}`}}>{this.state.translation.GENERAL.read_more}</Link>
                                                                    </div>
                                                                    <div className="right button-right">
                                                                        <a onClick={() => this.addToCart(item)}>{this.state.translation.ORDER.order}</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })

                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Aux>
        )
    }
}
