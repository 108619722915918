import React from 'react';
import Aux from '../../hoc/Auxiliary';
import { NavLink,Link} from "react-router-dom";
import axios from "axios/index";
import {getTranslation} from "../../Helper/Helper";
export default class Navigation extends React.Component {
    container = React.createRef();
    constructor(props) {
        super (props);
        this.state = {
            props,
            showNotification :false,
            open: false,
            show:false,
            badge:false,
            Auth: localStorage.getItem('token'),
            notificationList:[],
            notificationUnreadCount:'',
            countBasket:'',
            basketItems: 0,
            callBasket: false,
            customerType : localStorage.getItem('customerType'),
            permission : localStorage.getItem('permission'),
            orderList:[],
            total:0,
            translation: {
                LEFT_MENU: {},
                GENERAL: {},
                ORDER:{},
                ORDER_CONFIRMATION:{}
            }

        }
    }

    /* notification */
    showNotification ()  {
        let self = this;
        this.setState({
            showNotification : !this.state.showNotification
        });
        axios({
            method: 'post',
            url: 'https://canteen.az/api/private/user-notification/read-notification',
            headers: {
                authorization : 'bearer '+ localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    self.notificationUnreadCount()
                }
            })
            .catch(function (response) {

            });
    }

    handleButtonClick = () => {
        this.setState(state => {
            return {
                open: !state.open,
            };
        });
    };

    toggleMenu = () => {
        this.setState({
            show : !this.state.show
        })
    };

    /*unRead notification*/
    notificationUnreadCount = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/user-notification/count-unread',
            headers: {
                authorization : 'bearer '+ localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    self.setState({
                        notificationUnreadCount: response.data.result.data,

                    })
                }
            })
            .catch(function (response) {

            });
    };

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }


    handleClickOutside = event => {
        if (this.container.current && !this.container.current.contains(event.target)) {
            this.setState({
                open: false,
            });
        }
    };

    getNotification = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/user-notification',
            headers: {
                authorization : 'bearer '+ localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    self.setState({
                        notificationList: response.data.result.data,

                    })
                }
            })
            .catch(function (response) {

            });
    };

    showBasketBadge = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/order/cart/count',
            headers: {
                authorization : 'bearer '+ localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    self.setState({basketItems: response.data.result.data})
                }
            })
            .catch(function (response) {

            });
    };

    /*get product list count to show in basket*/
    getOrders = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/order/cart/orders',
            params: {
                limit: 1000,
                offset: 0
            },
            headers: {
                authorization : 'bearer '+ localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if(response.data.status.code==200) {
                    self.setState({
                        orderList: response.data.result.data.products
                    });
                    self.calculateTotal()
                }
            })
            .catch(function (response) {

            });
    };

    /*total Price*/
    calculateTotal () {
        let total=0;
        for (let i of this.state.orderList) {
            total+= i.quantity * i.price
        }
        this.setState({total})
    }

    componentDidMount() {
        this.showBasketBadge();
        document.addEventListener("mousedown", this.handleClickOutside);
        this.getNotification();
        this.notificationUnreadCount();
        this.getOrders();
        if(typeof global.translation === 'undefined'){
            let self = this;
            getTranslation(function(obj){
                self.setState({translation: obj})
            })
        }else{
            this.setState({translation: global.translation})
        }

    }

    render() {
        return (
            <Aux>
                <header id="navbar">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-md-12">
                                <nav className="navbar navbar-expand-lg">
                                    <Link className="navbar-brand icon" to="/">
                                        <img src={require('../../assets/img/logo.svg')} alt="logo"/>
                                    </Link>
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        onClick={this.toggleMenu}
                                    >
                                        <span className="navbar-toggler-icon myNavbarTogglerIcon">
                                          <i className="fas fa-bars"></i>
                                        </span>
                                    </button>
                                    <div
                                        className={[this.state.show ? 'show' : '', 'collapse', 'navbar-collapse'].join(' ')}
                                        id="navbarSupportedContent">
                                        <div className="all-inside">
                                            <ul className="navbar-nav ul-nav">
                                                <li className="nav-item li-nav">
                                                    <NavLink to="/" exact={true} className="nav-link">{this.state.translation.LEFT_MENU.home}</NavLink>
                                                </li>
                                                <li className="nav-item li-nav">
                                                    <NavLink to="/categories"
                                                             className="nav-link">{this.state.translation.GENERAL.fast_order}</NavLink>
                                                </li>
                                                <li className="nav-item li-nav dropdown-li" ref={this.container}>
                                                    {
                                                        this.state.Auth !== null ?
                                                            <a className="nav-link dropdown-toggle"
                                                               href="#"
                                                               id="navbarDropdown"
                                                               role="button"
                                                               data-toggle="dropdown"
                                                               aria-haspopup="true"
                                                               aria-expanded="false"
                                                               onClick={this.handleButtonClick}>{this.state.translation.ORDER.orders}</a>
                                                            :
                                                            <Link className="nav-link dropdown-toggle" to="/createPassword">{this.state.translation.ORDER.orders}</Link>

                                                    }
                                                    { (this.state.open &&  this.state.Auth !== null)  ?
                                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown" onClick={this.handleButtonClick}>
                                                            {
                                                                this.state.customerType !=9 ?
                                                                    <Link className="dropdown-item" to="/confirmOrders">{this.state.translation.ORDER.approved_orders}</Link>
                                                                    : null
                                                            }
                                                            <Link className="dropdown-item" to="/sendOrders">{this.state.translation.ORDER.sent_orders}</Link>
                                                            <Link className="dropdown-item" to="/acceptedOrders">{this.state.translation.ORDER.received_orders}</Link>
                                                            {
                                                                this.state.customerType !=9 ?
                                                                    <Link className="dropdown-item" to="/returnedOrders">{this.state.translation.GENERAL.returned_orders}</Link>
                                                                    : null
                                                            }
                                                            <Link className="dropdown-item" to="/patternOrders">{this.state.translation.BASKET.template_orders}</Link>
                                                        </div>
                                                        : ''
                                                    }
                                                </li>
                                                {
                                                    this.state.permission != 'ROLE_WRITE_ORDER' && this.state.permission != 'ROLE_ORDER_CONFIRMATION' ?
                                                        <li className="nav-item li-nav">
                                                            {
                                                                this.state.Auth !== null ?
                                                                    <NavLink to="/history" className="nav-link">{this.state.translation.GENERAL.history}</NavLink>
                                                                    :
                                                                    <Link to="/createPassword" className="nav-link">{this.state.translation.GENERAL.history}</Link>
                                                            }
                                                        </li>
                                                        : null
                                                }
                                                <li className="nav-item li-nav">
                                                    {
                                                        this.state.Auth !== null ?
                                                            <NavLink to="/contact" className="nav-link">{this.state.translation.GENERAL.contact}</NavLink>
                                                            :
                                                            <Link to="/createPassword" className="nav-link">{this.state.translation.GENERAL.contact}</Link>
                                                    }
                                                </li>
                                                <li className="nav-item li-nav">
                                                    <NavLink to="/about" className="nav-link">
                                                        {this.state.translation.GENERAL.about}
                                                    </NavLink>
                                                </li>
                                            </ul>
                                            <div className="right">
                                                {/* <div className="search">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="search"
                                                                id="search"
                                                                placeholder="Bütün məhsullar üzrə axtarış"/>
                                                        </div>*/}
                                                <div className="basket-part">
                                                    <div className="bell">
                                                        {
                                                            this.state.Auth !== null ?
                                                                <a href="#" className="active-menu" onClick={() => this.showNotification()}>
                                                                    {
                                                                        this.state.notificationUnreadCount >0 ?
                                                                            <span>{this.state.notificationUnreadCount}</span>
                                                                            : null
                                                                    }
                                                                    <i className="far fa-bell"></i>
                                                                </a> :
                                                                <Link to="/createPassword" className="active-menu">
                                                                    <i className="far fa-bell"></i>
                                                                </Link>
                                                        }
                                                    </div>
                                                    <div className="basket">
                                                        {
                                                            this.state.Auth !== null ?
                                                                <Link to="/basket">
                                                                    <span id='basketItem'>{this.state.basketItems }</span>
                                                                    <i className="fas fa-shopping-cart"></i>
                                                                </Link>
                                                                :
                                                                <Link to="/createPassword">
                                                                    <i className="fas fa-shopping-cart"></i>
                                                                </Link>                                                        }
                                                    </div>
                                                </div>
                                                <div className="price">
                                                    {this.state.translation.ORDER_CONFIRMATION.amount}: <span className="all-price" id="basketTotal"> {parseFloat(this.state.total).toFixed(2)} </span> AZN
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </header>
                {
                    this.state.showNotification ?
                        <div className="modal modal-notification" id="modal-notification" tabIndex="-1" role="dialog" aria-labelledby="modal-notification" aria-hidden="true">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="close" onClick={()=>this.showNotification()}>
                                            <i className="fas fa-times"></i>
                                        </div>
                                        <div className="section-head">{this.state.translation.LEFT_MENU.notifications}</div>
                                        {
                                            this.state.notificationList.map((item,i)=> {
                                                return (
                                                    <div className="day" key={i}>
                                                        <div className="box">
                                                            <div className="head">{item.title}</div>
                                                            <div className="content">
                                                                {item.content}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="modal-backdrop show" onClick={()=>this.showNotification()}></div>
                        </div>
                        :null
                }
            </Aux>
        )
    }
}
