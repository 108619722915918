import React from 'react';
import {getTranslation} from "../../Helper/Helper";

export default class About extends React.Component {
    constructor(props) {
        super (props);
        console.log(props.location.hash)
        let tab = props.location.hash.length > 0 ? props.location.hash.slice(1) : 'about'
        this.state = {
            tab,
            translation : {
                GENERAL_REPORT : {},
                GENERAL : {}
            }
        }
    }

    componentDidMount() {

        let header = document.getElementById('header');
        header.classList.remove("fixed");

        if(typeof global.translation === 'undefined'){
            let self = this;
            getTranslation(function(obj){
                self.setState({translation: obj})
            })
        }else{
            this.setState({translation: global.translation})
        }
    }

    render () {
      return (
          <section id="about">
              <div className="container-fluid own-container">
                  <div className="row">
                      <div className="col-md-12">
                          <div className="about">
                              <div className="tabs">
                                  <ol className="tab-list">
                                      <li className={['tab-list-item' ,this.state.tab == 'about' ? 'tab-list-active' : ''].join(' ')} onClick={() => this.setState({tab: 'about'})}>{this.state.translation.GENERAL.about}</li>
                                      <li className={['tab-list-item' ,this.state.tab == 'software' ? 'tab-list-active' : ''].join(' ')} onClick={() => this.setState({tab: 'software'})}>{this.state.translation.GENERAL.about_text4}</li>
                                      <li id="partners" className={['tab-list-item' ,this.state.tab == 'partners' ? 'tab-list-active' : ''].join(' ') } onClick={() => this.setState({tab: 'partners'})}>{this.state.translation.GENERAL.partners}</li>
                                  </ol>
                                  <div className="tab-content">
                                      {
                                          this.state.tab == 'about' ?
                                              <div className="about-all-part same-div">
                                                  <div className="main">
                                                      <div className="head">{this.state.translation.GENERAL.about_bakumart}</div>
                                                      <div className="text">
                                                          {this.state.translation.GENERAL.about_text1}
                                                          <br/><br/>
                                                          {this.state.translation.GENERAL.about_text2 + ' ' + this.state.translation.GENERAL.about_text2_}
                                                      </div>
                                                      <div className="img">
                                                          <img src={require('../../assets/img/about.jpg')} alt="about"/>
                                                      </div>
                                                  </div>
                                              </div>
                                              : null
                                      }
                                      {
                                          this.state.tab == 'software' ?
                                              <div className="about-program-part same-div">
                                                  <div className="main">
                                                      <div className="head">{this.state.translation.GENERAL.about_text4}</div>
                                                      <div className="text">
                                                          <ul>
                                                              <li>
                                                                  {this.state.translation.GENERAL.about_text_list_1}
                                                              </li>
                                                              <li>
                                                                  {this.state.translation.GENERAL.about_text_list_2}
                                                              </li>
                                                              <li>
                                                                  {this.state.translation.GENERAL.about_text_list_3}
                                                              </li>
                                                              <li>
                                                                  {this.state.translation.GENERAL.about_text_list_4}
                                                              </li>
                                                              <li>
                                                                  {this.state.translation.GENERAL.about_text_list_5}
                                                              </li>
                                                          </ul>

                                                      </div>
                                                      <div className="img">
                                                          <img src={require('../../assets/img/slider1.jpg')} alt="about"/>
                                                      </div>
                                                  </div>
                                              </div>

                                              : null
                                      }

                                      {
                                          this.state.tab == 'partners' ?
                                              <div className="about-partners-part same-div" id="partners">
                                                  <div className="main">
                                                      <div className="head">{this.state.translation.GENERAL.partners}</div>
                                                      <div className="img">
                                                          <img src={require('../../assets/img/partners.png')} alt="about"/>
                                                      </div>
                                                  </div>
                                              </div>

                                              : null
                                      }
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      )
  }
}

