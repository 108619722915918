import React from 'react';
import Aux from '../../hoc/Auxiliary'
import axios from "axios";
import {getTranslation} from "../../Helper/Helper";
import Swal from 'sweetalert';
export default class Individual extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: "",
            phoneNumber: "+994",
            referralCode: "",
            validationName: false,
            validationPhone:false,
            translation: {
                LOGIN_SIGNUP: {},
                BASKET: {}
            }
        };
        this.handleChange = this.handleChange.bind(this);
    }

    // get formControl value
    handleChange = (stateVar, event) => {
        this.setState({
            [stateVar]: event.target.value
        });
    };

    /*send individual data api*/
    sendData=(event) => {
        event.preventDefault();
        let self =this;
        let hasValidationName = this.state.fullName.length>=3;
        let hasValidationPhone = this.state.phoneNumber.length>=11;
        self.setState ( {
            validationName : !hasValidationName ,
            validationPhone: !hasValidationPhone,
        });
        let data = {
            additionalPhoneNumber: this.state.phoneNumber,
            branchName: this.state.fullName,
            branchType: "ferdi musteri",
            companyName: 'YAXINDA.AZ',
            customerType: 9,
            fullName: this.state.fullName,
            phoneNumber: this.state.phoneNumber,
            turnover: 10000,
            referralCode:this.state.referralCode || null
        };
        if(hasValidationName && hasValidationPhone) {
            axios({
                method: 'post',
                url: 'https://canteen.az/api/public/auth/register',
                data: data,
            })
                .then(function (response) {
                    if(response.data.status.code==200) {
                        Swal({
                            title: self.state.translation.LOGIN_SIGNUP.success_operation,
                        });

                        window.location.href = '/creatPassword'
                    }
                })
                .catch(function (response) {

                });
            self.setState ( {
                validationName :false ,
                validationPhone:false
            });
        }
    };

    componentDidMount() {
        let header = document.getElementById('header');
        header.classList.remove("fixed");
        if(typeof global.translation === 'undefined'){
            let self = this;
            getTranslation(function(obj){
                self.setState({translation: obj})
            })
        }
        else{
            this.setState({translation: global.translation})
        }
    }
    render() {
        return (
            <Aux>
                <section id="special">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="special">
                                    <div className="left">
                                        <div className="section-head">{this.state.translation.LOGIN_SIGNUP.create_individual_account}</div>
                                        <form onSubmit={this.sendData}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control name"
                                                    id="name"
                                                    name="name"
                                                    placeholder={this.state.translation.LOGIN_SIGNUP.name_surname + '*'}
                                                    value={this.state.fullName}
                                                    onChange={(event)=>this.handleChange('fullName', event)}
                                                />
                                                {
                                                    this.state.validationName ?
                                                        <div className="invalid-feedback">
                                                            {this.state.translation.LOGIN_SIGNUP.include_name_surname}
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control phone"
                                                    id="phone"
                                                    name="phone"
                                                    value={this.state.phoneNumber}
                                                    onChange={(event)=>this.handleChange('phoneNumber', event)}
                                                />
                                                {
                                                    this.state.validationPhone ?
                                                        <div className="invalid-feedback">
                                                            {this.state.translation.LOGIN_SIGNUP.include_phone_number}
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="referralCode"
                                                    placeholder={this.state.translation.LOGIN_SIGNUP.include_referral_code}
                                                    value={this.state.referralCode}
                                                    onChange={(event)=>this.handleChange('referralCode', event)}
                                                />
                                            </div>
                                            <button type="submit">{this.state.translation.LOGIN_SIGNUP.send_btn}</button>
                                        </form>
                                    </div>
                                    <div className="right">
                                        <img src={require('../../assets/img/special.png')} alt="special"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="special-footer">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="footer">
                                    Copywrite 2020 by Canteen. Allrigts reserved
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Aux>
        )
    }
}
