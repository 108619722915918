import React, {Component} from 'react';
import Aux from '../../hoc/Auxiliary';
import axios from "axios/index";
import Select, {components} from 'react-select';
import {Map, Marker, GoogleApiWrapper, Polygon} from 'google-maps-react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/index.min.css';
import Swal from 'sweetalert';
import * as geolib from 'geolib';
import {getTranslation} from "../../Helper/Helper";

class OrderParameters extends Component {
    constructor(props) {
        super(props);
        let customerType = localStorage.getItem('customerType');
        let deliveryTime = customerType != 9 ? '18' : false;
        this.state = {
            showAddressToggle: false,
            addressModal: false,
            showHoursToggle: false,
            showPaymentToggle: false,
            showGiftToggle: false,
            shadow: false,
            addressList: [],
            weekdays: [
                {weekday: 0},
                {weekday: 1},
                {weekday: 2},
                {weekday: 3},
                {weekday: 4},
                {weekday: 5},
                {weekday: 6},
            ],
            hours: [
                {begin: 9, end: 11},
                {begin: 11, end: 13},
                {begin: 13, end: 15},
                {begin: 15, end: 17},
                {begin: 17, end: 19},
                {begin: 19, end: 21},
            ],
            showAdditionalNote: false,
            selectedOption: null,
            weekday: '0',
            selectedWeekday: '0',
            visaModal: false,
            paymentType: 'cash',
            giftList: [],
            giftPashaList: [],
            total: 0,
            balance: 0,
            time: new Date(),
            savedCardList: [],
            cash: '',
            gift: {},
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            currentLocation: {
                lat: 0,
                lng: 0
            },
            address: {},
            addressName: '',
            deliveryTime,
            type: '',
            cardID: '0',
            orderID: '',
            otherCard: false,
            selectedCard: false,
            customerType,
            timeBusiness: 15,
            validation: false,
            description: '',
            polygonList: [],
            bakumartPosition: {lng: 49.86895, lat: 40.39471},
            hiddenButton: false,
            balanceBonusTotal: 0,
            balanceBonusChecked: false,
            translation: {
                GENERAL_REPORT: {},
                GENERAL: {},
                ORDER: {},
                ORDER_CONFIRMATION: {},
                LOGIN_SIGNUP: {},
                ORDER_PARAMETERS: {},
                UTIL: {},
                BASKET: {},
                LEFT_MENU: {}
            },
        };

        this.handleChange = this.handleChange.bind(this);
    }

    showAddressList() {
        this.setState({
            showAddressToggle: !this.state.showAddressToggle,
            shadow : !this.state.shadow
        })
    }

    showDeliveryHours() {
        this.setState({
            showHoursToggle: !this.state.showHoursToggle
        })
    }

    showPaymentType() {
        this.setState({
            showPaymentToggle: !this.state.showPaymentToggle
        })
    }

    showGiftList() {
        this.setState({
            showGiftToggle: !this.state.showGiftToggle
        })
    }

    //get input value
    handleChange = (stateVar, event) => {
        this.setState({
            [stateVar]: event.target.value
        });
    };

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };

    //map marker
    onMarkerDragEnd = (three) => {
        const {latLng} = three;
        const lat = latLng.lat();
        const lng = latLng.lng();
        let self = this;
        self.setState({
            currentLocation: {
                lat: lat,
                lng: lng
            }
        });
        axios({
            method: 'get',
            url: 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=AIzaSyB-OBHSmH_SJkbcd7JOVv1ryfJwmX3e704',
        })
            .then(function (response) {
                self.setState({addressName: response.data.results[0].formatted_address})
            })
            .catch(function (response) {

            });

    };

    // get timeList for weekends
    getTime = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/public/product/time',
            headers: {
                authorization: 'bearer' + localStorage.getItem('token')
            }
        })
            .then(function (res) {
                if (res.data.status.code === 200) {
                    let date = new Date();
                    date.setTime(res.data.result.data);
                    self.setState({
                        time: date
                    });
                    self.setState({weekday: date.getDay()}, () => {
                        let newWeekdays = [];
                        for (let i = parseInt(self.state.weekday); i < self.state.weekdays.length; i++) {
                            self.state.weekdays[i].date = new Date();
                            let diff = parseInt(self.state.weekdays[i].weekday) - parseInt(self.state.weekday)
                            self.state.weekdays[i].date.setDate(date.getDate() + diff);
                            newWeekdays.push(self.state.weekdays[i])
                            if (date.getDay() === self.state.weekdays[i].weekday) self.setState({selectedWeekday: self.state.weekdays[i]})
                        }

                        for (let i = 0; i < parseInt(self.state.weekday); i++) {
                            self.state.weekdays[i].date = new Date();
                            let diff = parseInt(self.state.weekdays[i].weekday) - parseInt(self.state.weekday) + 7
                            self.state.weekdays[i].date.setDate(date.getDate() + diff);
                            newWeekdays.push(self.state.weekdays[i])
                        }

                        self.setState({weekdays: newWeekdays})
                    });
                }
            })
            .catch(function (res) {

            })
    };

    getAddress = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/address/addresses',
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code === 200) {
                    self.setState({
                        addressList: response.data.result.data,
                    });
                }
            })
            .catch(function (response) {

            });
    };

    sendAddress = (event) => {
        event.preventDefault();
        let lat = this.state.currentLocation.lat;
        let lng = this.state.currentLocation.lng
        let isNotInPolygon = geolib.isPointInPolygon({lat, lng}, this.state.polygonList);
        if (!isNotInPolygon) {
            Swal({
                title: this.state.translation.GENERAL.warning,
                text: this.state.translation.ORDER_PARAMETERS.address_text,
                icon: "warning",
                button: 'Ok',
            })
        }
        let self = this;
        let hasDescription = this.state.description.length >= 3;
        if (hasDescription && isNotInPolygon) {
            axios({
                method: 'post',
                url: 'https://canteen.az/api/private/address',
                data: {
                    address: this.state.addressName,
                    addressType: 1,
                    region: "Azerbaijan",
                    description: this.state.description,
                    latitude: this.state.currentLocation.lat,
                    longitude: this.state.currentLocation.lng
                },
                headers: {
                    authorization: 'bearer ' + localStorage.getItem('token')
                }
            })
                .then(function (response) {
                    if (response.data.status.code === 200) {
                        self.setState({addressModal: false, description: ''});
                        self.getAddress();
                    }
                })
                .catch(function (response) {

                });
            self.setState({
                validation: false
            })
        } else {
            self.setState({
                validation: true
            })
        }

    };

    // set address in map
    setAddress = (address) => {
        let lat = address.latitude;
        let lng = address.longitude;
        let isNotInPolygon = geolib.isPointInPolygon({lat, lng}, this.state.polygonList);
        if (!isNotInPolygon) {
            Swal({
                title: this.state.translation.GENERAL.warning,
                text: this.state.translation.ORDER_PARAMETERS.address_text,
                icon: "warning",
                button: 'Ok',
            })
        } else {
            this.setState({address})
        }
    };

    getTotalSum = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/order/cart/orders',
            params: {
                limit: 1000,
                offset: 0,
                sort: 2
            },
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    let total = 0;
                    let balance = response.data.result.data.balance;
                    for (let i of response.data.result.data.products) {
                        total += parseFloat(i.price) * parseFloat(i.quantity);
                    }

                    self.setState({
                        total: total.toFixed(2),
                        balance: parseFloat(balance).toFixed(2)
                    });
                }
            })
            .catch(function (response) {

            });
    };

    //select option
    handleOptions = selectedOption => {
        if (selectedOption.value === 3) {
            this.setState({showAdditionalNote: true})
        } else {
            this.setState({showAdditionalNote: false})
        }
        this.setState({
            selectedOption,
            type: selectedOption.value
        });
    };

    getCampaign = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/product/v2/search',
            params: {
                limit: 50,
                offset: 0,
                campaign: 11,
            },
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    self.setState({
                        giftList: response.data.result.data.products,
                    });
                }
            })
            .catch(function (response) {

            });
    };

    getPashaBankCampaign = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/product/v2/search',
            params: {
                limit: 50,
                offset: 0,
                campaign: 10,
            },
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    self.setState({
                        giftPashaList: response.data.result.data.products,
                    });
                }
            })
            .catch(function (response) {

            });
    };

    getSavedCards = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/payment/saved-cards',
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    self.setState({
                        savedCardList: response.data.result.data,
                    });
                }
            })
            .catch(function (response) {

            });
    };

    getPolygon() {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/public/product/location-polygon'
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    let polygonList = JSON.parse(response.data.result.data[0].polygon);
                    self.setState({
                        polygonList
                    });
                }
            })
            .catch(function (response) {

            });
    }

    getDistance(p1, p2) {
        const R = 6378137; // Earth’s mean radius in meter
        const dLat = this.rad(p2.lat - p1.lat);
        const dLong = this.rad(p2.lng - p1.lng);
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.rad(p1.lat)) * Math.cos(this.rad(p2.lat)) *
            Math.sin(dLong / 2) * Math.sin(dLong / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c;
        return d;
    }

    rad(x) {
        return x * Math.PI / 180;
    }

    calculateDistance() {
        return this.getDistance(this.state.bakumartPosition, {
            lat: this.state.address.latitude,
            lng: this.state.address.longitude
        });
    }

    completeOrder = () => {
        let confirmErrors = {};
        let date = this.state.selectedWeekday.date.getFullYear() + '-' + ("0" + (this.state.selectedWeekday.date.getMonth() + 1)).slice(-2) + '-' + ("0" + this.state.selectedWeekday.date.getDate()).slice(-2);
        let self = this;
        let deliveryTime = this.state.deliveryTime + ':00';
        if (self.state.customerType != 9) {
            date = new Date();
            date.setDate(date.getDate() + 1);
            date = date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
        }

        let data = {
            region: this.state.address.region,
            cash: this.state.cash,
            giftId: this.state.gift.id ? this.state.gift.id : null,
            address: this.state.address.address,
            addressDescription: this.state.address.description,
            note: this.state.note,
            deliveredDate: date + ' ' + deliveryTime,
            deliveryType: "1",
            distance: this.calculateDistance(),
            latitude: this.state.address.latitude,
            longitude: this.state.address.longitude,
            operationType: 1,
            paymentType: this.state.paymentType === 'cash' ? "1" : "2",
            title: "",
            useBonus: this.state.balanceBonusChecked ? '1' : '0'
        };

        if (self.state.type !== undefined) {
            data.productExist = self.state.type;
        }

        if (Object.keys(self.state.address).length === 0) {
            confirmErrors.address = self.state.translation.LOGIN_SIGNUP.choose_address
        }else{
            delete confirmErrors.address;
        }

        if (self.state.paymentType === 'cash' && self.state.cash === "") {
            confirmErrors.cash = self.state.translation.ORDER_PARAMETERS.enter_cash
        }else{
            delete confirmErrors.cash;
        }

        if (self.state.deliveryTime === false) {
            confirmErrors.deliveryTime = self.state.translation.LOGIN_SIGNUP.choose_delivery_time
        }else{
            delete confirmErrors.deliveryTime;
        }

        if(Object.keys(confirmErrors).length >0){
            let text = '';
            for(let i in confirmErrors){
                text += confirmErrors[i]+ '\n';
            }
            Swal({
                title: self.state.translation.GENERAL.warning,
                text,
                icon: "warning",
                button: 'Ok',
                className: "swal-order"
            })
        }

        if (self.state.paymentType === 'card' && parseInt(self.state.cardID) === 0 && (Object.keys(self.state.address).length !== 0 && this.state.deliveryTime !== false)) {
            self.setState({
                visaModal: true
            })
        }

        axios({
            method: 'post',
            url: 'https://canteen.az/api/private/order/complete',
            data: data,
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    if (self.state.paymentType === 'cash') {
                        Swal({
                            title: self.state.translation.LOGIN_SIGNUP.success_operation,
                            icon: "success",
                        }).then(function () {
                            window.location.href = "/";
                        })
                    }
                    self.setState({
                        orderID: response.data.result.data,
                        hiddenButton: true
                    });

                    if (self.state.paymentType === 'card' && parseInt(self.state.cardID) !== 0) {
                        self.saveCard()
                    }
                }
            })
            .catch(function (response) {

            });
    };

    sendPayment = () => {
        axios({
            method: 'post',
            url: 'https://canteen.az/api/private/payment',
            data: {
                cardName: this.state.cardName,
                giftId: this.state.gift.id,
                orderId: this.state.orderID,
                save: 1,
            },
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    window.location.href = "https://pay.millikart.az/epayment/ClientHandler?trans_id=" + response.data.result.data
                }
            })
            .catch(function (response) {

            });
    };

    saveCard = () => {
        let self = this;
        axios({
            method: 'post',
            url: 'https://canteen.az/api/private/payment/saved-card',
            data: {
                cardId: this.state.cardID,
                giftId: this.state.gift.id,
                orderId: this.state.orderID,
            },
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    if (self.state.paymentType === 'card' && parseInt(self.state.cardID) !== 0) {
                        let transactionNumber = response.data.result.data;
                       window.location.href = '/callback?transactionNumber=' + transactionNumber
                    }
                }
            })
            .catch(function (response) {

            });
    };

    //set address into map
    setMapAddress(description) {
        this.setState({addressName: description});
        geocodeByAddress(description)
            .then(results => getLatLng(results[0]))
            .then(({lat, lng}) => {
                this.setState({currentLocation: {lat, lng}})
            });
    }

    getUserStatics = () => {
        let self = this;
        axios({
            method: 'get',
            url: 'https://canteen.az/api/private/user-statistics',
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response.data.status.code == 200) {
                    let remainingBonus = response.data.result.data.remainingBonus == null ? 0 : response.data.result.data.remainingBonus
                    let remainingBalance = response.data.result.data.remainingBalance == null ? 0 : response.data.result.data.remainingBalance
                    let balanceBonusTotal = parseFloat(remainingBonus + remainingBalance).toFixed(2);
                    self.setState({
                        balanceBonusTotal
                    });

                }
            })
            .catch(function (response) {

            });
    }

    componentDidMount() {
        this.getAddress();
        this.getCampaign();
        this.getTotalSum();
        this.getTime();
        this.getSavedCards();
        this.getPashaBankCampaign();
        this.getPolygon();
        this.getUserStatics();
        let self = this;
        navigator.geolocation.getCurrentPosition(function (position) {
            self.setState({
                currentLocation: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }
            });

            axios({
                method: 'get',
                url: 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + position.coords.latitude + ',' + position.coords.longitude + '&key=AIzaSyAta7n0naOcu4eRrPnWvBPNPS0jT7QFhqk',
            })
                .then(function (response) {
                    self.setState({addressName: response.data.results[0].formatted_address})
                })
                .catch(function (response) {

                });

        });

        if (typeof global.translation === 'undefined') {
            let self = this;
            getTranslation(function (obj) {
                self.setState({translation: obj})
            })
        } else {
            this.setState({translation: global.translation})
        }


        let rightBlock = document.getElementById('sticky');
        let stickyChild = document.getElementById('sticky-child');

        window.onscroll = function () {
            if(window.pageYOffset > 180) {
                rightBlock.classList.add('sticky');
                stickyChild.style.width = rightBlock.offsetWidth + 'px';
            }

            else {
                rightBlock.classList.remove('sticky')
            }
        }

    }

    render() {
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                color: '#000',
                backgroundColor: state.isSelected ? '#E4E4E4' : 'transparent',
                padding: '18px 24px',
                fontSize: '14px',
                width: '100%',
                borderBottom: '1px solid #f5f5f5',
                "&::first-of-type": {
                    borderRadius: '8px 8px 0 0',
                },
                "&:hover": {
                    backgroundColor: '#E4E4E4',
                },
                "&:last-child": {
                    borderBottom: 'none',
                    borderRadius: '0 0 8px 8px',
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                position: 'relative'

            }),

            control: (provided) => ({
                ...provided,
                minHeight: '14px',
                borderRadius: '8px',
                width: '100%',
                fontSize: '16px',
                padding: '0 32px',
                color: '#000',
                backgroundColor: 'transparent',
                border: 'none',
                boxShadow: 'none',
                "::focus": {
                    borderColor: '#f5f5f5',
                }
            }),

            indicatorSeparator: () => {
            },

            dropdownIndicator: defaultStyles => ({
                ...defaultStyles,
                'svg ': {
                    display: 'none'
                },
                background: `url(${require("../../assets/img/arrow_down.svg")}) no-repeat 50%`,
                paddingRight: '20px'
            }),

            valueContainer: (provided) => ({
                ...provided,
                padding: '2px 0'
            }),

            menu: (provided) => ({
                ...provided,
                background: '#ffffff',
                boxShadow: '0 2px 13px rgba(0, 0, 0, 0.132184)',
                borderRadius: '8px',
                padding: '0',
                border: '1px solid #ddd',
                width: '100%',
                zIndex: '99999'
            }),
            menuList: base => ({
                ...base,
                padding: 0,
                zIndex: 99999

            })
        };
        const Option = props => {
            return (
                <components.Option {...props}>
                    {props.label}
                    <label className="input-radio" style={{position: "absolute", right: '30px'}}>
                        <input type="radio" checked={props.isSelected} onChange={() => null}/>
                        <span className="checkmark"></span>
                    </label>
                </components.Option>
            );
        };

        const options = [
            {value: 1, label: this.state.translation.ORDER_PARAMETERS.change_product},
            {value: 2, label: this.state.translation.ORDER_PARAMETERS.remove_product},
            {value: 3, label: this.state.translation.ORDER_PARAMETERS.additional_note}
        ];
        return (
            <Aux>
                <section id="basket-param">
                    <div className="container-fluid own-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="basket-param">
                                    <div className="left_part">
                                        <div className="more">
                                            <div className="top">
                                                <div className="left">
                                                    {this.state.translation.ORDER_PARAMETERS.order_parameters}
                                                </div>
                                            </div>
                                            <div className="head">{this.state.translation.ORDER_CONFIRMATION.address}</div>
                                            <div className={['box','address', this.state.shadow ? 'shadow' : ' '].join(' ')}>
                                                <div className="compact" onClick={() => this.showAddressList()}>
                                                    <div className="left-com">
                                                        <img src={require('../../assets/img/location.svg')}
                                                             alt="location"/>
                                                    </div>
                                                    <div className="right-com">
                                                        <div
                                                            className="top-com">{this.state.translation.ORDER_PARAMETERS.delivery_address}</div>
                                                        <div className="bottom-com">{this.state.address.address || this.state.translation.ORDER_PARAMETERS.choose_address}</div>
                                                    </div>
                                                    <div className="arrow">
                                                        <img src={require('../../assets/img/down.svg')} alt="arrow"/>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.showAddressToggle ?
                                                        <div className="show-box">
                                                            {
                                                                this.state.addressList.map((item) => {
                                                                    return (
                                                                        <div className="place" key={item.id}>
                                                                            <div className="left-box">
                                                                                <label className="input-radio">
                                                                                    <img
                                                                                        src={require('../../assets/img/home.svg')}
                                                                                        alt="home"/>
                                                                                    {item.address}
                                                                                    <input type="radio"
                                                                                           name="address"
                                                                                           checked={this.state.address === item}
                                                                                           onChange={() => {
                                                                                               this.setAddress(item);
                                                                                               this.setState({
                                                                                                   showAddressToggle: false,
                                                                                                   shadow: false
                                                                                               })
                                                                                           }}/>
                                                                                    <span className="checkmark"></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            <div className="add"
                                                                 onClick={() => this.setState({addressModal: true})}>
                                                                <img src={require('../../assets/img/add.svg')}
                                                                     alt="add"/>
                                                                {this.state.translation.GENERAL.add_new_address}
                                                            </div>
                                                        </div>

                                                        : null
                                                }
                                            </div>
                                            <div className="head">{this.state.translation.GENERAL.delivery}</div>
                                            {
                                                this.state.customerType != 9 ?
                                                    <div className="box">
                                                        {
                                                            this.state.time.getHours() >= this.state.timeBusiness ?
                                                                <div className="inside">
                                                                    <div
                                                                        className={['delivery', this.state.deliveryTime == '18' ? 'checked' : ''].join(' ')}
                                                                        onClick={() => this.setState({deliveryTime: '18'})}>
                                                                        <div className="left-part">
                                                                            <img className="icon"
                                                                                 src={require('../../assets/img/clock.svg')}
                                                                                 alt="clock"/>
                                                                            <span>{this.state.translation.UTIL.next_day_x_hour_1}</span>
                                                                        </div>
                                                                        <div className="right-part">
                                                                            <img
                                                                                src={require('../../assets/img/check.svg')}
                                                                                alt="check"/>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={['delivery', this.state.deliveryTime === '12' ? 'checked' : ''].join(' ')}
                                                                        onClick={() => this.setState({deliveryTime: '12'})}>
                                                                        <div className="left-part">
                                                                            <img className="icon"
                                                                                 src={require('../../assets/img/profil_calendar.svg')}
                                                                                 alt="calendar"/>
                                                                            <span>{this.state.translation.UTIL.next_day_x_hour_2}</span>
                                                                        </div>
                                                                        <div className="right-part">
                                                                            <img className="check"
                                                                                 src={require('../../assets/img/check.svg')}
                                                                                 alt="check"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="inside">
                                                                    <div
                                                                        className={['delivery', this.state.deliveryTime == '12' ? 'checked' : ''].join(' ')}
                                                                        onClick={() => this.setState({deliveryTime: '12'})}>
                                                                        <div className="left-part">
                                                                            <img
                                                                                src={require('../../assets/img/clock.svg')}
                                                                                alt="clock"/>
                                                                            <span>{this.state.translation.UTIL.next_day_x_hour_3}</span>
                                                                        </div>
                                                                        <div className="right-part">
                                                                            <img
                                                                                src={require('../../assets/img/check.svg')}
                                                                                alt="check"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        }

                                                    </div>

                                                    :

                                                    <div className="box delivery">
                                                        <div className="compact"
                                                             onClick={() => this.showDeliveryHours()}>
                                                            <div className="left-com">
                                                                <img src={require('../../assets/img/clock.svg')}
                                                                     alt="clock"/>
                                                            </div>
                                                            <div className="right-com">
                                                                <div
                                                                    className="top-com">{this.state.translation.ORDER_PARAMETERS.delivery_date}</div>
                                                                <div className="bottom-com">
                                                                    {parseInt(this.state.deliveryTime) > 0 ? this.state.deliveryTime + ':00' : this.state.deliveryTime || this.state.translation.ORDER_PARAMETERS.choose_date}
                                                                </div>
                                                            </div>
                                                            <div className="arrow">
                                                                <img src={require('../../assets/img/down.svg')}
                                                                     alt="arrow"/>
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.showHoursToggle ?
                                                                <div className="show-box">
                                                                    <div className="week_days">
                                                                        <div className="header">{this.state.translation.ORDER_PARAMETERS.when}</div>
                                                                        {
                                                                            (this.state.selectedWeekday.weekday === this.state.weekday) || (this.state.weekday === 0) ||  (this.state.selectedWeekday.weekday === 0 ) ?
                                                                                <p className="subtitle">Hörmətli müştəri çatdırılma saatı üçün növbəti günü seçməyinizi xahiş edirik.</p>
                                                                                : null
                                                                        }
                                                                        <ul>
                                                                            {
                                                                                this.state.weekdays.map((item, i) => {
                                                                                    console.log(this.state.weekday)
                                                                                    return (
                                                                                        <li key={i} onClick={() => {
                                                                                            this.setState({selectedWeekday: item})
                                                                                        }}
                                                                                            className={this.state.selectedWeekday.weekday === item.weekday ? 'active-li' : ''}>
                                                                                            {item.weekday == this.state.weekday ? this.state.translation.ORDER_PARAMETERS.today : this.state.translation.ORDER_PARAMETERS['d' + item.weekday]}
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                    {
                                                                        this.state.hours.map((item, i) => {
                                                                            return (
                                                                                //className={(this.state.time.getHours() >= item.begin  && this.state.selectedWeekday.weekday === this.state.weekday) ||  (this.state.time.getHours() + 1 == item.begin && this.state.time.getMinutes() >= 30) ? 'place deactive-box' : 'place'}
                                                                                <div className={(this.state.selectedWeekday.weekday === 0 ) || (this.state.weekday === 0) || (this.state.selectedWeekday.weekday === this.state.weekday) || ( (item.begin ===  9 ) || (item.begin === 11) || (item.begin === 13)) ? 'place deactive-box' : 'place'} key={i}>
                                                                                    <div className="left-box">
                                                                                        <label className="input-radio">
                                                                                            {item.begin}:00-{item.end}:00
                                                                                            <input type="radio"
                                                                                                   name="time"
                                                                                                   value={item.begin}
                                                                                                   checked={this.state.deliveryTime == item.begin}
                                                                                                   onChange={(event) => {
                                                                                                       this.setState({showHoursToggle: false});
                                                                                                       this.handleChange('deliveryTime', event)
                                                                                                   }}/>
                                                                                            <span className="checkmark"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }

                                                                </div>
                                                                : null
                                                        }
                                                    </div>

                                            }
                                            <div
                                                className="head">{this.state.translation.ORDER_PARAMETERS.doesnt_exist_product}</div>
                                            <div className="box no-padding">
                                                <Select
                                                    placeholder={this.state.translation.ORDER_PARAMETERS.choose}
                                                    onChange={this.handleOptions}
                                                    options={options}
                                                    styles={customStyles}
                                                    components={{Option}}
                                                />
                                                {
                                                    this.state.showAdditionalNote ?
                                                        <form className="no-product">
                                                            <div className="form-group">
                                                        <textarea
                                                            id="subject"
                                                            className="form-control"
                                                            name="subject"
                                                            rows="0"
                                                            cols="0"
                                                            placeholder={this.state.translation.FEEDBACK.message}
                                                            value={this.state.note}
                                                            onChange={(e) => this.handleChange('note', e)}
                                                        />
                                                            </div>
                                                        </form>
                                                        : null
                                                }
                                            </div>
                                            <div className="head">{this.state.translation.ORDER_CONFIRMATION.payment}</div>
                                            <div className="box visa">
                                                <div className="compact" onClick={() => this.showPaymentType()}>
                                                    <div className="left-com">
                                                        <img src={require('../../assets/img/wallet.svg')} alt="wallet"/>
                                                    </div>
                                                    <div className="right-com">
                                                        <div
                                                            className="top-com">{this.state.translation.ORDER_PARAMETERS.payment_type}</div>
                                                        <div
                                                            className="bottom-com">{this.state.translation.ORDER_PARAMETERS.choose_payment_type}</div>
                                                    </div>
                                                    <div className="arrow">
                                                        <img src={require('../../assets/img/down.svg')} alt="arrow"/>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.showPaymentToggle ?
                                                        <div className="show-box">
                                                            <div className="switch">
                                                                <div className="switch_sep"></div>
                                                                <input className="switch_cb"
                                                                       id="y" type="radio"
                                                                       checked={this.state.paymentType === 'cash'}
                                                                       onChange={() => {
                                                                           this.setState({paymentType: 'cash'})
                                                                       }}/>
                                                                <label className="switch_label"
                                                                       htmlFor="y">{this.state.translation.ORDER_CONFIRMATION.cash}</label>
                                                                <input className="switch_cb" id="yy"
                                                                       checked={this.state.paymentType === 'card'}
                                                                       onChange={() => {
                                                                           this.setState({paymentType: 'card'})
                                                                       }} type="radio"/>
                                                                <label className="switch_label"
                                                                       htmlFor="yy">{this.state.translation.ORDER_CONFIRMATION.card}</label>
                                                                <div className="switch_hl"></div>
                                                            </div>

                                                            {
                                                                this.state.paymentType === 'cash' ?
                                                                    <form className="payment">
                                                                        <div className="form-group">
                                                                            <label
                                                                                htmlFor="message">{this.state.translation.ORDER_PARAMETERS.enter_cash}</label>
                                                                            <input
                                                                                id="message"
                                                                                className="form-control"
                                                                                type="number"
                                                                                name="message"
                                                                                placeholder={this.state.translation.ORDER_PARAMETERS.add_cash}
                                                                                value={this.state.cash}
                                                                                onChange={(e) => this.handleChange('cash', e)}
                                                                            />
                                                                        </div>
                                                                    </form> :
                                                                    <div
                                                                        className={['place', 'payment-part'].join(' ')}>
                                                                        {
                                                                            this.state.savedCardList.map((item, i) => {
                                                                                return (
                                                                                    <div className="left-box" key={i}>
                                                                                        <label className="input-radio">
                                                                                            <img
                                                                                                src={require('../../assets/img/visa.svg')}
                                                                                                alt="visa"/>
                                                                                            {item.cardName}
                                                                                            <input type="radio"
                                                                                                   name="visa"
                                                                                                   value={item.cardId}
                                                                                                   onChange={(event) => this.handleChange('cardID', event)}/>
                                                                                            <span className="checkmark"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                        <div className="left-box">
                                                                            <label className="input-radio">
                                                                                <img
                                                                                    src={require('../../assets/img/visa.svg')}
                                                                                    alt="visa"/>
                                                                                {this.state.translation.ORDER_CONFIRMATION.pay_with_other_card}
                                                                                <input type="radio" name="visa"
                                                                                       value='0'
                                                                                       checked={this.state.cardID}
                                                                                       onChange={(event) => this.handleChange('cardID', event)}/>
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div className="checkbox-part">
                                                <div className="checkbox">
                                                    <label>
                                                        {this.state.translation.LEFT_MENU.balance_bonus } ( {this.state.balanceBonusTotal} AZN )
                                                        <input type="checkbox"  checked={this.state.balanceBonusChecked} onChange={()=>this.setState({balanceBonusChecked: !this.state.balanceBonusChecked})}/>
                                                        <span className="mark-checked"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="head">{this.state.translation.ORDER_CONFIRMATION.gift}  </div>
                                            <div className="box gift">
                                                <div className="compact" onClick={() => this.showGiftList()}>
                                                    <div className="left-com">
                                                        <img src={require('../../assets/img/gift.svg')} alt="gift"/>
                                                    </div>
                                                    <div className="right-com">
                                                        <div
                                                            className="top-com">{this.state.translation.ORDER_PARAMETERS.gift}</div>
                                                        <div
                                                            className="bottom-com">{this.state.gift.name || this.state.translation.ORDER_PARAMETERS.choose}</div>
                                                    </div>
                                                    <div className="arrow">
                                                        <img src={require('../../assets/img/down.svg')} alt="arrow"/>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.showGiftToggle ?
                                                        <div className="show-box">
                                                            <div className="gift-box">
                                                                <div
                                                                    className="header">{this.state.translation.ORDER_CONFIRMATION.gift_for_all} </div>
                                                                <div className="gift-list-content">
                                                                    {
                                                                        this.state.giftList.map((item) => {
                                                                            return (
                                                                                <div className="place gift-place"
                                                                                     key={item.id}>
                                                                                    <div className="left-box">
                                                                                        <label className="input-radio">
                                                                                            {
                                                                                                item.images.length > 0 ?
                                                                                                    <img
                                                                                                        src={`https://canteen.az/bm/files/products/${item.id}/300/${item.images[0]} `}
                                                                                                        className="product"
                                                                                                        alt={item.name}/>
                                                                                                    : null
                                                                                            }
                                                                                            {item.displayName}
                                                                                            <input type="radio"
                                                                                                   checked={this.state.gift.id == item.id}
                                                                                                   onChange={(event) => {
                                                                                                       this.setState({
                                                                                                           showGiftToggle: false,
                                                                                                           gift: item
                                                                                                       })
                                                                                                   }}/>
                                                                                            <span className="checkmark"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.paymentType === 'card' && this.state.giftPashaList.length > 0 ?
                                                                    <div className='gift-box'>
                                                                        <div className="header">
                                                                            {this.state.translation.ORDER_CONFIRMATION.gift_for_pasha}
                                                                            <div className="gift-list-content">
                                                                                {
                                                                                    this.state.giftPashaList.map((item) => {
                                                                                        return (
                                                                                            <div
                                                                                                className="place gift-place"
                                                                                                key={item.id}>
                                                                                                <div
                                                                                                    className="left-box">
                                                                                                    <label
                                                                                                        className="input-radio">
                                                                                                        {
                                                                                                            item.images.length > 0 ?
                                                                                                                <img
                                                                                                                    src={`https://canteen.az/bm/files/products/${item.id}/300/${item.images[0]} `}
                                                                                                                    className="product"
                                                                                                                    alt={item.name}/>
                                                                                                                : null
                                                                                                        }
                                                                                                        {item.displayName}
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            name="gift"
                                                                                                            checked={this.state.gift.id == item.id}
                                                                                                            onChange={(event) => {
                                                                                                                this.setState({
                                                                                                                    showGiftToggle: false,
                                                                                                                    gift: item
                                                                                                                })
                                                                                                            }}/>
                                                                                                        <span className="checkmark"></span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div> : null
                                                            }

                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right-block" id="sticky">
                                        <div className="right_part" id="sticky-child">
                                            <div className="inside">
                                                <div
                                                    className="left-basket bold">{this.state.translation.BASKET.balance}</div>
                                                <div className="right-basket font-bold">{this.state.balanceBonusTotal} AZN</div>
                                            </div>
                                            <div className="inside">
                                                <div
                                                    className="left-basket bold">{this.state.translation.ORDER.limit}</div>
                                                <div className="right-basket font-bold"> {this.state.balance} AZN </div>
                                            </div>
                                            <div className="inside">
                                                <div
                                                    className="left-basket font-bold">{this.state.translation.ORDER.order_amount}</div>
                                                <div className="right-basket">{this.state.total} AZN</div>
                                            </div>
                                            <div className="inside">
                                                <div
                                                    className="left-basket font-bold">{this.state.translation.ORDER.delivery_amount}</div>
                                                <div className="right-basket">0.00 AZN</div>
                                            </div>
                                            <div className="inside brown">
                                                <div
                                                    className="left-basket font-bold">{this.state.translation.ORDER.total_amount}</div>
                                                <div className="right-basket">{this.state.total} AZN</div>
                                            </div>
                                            <div
                                                className={['button', this.state.hiddenButton ? 'hidden-button' : ''].join(' ')}>
                                                <a href="#" onClick={() => this.completeOrder()}>{this.state.translation.LOGIN_SIGNUP.confirm_btn}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    this.state.addressModal ?
                        <div
                            className="modal modal-address"
                            id="modal-address"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="modal-address"
                            aria-hidden="true">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="close" data-dismiss="modal" aria-label="Close"
                                             onClick={() => this.setState({addressModal: false})}>
                                            <i className="fas fa-times"></i>
                                        </div>
                                        <div className="map">
                                            <Map google={this.props.google}
                                                 onClick={this.onMapClicked}
                                                 initialCenter={this.state.currentLocation}
                                                 center={this.state.currentLocation}
                                                 zoom={11}
                                            >
                                                <Marker position={this.state.currentLocation}
                                                        draggable={true}
                                                        onDragend={(lat, lng, coordinates) => this.onMarkerDragEnd(coordinates)}/>
                                                <Polygon
                                                    paths={this.state.polygonList}
                                                    key={144}
                                                    editable={true}
                                                    options={{
                                                        strokeColor: '#410056',
                                                        strokeOpacity: 0.8,
                                                        strokeWeight: 2,
                                                        fillColor: '#410056',
                                                        fillOpacity: 0.2,
                                                    }}
                                                />

                                            </Map>
                                        </div>
                                        <form onSubmit={this.sendAddress}>
                                            <div className="form-group">
                                                <GooglePlacesAutocomplete
                                                    key={1}
                                                    autocompletionRequest={{
                                                        componentRestrictions: {
                                                            country: ['az'],
                                                        }
                                                    }}
                                                    placeholder={this.state.translation.GENERAL.search}
                                                    onSelect={({description}) => this.setMapAddress(description)}
                                                    initialValue={this.state.addressName}
                                                    renderInput={(props) => (
                                                        <div className="custom-wrapper">
                                                            <input className="search_part"
                                                                   {...props}
                                                            />
                                                        </div>
                                                    )}
                                                    renderSuggestions={(active, suggestions, onSelectSuggestion) => (
                                                        <div className="suggestions-container">
                                                            {
                                                                suggestions.map((suggestion, i) => (
                                                                    <div
                                                                        className="suggestion"
                                                                        onClick={(event) => onSelectSuggestion(suggestion, event)}
                                                                        key={i}
                                                                    >
                                                                        {suggestion.description}
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="address_part"
                                                    name="address_part"
                                                    placeholder={this.state.translation.GENERAL.address_hint}
                                                    value={this.state.description}
                                                    onChange={(event) => this.handleChange('description', event)}
                                                />
                                            </div>
                                            {
                                                this.state.validation ?
                                                    <div className="invalid-feedback">
                                                        {this.state.translation.GENERAL.address_hint_enter}
                                                    </div>
                                                    : null
                                            }

                                            <button type="submit">{this.state.translation.BASKET.add_btn}</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-backdrop show" onClick={() => this.setState({addressModal: false})}></div>
                        </div>
                        : null
                }
                {
                    this.state.visaModal ?
                        <div className="modal  modal-visa" id="modal-visa" tabIndex="-1" role="dialog"
                             aria-labelledby="modal-visa" aria-hidden="true">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="close" data-dismiss="modal" aria-label="Close">
                                            <i className="fas fa-times"></i>
                                        </div>
                                        <div className="add-cart">
                                            <p>{this.state.translation.ORDER_PARAMETERS.save_card}</p>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="cart_number"
                                                    name="cart_number"
                                                    placeholder={this.state.translation.ORDER_PARAMETERS.example_card}
                                                    onChange={(e) => this.handleChange('cardName', e)}
                                                />
                                            </div>
                                            <div className="info">
                                                {this.state.translation.ORDER_PARAMETERS.card_text}
                                            </div>
                                            <div className="visa-btns">
                                                <button onClick={() => this.sendPayment()}>{this.state.translation.GENERAL.yes}</button>
                                                <button onClick={() => {this.setState({cardName: null}, () => this.sendPayment())}}>{this.state.translation.GENERAL.no}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-backdrop show" onClick={() => this.setState({visaModal: false})}></div>
                        </div>
                        : null
                }
            </Aux>

        )
    }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyB-OBHSmH_SJkbcd7JOVv1ryfJwmX3e704')
})(OrderParameters)
